import React, { useEffect } from "react" 
import { useMainStore, IState, ITheme, THEME_NAMES} from '@/settings/zustand' 
import "../../../assets/css/day-night.css"
import actions from "../data/actions"
import { SWITCH_THEME_ACTION } from "../data/actionTypes"
import { Button, ButtonGroup } from "@blueprintjs/core"
import { __ } from "@/libs/utilities"

const DayNightSwitcher = (props: any): JSX.Element =>
{  
    const theme: THEME_NAMES = useMainStore((state:IState) => state.theme) || THEME_NAMES.DAY
    const switchTheme = ( theme: THEME_NAMES ) =>
    {  
        actions( SWITCH_THEME_ACTION, { theme } )
    }
    switch(props.type){
        case "buttons":
            return <ButtonSwitcher theme={theme} switchTheme={switchTheme} className={props.className} />
        case "graphics":
        default:
            return <GraphicsSwitcher theme={theme} switchTheme={switchTheme} className={props.className} />
    }    
}
export default DayNightSwitcher

const GraphicsSwitcher = ( { theme, switchTheme, className } :any ) : JSX.Element => {
    return <div 
        className={`day-night-container ${className} ${ theme === THEME_NAMES.DAY ? "day" : "night" }`} 
        onClick={() => switchTheme( theme ) }    
    >
        <svg id="sun-moon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="10px" y="10px" width="64" height="32"
            viewBox="0 0 81.4 40.3" xmlSpace="preserve">
            <g>
                <defs>
                    <circle id="SVGID_1_" cx="20.9" cy="20.3" r="19.7"/>
                </defs>
                <clipPath id="SVGID_2_">
                    <use xlinkHref="#SVGID_1_"  style={{overflow: "visible" }}/>
                </clipPath>
                <path className="dnsw-svg" d="M-0.3-0.3v40.6h87.6V-0.3H-0.3z M15.3,29.7c-8,0-14.5-6.5-14.5-14.5S7.3,0.6,15.3,0.6s14.5,6.5,14.5,14.5
                    S23.4,29.7,15.3,29.7z"/>
            </g>
        </svg>
    </div>
}

const ButtonSwitcher = ( {theme, switchTheme, className } :any ) : JSX.Element => {
    return <ButtonGroup  className={` text-light` }>
        <Button 
            onClick={() => switchTheme(THEME_NAMES.DAY)} 
            className={` ${theme === THEME_NAMES.DAY ? "" : " opacity_5 "} ${className} `} 
            minimal
        >
            <i className="fas fa-sun mr-3"></i>
            {__("Day")}
        </Button>
        <Button 
            onClick={() => switchTheme(THEME_NAMES.EVENING)} 
            className={` ${theme === THEME_NAMES.EVENING ? "" : " opacity_5 "} ${className} `} 
            minimal
        >
            <i className="fas fa-cloud-sun mr-3"></i>
            {__("Evening")}
        </Button>
        <Button 
            onClick={() => switchTheme(THEME_NAMES.NIGHT)} 
            className={` ${theme === THEME_NAMES.NIGHT ? "" : " opacity_5 "} ${className} `} 
            minimal
        >
            <i className="fas fa-moon mr-3"></i>
            {__("Night")}
        </Button> 
    </ButtonGroup>
}