import { IProject, IProjectMilestone } from "@/modules/pe-fest-module/data/interfaces"
import ProjectRating from "../ProjectRating"
import { IFestProjectsProps, useFestProjects } from "@/modules/pe-fest-module/data/store"
import Project from "../Project"
import ProjectMilestones from "../milestone/ProjectMilestones"

/* Protopia Ecosystem component */
export interface IProjectMobileRatingsProps {

}
const ProjectMobileRatings = (props: IProjectMobileRatingsProps): JSX.Element => {
    const item: IProject = useFestProjects((state: IFestProjectsProps) => state.currentProject)
    return <div className='pe-project-mobile-ratings-container'>
        <ProjectMilestones item={item} />
        <ProjectRating item={item} />
    </div>
}
export default ProjectMobileRatings