import { __, sprintf } from "@/libs/utilities"
import { IRating } from "@/modules/pe-fest-module/data/interfaces"
import { Callout, Intent } from "@blueprintjs/core"

/* Protopia Ecosystem component */
export interface ICorrectRatingLabelProps {
   rating: IRating
   children: any
}
const CorrectRatingLabel = (props: ICorrectRatingLabelProps): JSX.Element => {
    return <div>
        {
            props.rating.correct_user_id && props.rating.correct_display_name
                ?
                <Callout className="correct-rating-label-container px-1 pt-1 pb-0 text-light" intent={Intent.DANGER} icon="blank">
                    <div 
                        className=" "
                        dangerouslySetInnerHTML={{
                            __html: sprintf(
                                `<span class=" small">${__( "Your rating correct by %s. " )}</span>`, 
                                `<span class=" title">${props.rating.correct_display_name!.toString()}</span>`, 
                            )
                        }}
                    />
                    <div className="lead">
                        <div className="small">
                            { props.rating.correct_description!.toString() }
                        </div>
                    </div>  
                    <div className="mb-2 small"
                        dangerouslySetInnerHTML={{
                            __html: sprintf(
                                __( "Now real rating - %s." ), 
                                `<span class="title">${props.rating.correct_rating}</span>`, 
                            )
                        }}
                    />  
                    <div>
                        {props.children}
                    </div>  
                </Callout>
                :
                <div>
                    {props.children}
                </div>
        }
        
    </div>
}
export default CorrectRatingLabel