
export interface ITrimContent {
    content: string
    isTrim: boolean
    trimContent?: string
  }
  export const trimContent = (content: string, wordsCount: number): ITrimContent =>
  {
    const trims = content.toString().split(" ")
    if(trims.length > wordsCount)
    { 
      return {
        content: trims.slice( 0, wordsCount ).join(" ") + "...", 
        isTrim: true, 
        trimContent: trims.slice( wordsCount, -1 ).join(" ") 
      }
    }
    else
    {
      return {content, isTrim:false}
    }
  }