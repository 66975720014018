import { clearHTML } from "@/libs/utilities"


export const UPDATE_PROJECT_RATING = (data: any) => {
    const site = data.landId
        ?
        ` land_id: "${ data.landId }" `
        :
        ``
    const ret = `
        mutation updateRating {
            changePEFestRating( 
                id: "${data.id}" 
                ${site}
                input: { 
                    rating: ${data.item.rating},
                    criteryId: "${data.item.criteryId}",
                    memberId: "${data.item.memberId}",
                    expertId: "${data.item.expertId}",
                    display_name: "${data.item.display_name}",
                    description: "${clearHTML(data.item.description)}"${
                        data.item.correct_rating
                            ?
                            `, correct_rating: ${ data.item.correct_rating },
                            correct_user_id: ${ data.item.correct_user_id},
                            correct_description: "${ data.item.correct_description || "" }",
                            correct_display_name: "${ data.item.correct_display_name || "" }"
                            `
                            :
                            ``
                    }
                }
        ) {
            id
            rating
            expertId
            display_name
            criteryId
            memberId
            description
            correct_rating
            correct_user_id
            correct_description
            correct_display_name
        }
    }`
    // console.log( ret )
    return ret
}