import { APOLLO_FIELD_FILTER_TYPE, IAppoloField, ID, IMetaFilter, IPaging, ITax, ITaxonomyFilter, Role } from '@/libs/interfaces/layouts'
import { __ } from '@/libs/utilities'
import { Button, ButtonGroup, Collapse, Intent } from '@blueprintjs/core'
import { useEffect, useState } from 'react'
import { apolloFields } from 'src/libs/layouts'
import { IState, SORT_ORDER, useMainStore } from '../../../../settings/zustand'
import { IAdminStore, IBulk, useAdminStore } from '../../data/store'
import BulkDeleter from './BulkDeleter'
import BulkEditor from './BulkEditor'
import BulkVisibler from './BulkVisibler'
import MetaFilterElement from './MetaFilterElement'
import DataTableSearchPanel from './DataTableSearchPanel'
import TaxonomyFilterElement from './TaxonomyFilterElement'
import BulkDuplicator from './BulkDuplicator'
import { SCALAR_TYPES } from '@/libs/scalars'
import DataTableSettingsPanel from './DataTableSettingsPanel' 

const DataTableFiltersPanel = (props: IDataTableFilterProps): JSX.Element => {
    const bulks: IBulk[]                        = useAdminStore((state:IAdminStore) => state.bulks) 
    const search: string                        = useAdminStore((state:IAdminStore) => state.search) 
    const metaFilter                            = useAdminStore((state:IAdminStore) => state.metaFilter) 
    const post_status                           = useAdminStore((state:IAdminStore) => state.post_status) 
    const in__roles                             = useAdminStore((state:IAdminStore) => state.in__roles) 
    const setMetaFilter                         = useAdminStore((state:IAdminStore) => state.setMetaFilter) 
    const setPost_status                        = useAdminStore((state:IAdminStore) => state.setPost_status) 
    const setIn__roles                          = useAdminStore((state:IAdminStore) => state.setIn__roles) 
    const setSearch                             = useAdminStore((state:IAdminStore) => state.setSearch) 
    const setTaxonomies                         = useAdminStore((state:IAdminStore) => state.setTaxonomies) 
    const setSortOrder: any                     = useAdminStore((state: IAdminStore ) => state.setSortOrder)
    const landId : ID                           = useMainStore((state: IState) => state.landId ) 
    const [metaFilters, setMetaFilters]         = useState<any[]>( [] ) 
    const [relation, setRelation]               = useState<"OR" | "AND" >( 'OR' )
    const [isSettingsOpen, setIsSettingsOpen]   = useState( false )
    const [isFilterOpen, setIsFilterOpen]       = useState( false ) 


    useEffect(() => {
        onSetFilters()
    }, [ props.data_type ]) 

    const getMetaValue = (field: IAppoloField, i:number): any => { 
        switch( field.type ) {
            case SCALAR_TYPES.POST_STATUS:
                return post_status
            default: 
                const ff = metaFilter.filter(( mF: IMetaFilter ) => mF.key === field.name ) 
                return ff[0]?.value
        }
    }
    const onFilter = ( key: string, value: any ) => { 
        let _in__roles: string[] | null = useAdminStore.getState().in__roles

        //console.log( useAdminStore.getState().metaFilter, key ) 

        let _metas = [ ...useAdminStore.getState().metaFilter ].filter( m => m.key !== key )  
        if( key === "roles" && props.data_type === "User" ) { 
            _in__roles = value
        }
        else if(key === "post_status") {
            setPost_status( value )
        }
        else if( value !== null && ( value?.length !==  0) ) { 
            _metas.push({ key, value} as IMetaFilter )
        }
    
        setMetaFilter( _metas ) 
        setIn__roles( _in__roles )
        onSetPaging()
    }

    const onTaxonomy = (tax_name:string, term_ids: Number[]) => { 
        let _taxonomies = (useAdminStore.getState().taxonomies || [])
            .filter( (t: ITaxonomyFilter) => t.tax_name !== tax_name )
        _taxonomies.push({tax_name, term_ids})
        console.log(_taxonomies)
        setTaxonomies(_taxonomies)
        onSetPaging()
    }

    const onSearch =(_search: string) => {
        setSearch(_search)
        onSetPaging()
    }

    const clearFilter = () => {
        setMetaFilter([])
        setIn__roles(null)
        setRelation("OR")
        onSetFilters()
        props.clearFilter()
    }

    const onSetPaging = () => {
        let paging: IPaging = {
            metas:          useAdminStore.getState().metaFilter,
            in__roles :     useAdminStore.getState().in__roles,
            post_status:    useAdminStore.getState().post_status,
            relation:       relation,
            search:         useAdminStore.getState().search,
            taxonomies:     useAdminStore.getState().taxonomies,
        } 
        if( props.onFilter ) {
            props.onFilter(paging)
        }
    } 

    const onSort = ( order: SORT_ORDER, byField: string ) => { 
        // setSortOrder( order.toUpperCase() )
    }

    const onSetFilters = () => {
         // set filters and meta_filters
         const fields: IAppoloField[] = Object.keys( apolloFields( props.data_type ) ).map( af => {
            return { ...apolloFields( props.data_type )[af], name: af }
        } )
        const _metaFilters: any[] = []
        fields.forEach( (field: IAppoloField, i: number) => { 
            if( field.filter_type === APOLLO_FIELD_FILTER_TYPE.TAXONOMIES ) {
                _metaFilters.push(
                    <TaxonomyFilterElement 
                        key={ i } 
                        field={ field } 
                        onFilter = { onTaxonomy }
                    /> 
                )
            }
            if( field.filter_type === APOLLO_FIELD_FILTER_TYPE.META ) { 
                console.log( field.name, field.type )
                _metaFilters.push(
                    <MetaFilterElement 
                        key={ i } 
                        field={ field } 
                        onFilter = { onFilter }
                        value={ getMetaValue( field, i ) }
                    /> 
                )
            }
        }) 
        setMetaFilters(_metaFilters)
    }

    const filterBtn = !!metaFilters.length
	    ?
		<Button 
            minimal 
            icon={ isFilterOpen ? "filter-remove" : "filter" }
            onClick={ () => setIsFilterOpen(!isFilterOpen ) }
        >
			{ __(isFilterOpen ? "hide Filters" : "show Filters") }
		</Button>
	    :			
		null
	
    const filterBlock = !!metaFilters.length 
	    ? 
        <Collapse isOpen={isFilterOpen} className="w-100">
            <div className="p-3 pe-light mt-2">
                <div className="d-flex flex-wrap mb-2"> 
                    { metaFilters }
                </div>
                <div className="d-flex flex-wrap">
                    <ButtonGroup className="flex-wrap ">
                        <Button
                            minimal={relation === "AND"}
                            intent={Intent.SUCCESS}
                            data-relation="OR"
                            onClick={() => {
                                setRelation('OR')
                                onSetPaging()
                            }}
                        >
                            { __("All by any fields")}
                        </Button>
                        <Button
                            minimal={relation === "OR"}
                            intent={Intent.DANGER}
                            data-relation="AND"
                            onClick={() => {
                                setRelation('AND');
                                onSetPaging()
                            }}
                        >
                            {__("Only have all fields")}
                        </Button>
                    </ButtonGroup>
                    <Button minimal onClick={ clearFilter } className="ml-3 w-32 w-sm-100">
                        { __("clear filters") }
                    </Button>
                </div>
            </div>
        </Collapse> 
	    :			
		null 



    
    return <>
        <div className="d-flex w-100 align-items-center ">
            <Button
                minimal
                className="ml-2"
                icon="cog"
                onClick={() => setIsSettingsOpen( !isSettingsOpen )}
            >
                { __( isSettingsOpen ? "hide Settings" : "show Settings") }
            </Button>
            { filterBtn }
            <div className="ml-auto">
                <DataTableSearchPanel search={search} onSearch={onSearch} />               
            </div> 
        </div>
        <Collapse isOpen={ isSettingsOpen } className="w-100">
            <DataTableSettingsPanel data_type={props.data_type} onSort={onSort}/>
        </Collapse>
		{ filterBlock } 
        <Collapse isOpen={ !!bulks.filter((bulk: IBulk) => bulk.checked).length } className="w-100">
            <div className="p-3 pe-light mt-2 border-bottom-dark">
                <ButtonGroup className="d-flex align-items-center flex-wrap">
                    <BulkDeleter data_type={props.data_type}/> 
                    <BulkEditor data_type={props.data_type} landId={landId} /> 
                    <BulkDuplicator onConfirm={ () => { if( props.onDoubled) props.onDoubled() } }/> 
                    <BulkVisibler data_type={props.data_type}/>                    
                </ButtonGroup>
            </div>
        </Collapse>
    </>
} 

export default DataTableFiltersPanel

export interface IDataTableFilterProps {
    data_type: string
    data_status?: string
    count: number
    onChangeCount : ( count: number ) => void
    clearFilter : () => void
    onFilter?: ( paging: IPaging ) => void
    onDoubled?: () => void 
}