import { ID } from "@/libs/interfaces/layouts"
import { IMedia } from "../../data/interfaces"
import BulkCheckBox from "./BulkCheckBox"
import { IAdminStore, IBulk, useAdminStore } from "../../data/store"

/* Protopia Ecosystem component */

export interface IMediaThumbnailProps {
    item: IMedia
    i: number
    data_type: string
    height: number
    params : any
    selectId: ID
}
const MediaThumbnail = ({item, selectId, ...props}: IMediaThumbnailProps): JSX.Element => { 
    const bulks: IBulk[] = useAdminStore( (state:IAdminStore) => state.bulks )
    const setBulks: ( bulks: IBulk[] ) => void = useAdminStore( (state:IAdminStore) => state.setBulks )

    const check = ( evt: any ) => {
        setBulks(
            bulks.map( b => {
                return {
                    ...b,
                    checked: b.id === item?.id
                    ?
                    !b.checked
                    : 
                    b.checked
                }
            })
        ) 
        console.log( bulks.filter(b => b.id === item.id)[0].checked )
    }
    const img = item?.url?.toString() === "false" ? item?.large : item?.url
    const bulk = bulks.filter(b => b.id === item.id)[0]
    return <>
        <div  
            className={ "wp-thumbnail position-relative" + ( item?.id === selectId ? " active " : "" ) }
            data-id={ item?.id }
            style={{ backgroundImage: "url(" + img +")" }}
            onClick={() => props.params.onOpen(item, props.i)}
        >
            <BulkCheckBox checked={ bulk ? bulk.checked : false } onCheck={check} />
        </div>
    </>
}
export default MediaThumbnail