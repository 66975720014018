import DocViewer, { DocViewerRenderers } from "react-doc-viewer";  


/* Protopia Ecosystem component */
export interface IPresentationProps {
   
}
const Presentation = (props: IPresentationProps): JSX.Element => {
    const docs: any[] = [
        { 
            uri:  "/assets/data/priority.pptx",
            fileType: "pptx", 
        },  
      ];
     
    return <div className='pe-presentation-container layout-app-state'>
        <DocViewer 
            documents={docs} 
            pluginRenderers={DocViewerRenderers} 
            style={{height:800}}
            config={{
                header: {
                 disableHeader: true,
                 disableFileName: true,
                 retainURLParams: true
                }
            }}
        />  
    </div>
}
export default Presentation