import { __, __cases } from "@/libs/utilities"
import { IPEFestSchool } from "../../data/interfaces"
import { getFeedRoute, getRoute } from "@/libs/layouts"
import { useNavigate } from "react-router"

/* Protopia Ecosystem component */
export interface IFestSchoolCardProps {
   item: IPEFestSchool
}
const FestSchoolCard = (props: IFestSchoolCardProps): JSX.Element => {
    const navigate = useNavigate()
    const routeSingle = getFeedRoute("PEFestProject") 
    const _url = routeSingle
        ? 
        `/${ getRoute(routeSingle)}?schools=-1,${ props.item.id }` 
        : 
        "/"
    const onClick = () => {
        navigate( _url )
    }
    return <div className="col-md-6 mb-0 py-2 px-0 strob-tile ">
        <div className="container">
            <div className="row pb-1">
                <div className="col-md-6  my-auto">
                    <h5 
                        className="m-0 w-100 pointer" 
                        style={{ lineHeight: .9 }} 
                        onClick={onClick}
                    >
                        { props.item.title }  
                    </h5>
                    {
                        !!props.item.destrict.length &&
                        <div className=" small " >
                            { props.item.destrict[0].title }
                        </div>
                    }
                </div>
                <div className="col-md-4 cl-6 my-auto">
                    {
                        props.item.guardian?.id && 
                        <div className="">
                            <div className=" small " >
                                { __(!!props.item.guardian2 ? "School_guardians" : "School_guardian") }:
                            </div>
                            <div className=" ">
                                {props.item.guardian?.display_name}
                            </div> 
                            <div className=" ">
                                {props.item.guardian2?.display_name}
                            </div> 
                        </div>
                    }                    
                </div>
                <div className="col-md-2 col-6 h-100 my-auto">
                    <div>
                        <span className='mr-2'>{ props.item.count || 0 }</span>
                        <span>{__cases(props.item.count, "projects")}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
}
export default FestSchoolCard

const MiddleSchoolCard = (props: IFestSchoolCardProps): JSX.Element  => {
    const navigate = useNavigate()
    const routeSingle = getFeedRoute("PEFestProject") 
    const _url = routeSingle
        ? 
        `/${ getRoute(routeSingle)}?schools=-1,${ props.item.id }` 
        : 
        "/"
    const onClick = () => {
        navigate( _url )
    }
    return <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
       <div 
            className="card h-100 m-0 p-0 position-relative"  
        >
            <div
                className="card-img border-none overflow-hidden hover pointer d-flex align-items-end "
                style={{
                    backgroundImage: `url(${props.item.thumbnail})`,
                    height: 130,
                    backgroundSize: "cover",
                    backgroundRepeat:"no-repeat"
                }} 
                onClick={onClick}
            > 
                {
                    !!props.item.destrict.length &&
                    <div className="pe-narrow-pennant pe-dark small text-uppercase" >
                        { props.item.destrict[0].title }
                    </div>
                }
            </div> 
            <div
                className="card-header d-flex flex-centered flex-column pb-3"
                title={ props.item.title }
                style={{
                    height: 125,
                    overflow: "hidden",
                    padding: "0 1.25em",
                    position: "relative",
                    display: "table",
                    paddingTop: 14
                }}
            >
                <h5 
                    className="m-0 w-100 text-center pointer" 
                    style={{ lineHeight: 1.0 }} 
                    onClick={onClick}
                >
                    { props.item.title }  
                </h5>
                <div>
                    <div className="btn btn-link px-3 py-1">
                        {props.item.guardian?.display_name}
                    </div> 
                </div>
                <div>
                    <span className='mr-2'>{ props.item.count || 0 }</span>
                    <span>{__cases(props.item.count, "projects")}</span>
                </div>
            </div>
            <ul className="list-group list-group-flush flex-grow-100 ">
                <li className="list-group-item h-100 d-flex flex-column">
                    
                </li>
            </ul>
        </div>
    </div>
}