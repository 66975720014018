import { useEventAdminStore, useToposStore } from "../pe-topos-module/data/store"
import BanExpertForm from "./views/festAdmin/BanExpertForm"
import EducationOrganizationForm from "./views/festAdmin/EducationOrganizationForm"
import EventCarouselPhaseForm from "./views/festAdmin/EventCarouselPhaseForm"
import ExternalProjectFieldsForm from "./views/festAdmin/ExternalProjectFieldsForm"
import MainPageForm from "./views/festAdmin/MainPageForm"
import PackProjectCreateForm from "./views/festAdmin/PackProjectCreateForm"
import SettingsForm from "./views/festAdmin/SettingsForm"
import TrackForm from "./views/festAdmin/TrackForm"
import NewFestivalSettings from "./views/festival-admin-list/add-festival/add-festival-tabs/NewFestivalSettings"

const init = () => {
    return new Promise<any>((resolve, reject) => {
        // console.log("init fest")
        useEventAdminStore.setState({
            newEventTypeSetting: {
                ...useToposStore.getState().newEventTypeSetting,
                Festival: {
                    component: NewFestivalSettings,
                    module: "pe-fest-module",
                    eventType: "Festival"
                }
            },
        })
        useToposStore.setState({
            eventCarousel: {
                ...useToposStore.getState().eventCarousel,
                PhaseForm: {
                    component: <EventCarouselPhaseForm />,
                },
                EducationOrganizationForm: {
                    component: <EducationOrganizationForm />
                },
                TrackForm: {
                    component: <TrackForm />
                },
                PackProjectCreateForm:{
                    component: <PackProjectCreateForm />
                },
                BanExpertForm:{
                    component: <BanExpertForm />
                }
            }, 
            eventTypeAdminData: {
                ...useToposStore.getState().eventTypeAdminData,
                Festival: {
                    'main-page' : {
                        title: "Main page design",
                        group: "Festival setting", 
                        component: MainPageForm,
                        position: "left",
                        icon: <i className="fas fa-suitcase"></i> 
                    },
                    settings : {
                        title: "Settings",
                        group: "Festival setting", 
                        component: SettingsForm,
                        position: "left"
                    },
                    projects :  {
                        title: "Projects",
                        data_type: "PEFestProject",
                        group: "Festival setting",
                        undergroup: "Landscape management",
                        position: "left",
                        icon: <i className="fas fa-university"></i>
                    },
                    tracks :  {
                        title: "Tracks",
                        data_type: "PEFestTrack",
                        group: "Festival setting",
                        undergroup: "Landscape management",
                        position: "left"
                    },
                    destricts :  {
                        title: "Destricts",
                        data_type: "PEFestDestrict",
                        group: "Festival setting",
                        undergroup: "Landscape management",
                        position: "left"
                    },
                    schools :  {
                        title: "Education organizations",
                        data_type: "PEFestSchool",
                        group: "Festival setting",
                        undergroup: "Landscape management",
                        position: "left"
                    },
                    honeycombs :  {
                        title: "Honeycombs",
                        data_type: "PEFestHoneycombs",
                        group: "Festival setting",
                        undergroup: "Landscape management",
                        position: "left"
                    },
                    "ex-fields" : {
                        title: "Project ex-fields",
                        group: "Festival setting",
                        undergroup: "Landscape management",
                        component: ExternalProjectFieldsForm,
                        position: "right"
                    },
                    criteries :  {
                        title: "Criteries",
                        data_type: "PEFestCritery",
                        group: "Festival setting",
                        undergroup: "Referee management",
                        position: "left",
                        icon: <i className="fas fa-tachometer-alt"></i>
                    },
                    categories :  {
                        title: "Categories",
                        data_type: "PEFestCategory",
                        group: "Festival setting",
                        undergroup: "Referee management",
                        position: "left",
                        icon: <i className="fas fa-tachometer-alt"></i>
                    },
                    milestones :  {
                        title: "Milestones",
                        data_type: "PEFestMilestone",
                        group: "Festival setting",
                        undergroup: "Referee management",
                        position: "left"
                    },
                    ganres :  {
                        title: "Ganres",
                        data_type: "PEFestGanre",
                        group: "Festival setting",
                        undergroup: "Referee management",
                        position: "left"
                    },
                }
            }
        })
        resolve( true )
        reject( false )
    }) 
}
export default init