
import { ScalableDialog } from "@/libs/useful"
import { __, sprintf } from "@/libs/utilities"
import { Button, ButtonGroup, Collapse, Intent } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import Feed from "src/modules/pe-basic-module/views/core/Feed"
import { DIALOG_SIZE, ID } from "src/libs/interfaces/layouts"
import { IMedia } from "../../data/interfaces"
import MediaElement from "./MediaElement"
import MediaThumbnail from "./MediaThumbnail"
import Uploader from "./Uploader"
import { IAdminStore, IBulk, useAdminStore } from "../../data/store"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import actions from "@/modules/pe-basic-module/data/actions"
import { DELETE_BULK_ACTION } from "@/modules/pe-basic-module/data/actionTypes"
import { SORT_ORDER } from "@/settings/zustand"

const MediaListForm = (props:any) :JSX.Element => {
    const params = useParams()
    const landId: ID = params.landId || ""
    // console.log(landId)
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
    const [current, setCurrent] = useState<IMedia | null>(null)
    const [i, setI] = useState<number>(0)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [order, setOrder] = useState(SORT_ORDER.ASC)
    const [seed, setSeed] = useState( Math.random() )
    const bulks: IBulk[] = useAdminStore( (state:IAdminStore) => state.bulks )
    const setBulks: ( bulks: IBulk[] ) => void = useAdminStore( (state:IAdminStore) => state.setBulks )
    const medias: IMedia[] = useDataStore((state: any) => state.Media)

    useEffect(() => {
        setBulks([])
    }, [])

    const onOpen = (item: IMedia, _i: number) => {
        setCurrent( item )
        setI(_i)
        setIsDialogOpen(true)
    }
    const onClose = () => {
        setCurrent( null )
        setIsDialogOpen(false)
    }
     
    const prev = () => {
         
    }
    const next = () => {
        
    }
    const onLoading = ( items: IMedia[],  offset: number, count: number, paging: string, fullCount: number ) => {
        setBulks(
            items.map(item => ({
                id: item.id,
                checked: false,
                data_type: "Media"
            }))
        )
    }
    const onBulkDelete = () => {
        actions(
            DELETE_BULK_ACTION, 
            {
                data_type: "Media",
                landId,
                id: bulks.map(b => b.id)
            } 
        )
        .then(r => setSeed(Math.random() ))
    }
    const onBulkClear = () => {
        setBulks( bulks.map(b => ({ ...b, checked: false } )) )
    }
    const onAddOpen = () => {
        setIsOpen(!isOpen)
        onBulkClear()
    }
    const checks = bulks.filter(b => b?.checked)
    const len = checks.length
    return <div className="w-100">
        <div className="container-fluid px-3 pt-3">
            <ButtonGroup className="px-2"> 
                <Button 
                    minimal 
                    onClick={ onAddOpen }
                >
                    {__( isOpen ? "Close" : "Add new" )}
                </Button> 
            </ButtonGroup>
        </div>
        <div className="container-fluid px-3 ">
            <Collapse isOpen={!!len} className="px-2">
                <div className="d-flex py-0">
                    <ConfirmButton  
                        buttonIntent={Intent.DANGER}
                        buttonLabel={sprintf(__("Delete %s elements"), len.toString() )}
                        onConfirm={onBulkDelete}
                        dialogClasssName={DIALOG_SIZE.NORMAL}
                    >
                        <div className="p-4">
                            <div className="pb-3">
                                { sprintf( __( "Romove %s element just now?" ), len.toString() ) }
                            </div>
                            <div className="d-flex flex-wrap">
                                {
                                    checks
                                        .map(b => {
                                            const item: IMedia = medias.filter(m => m.id === b.id)[0]
                                            const img = item?.url?.toString() === "false" ? item?.large : item?.url
                                            return <div 
                                                key={b.id} 
                                                className={ "wp-thumbnail position-relative mr-1 mb-1 ml-0 mt-0" } 
                                                style={{ backgroundImage: "url(" + img +")" }} 
                                            />
                                        })
                                }
                            </div>
                        </div>
                    </ConfirmButton>
                    <Button minimal onClick={onBulkClear}>{__("Clear select")}</Button>
                </div>
            </Collapse>
        </div>
        <div className="container-fluid px-3 ">
            <Collapse isOpen={isOpen} className="px-2">
                <Uploader />
            </Collapse>
        </div> 
        <Feed
            data_type="Media"
            offset={0}
            class_name='row flex-centered'
            containerClassName="container-fluid mx-3" 
            component={ MediaThumbnail }
            land_id={landId}
            onLoading={onLoading}
            paging=""
            // order={order}
            params={{
                onOpen, 
                seed
            }}
        />
        <ScalableDialog
            dialogSize={DIALOG_SIZE.LARGE}
            isOpen={isDialogOpen}
            onClose={onClose}
        >
            <MediaElement 
                item={current || {} as IMedia } 
                prev={prev}
                next={next}
            />
        </ScalableDialog>
    </div>
} 

export default MediaListForm
 