import { IPost } from "@/libs/interfaces/layouts"
import { IUser } from "@/settings/zustand"
import { useEffect } from "react"

export interface IRutubeData {
    video_id: string
    thumbnail_url:string
    title: string
    description: string
    viewr: number
    mobile: boolean
    author: IUser
    is_adult: boolean
    is_hidden: boolean
    is_licensed: boolean
    duration: number
    tags: string[]
    category: IPost
}

export default function useRutubeApi( embedCodeOrID: string ): any {
    // console.log( embedCodeOrID ) 
    let id: string = ""
    const matchId: string[] | null = embedCodeOrID.match(/https:\/\/rutube.ru\/play\/embed\/(.*?)\"/)
    if( matchId ) {
        // console.log( matchId[1] )
        id = matchId[1]
    }
    if(!!id) { 
        try { 
            const el = document.createElement('script') 
            el.type = 'text/plain' 
            el.src = `https://rutube.ru/api/play/options/${id}` 
            el.id = 'rutube-src-' + id  
            el.async = true
            el.onload = function ( data ) {
                console.log( data )  
            }
            el.onerror  = ( err ) => {
                console.error( err )  
            }
            document.getElementsByTagName('head')[0].appendChild(el) 
            // fetch(
            //     `https://rutube.ru/api/play/options/${id}`,
            //     {
            //         method: "GET", 
            //         mode: "cors",
            //         headers: {
            //             "Content-Type": "application/json", 
            //             "Access-Control-Allow-Origin": "*"
            //         }, 
            //         redirect: "follow", 
            //         referrerPolicy: "no-referrer"
            //     }
            // )
            // .then( (response: any) => response.json() )
            // .then( (resp: any) => console.log( resp ) )
        }
        catch(e) { } 
    }
    
    // else if(embedCodeOrID.split("/")[2] === "rutube.ru") {
    //     console.log( embedCodeOrID.split("/") )
    // }
    return null
}

export function readTextFile(file: string, callback: (resp: any)=> void) {
    var rawFile: XMLHttpRequest = new XMLHttpRequest();
    rawFile.overrideMimeType("application/json");
    rawFile.open("GET", file, true);
    rawFile.onreadystatechange = function() {
        if (rawFile.readyState === 4 && rawFile.status === 200 ) {
            callback(rawFile.responseText);
        }
    }
    rawFile.send(null);
}
 