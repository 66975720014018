import React from 'react' 
import { TableCheckBox } from './DataTypeRow'
import { Button } from '@blueprintjs/core'
import { getTableRows } from './utils'
import { __ } from '../../../../libs/utilities'
import { Link } from 'react-router-dom'
import { IAdminStore, IBulk, useAdminStore } from '../../data/store'

const DataTableLayout = (props:any) => {
    const bulks: IBulk[]    = useAdminStore((state:IAdminStore) => state.bulks) 
    const setBulks: ((bulks: IBulk[]) => void)  = useAdminStore((state:IAdminStore) => state.setBulks) 
    const items: any[]      = useAdminStore((state:IAdminStore) => state.items) 
    const data_type: string = useAdminStore((state:IAdminStore) => state.data_type) 
    
    const fields = { ...props.params.fields }
    const threads : any[] = getTableRows( fields )
    const colors = !!props.params.fields.admin_data.fill.length ? props.params.fields.admin_data.fill : ["#293742" , "#252c32"]
    const onMainBulk = (checked: boolean) => {
        const _bulks = items.map( (item : any) => 
        {
            return {
                id: item.id,
                data_type,
                checked
            } 
        })  
        console.log(items, _bulks, data_type)
        setBulks( _bulks )
    }
    // console.log( props )
    return <table className={ `table table-striped table-hover ${props.containerClassName} ` }> 
        <thead  
            style={{ 
                backgroundColor : colors[0],
                background: `linear-gradient(to right,  ${colors[0]} 0%,${colors[1]} 100%)`,
                color : "#EEE"
            }}
            
        >
            <tr>
                <th style={{ width: 50 }}>
                    {/* <TableCheckBox 
                        {...props} 
                        checked={ !bulks.filter((bulk: IBulk) => !bulk.checked).length }
                        onChecked={ onMainBulk } 
                    /> */}
                </th>
                {
                    threads.map(thread => {
                        const af = fields.apollo_fields[thread]
                        const style: any ={
                            width: af.col_width,
                            minWidth: af.col_width
                        }
                        return <th key={thread} data-thread={thread} style={style}>
                            { 
                                af.col_icon 
                                    ? 
                                    <div className="hint hint--top" data-hint={af.title} >
                                        <i className={ `${af.col_icon} ` }/> 
                                    </div>
                                    : 
                                    __(af.title) 
                            }   
                        </th>
                    })
                }
                <th  style={{ width: 200 }}>
                    <Link className='' to={`new`}>
                        <Button 
                            minimal

                        >
                            <div className="hint hint--top " data-hint={__("Add new")}>
                                <i className="fas fa-plus fa-2x text-light" /> 
                            </div>                        
                        </Button> 
                    </Link> 
                    {
                        false && <Button 
                            minimal
                        >
                            <div className="hint hint--top" data-hint={__("Package add")}>
                                <i className="fas fa-th fa-2x text-light" />
                            </div>                        
                        </Button>
                    }                 
                     
                </th>
            </tr>
        </thead>
        <tbody>
            { props.children }
        </tbody>
    </table> 
}

export default DataTableLayout