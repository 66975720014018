import { HasId } from "@/libs/interfaces/layouts"
import { IMilestone, IProject, IProjectMilestone, IRating } from "../interfaces"
import { useFestProjects } from "../store"

export default function updateLocalRating( newRatingData: IRating, mayBeMilestone: IMilestone | undefined = undefined ) : boolean {
    const currentProject : IProject = useFestProjects.getState().currentProject
    const currentProjectMilestones: IProjectMilestone[] = useFestProjects.getState().currentProjectMilestones
    const projectMilestone: IProjectMilestone = currentProjectMilestones.filter( (c: HasId ) => c.id === mayBeMilestone?.id)[0]
    useFestProjects.getState().setCurrentProjectMilestones(
        currentProjectMilestones.map(pm => {
            if(pm.id === projectMilestone?.id) {
                return {
                    ...projectMilestone,
                    ratings: projectMilestone.ratings.map(r => {
                        if(r.id === newRatingData?.id) {
                            return newRatingData
                        }
                        return r
                    })
                }
            }
            return pm
        })
    )
    useFestProjects.getState().setCurrentProject(
        {
            ...currentProject,
            ratings: currentProject.ratings.map(r => {
                if(r.id === newRatingData?.id) {
                    return newRatingData
                }
                return r
            })
        }
    )

    return true
}