
import React, { Suspense, useEffect } from 'react' 
import {Loading} from "@/libs/useful"
import {useMainStore, IState, THEME_NAMES, useLayoutStore, ILayoutStore} from '@/settings/zustand'
import LayoutBody from './LayoutBody'
import { cssStyle, template, title } from '../layouts'
import addCSSClass from './utils/addCSSClass'
import removeCSSClass from './utils/removeCSSClass'
import useScreenSize from '@/modules/pe-basic-module/hooks/useScreenSize'
import { WINDOW_WIDTH } from '../interfaces/layouts'

const random  = Math.random()
const LayoutApp = ():React.ReactElement => {
  const {width} = useScreenSize()
  const theme: THEME_NAMES = useMainStore((state:IState) => state.theme)
  const headerHeight: number = useLayoutStore((state: ILayoutStore) => state.headerHeight)
  const footerHeight: number = useLayoutStore((state: ILayoutStore) => state.footerHeight)
  
  const setThemeCSS = () => {
    //console.log(theme)    
    const root = document.documentElement;
    if(theme === THEME_NAMES.NIGHT || theme === THEME_NAMES.EVENING) { 
      root?.style.setProperty(
          "--force-contast-color",
          "#e1e1e1"
      );
      root?.style.setProperty(
          "--force-mini-contast-color",
          "#FFFFFF33"
      );
      root?.style.setProperty(
          "--drop-select-color",
          "#293742"
      );
      root?.style.setProperty(
          "--actual-color",
          "#fde5a8"
      );
      root?.style.setProperty(
          "--actual-force-color",
          "#FFC940"
      );
      addCSSClass(
        'layout-theme', 
        `.layout-menu-left { 
            background-color: #181e24; 
        }
        select option { 
          background-color: #181e24; 
        }
        select::-webkit-scrollbar {
          background:rgb(11, 12, 13)!important; 
          width: 10px!important; 
        }
        select::-webkit-scrollbar-thumb {
          background:rgb(38, 51, 63)!important;  
          width: 6px!important; 
        }
        .pe-font-awesome {
          background-color: #171f27 !important;
        }
        .bp5-numeric-input.bp5-large .bp5-button-group.bp5-vertical > .bp5-button {
          background-color: #181e24;
        } 
        .bp5-tag.bp5-minimal:not([class*=bp5-intent-]) {
          color:#999;
        }
        .text-force-black,
        .text-force-black .bp5-tag.bp5-minimal:not([class*=bp5-intent-])  {
          color: #1c2127!important;
        }
        .text-force-contrast {
          color:#EEE!important;
        }
        .color-force-contrast {
          filter: invert(1);
        }
        
        .layout-menu-left { 
          background-color: #181e24; 
        }
        main {
          background-color: #242e39;
          background: linear-gradient(to right,  rgb(39 47 56) 0%,rgb(26 32 40) 100%);
          color: #bac8c9!important; 
        }
        .icon-unlogined, .icon-logined,
        .page-item.active .page-link,
        .layout-state-logo,
        .layout-state-title {
          color: var(--actual-color)!important;
        }
        .layout-header { 
          border-bottom: 1px solid #aed1e933; 
        }
        .npm__react-simple-code-editor__textarea,
        .pe-code-editor > div  > div > textarea,
        .main-menu a, .main-menu .bp3-button {
          color: var(--actual-color)!important;
          -webkit-text-fill-color: var(--actual-color)!important;
        }
        .active > .layout-menu-icon {
          background-color: #2a3742; 
        }
        .pe-light,
        .card, 
        .bp5-card,
        .logined-menu { 
          background-color: #171f27!important; 
        } 
        .card2 { 
          background-color: #364b5f!important; 
        } 
        path.card_path {
          fill: #1c2937!important; 
        }
        .bp5-minimal, 
        .bp5-minimal:hover {
          color: #bac8c9!important; 
        }
        a {
          color: #bfd7f9;
        } 
        h1, h2, h3, h4, h5, h6, h7, h8 {
          font-family: 'Yanone Kaffeesatz', sans-serif;
          color: var(--actual-color)!important;
        }
        .avatar-svg-path {
          fill: #FFFFFF77;
        }
        .loader-ball-line-svg circle {
          fill: #AAAAAA!important;
        }
        .sb-container { 
          border: 1px dotted #FFFFFF50; 
        }
        .scalar-commentary { 
          color: #d0eaffcc;
        }
        .input.dark {
          border-bottom: 1px solid #ffffff44!important;
          color: #a3b9d6!important;
        }
        .bp5-button:not([class*=bp5-intent-]) {
          background-color: #171f27;
          color: #acb5c2!important;
        }        
        .bp5-button:not([class*=bp5-intent-]):hover {
          background-color: #151b21; 
          color: #aaaaaa!important;
        }
        .bp5-button .bp5-icon, .bp5-button .bp5-icon-standard, .bp5-button .bp5-icon-large {
          color: #acb5c2;
        }
        .bp5-button.bp5-minimal {
            background: none;
            box-shadow: none;
        }
        .bp5-tab {
          color: #8195ac;
        }
        .menu-aside,
        .tutor-main-2 .clapan-left {
          background-color: #4e5c6c;
        }
        .bp5-dialog, .bp5-drawer {
          background: #505964;
          color: #EEE;
        }
        .bp5-heading {
          color: #FFF;
        }
        .bp5-tab {
          color: #c8d1dc;
        }
        .bp5-tab[aria-selected=true], .bp5-tab:not([aria-disabled=true]):hover
        {
          color:#FFF;
        }
        .bp5-tab-indicator-wrapper .bp5-tab-indicator
        {
          background-color: #FFF;
        }
        .bp5-dialog-header {
          background: #545c66;
          color: #EEE;
        }
        .folder-sign svg {
          fill: #d6dbe8;
        }
        .folder-sign { 
          color: #d6dbe8;
        }
        .card-image-icon {
          background-color: #181e24; 
          color: #d2d2d2; 
        }
        .card:hover .card-image-icon {
          color: #ffffff!important; 
        }
        .list-group-item {  
          background-color: #181e24; 
        }
        .bp5-input-group .bp5-input { 
          background: #181e2466;
          color: #FFF!important;
        }
        .bp5-menu {
          background: #1c2937!important; 
          color: #c4d5e9!important;
        }
        .bp5-popover .bp5-popover-arrow-fill {
          fill: #1c2937;
        }
        .loader-cont svg  path{
          fill: #FFF;
        }
        input[type=radio].radio_full:not(checked) + label:before, input[type=checkbox].radio_full:not(checked) + label:before {
          background: #edeff2;
          color:#EEE;
          background: linear-gradient(to bottom, #6c737e 0%, #1e1c39 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#edeff2", endColorstr="#999999",GradientType=0 );
          box-shadow: inset -1px -1px 1px 0 rgb(0, 0, 0), 1px 4px 6px 0 rgb(33 32 32 / 25%), inset 1px 1px 1px 0 rgb(197 197 197 / 50%);
        }
        input[type=checkbox]._checkbox:checked + label:before {
          background: #576a96;
        }
        input[type=checkbox]._checkbox:not(checked) + label:before { 
          background: #6b6e7e;
          box-shadow: inset 0 2px 4px rgb(0 0 0 / 67%);
        }
        .member {
          border-color: #ebe4e4!important; 
          color: #d0cccc!important; 
        }
        .stroke-a-dark a, .a-dark {
          color: #c0cee6 !important;
        }
        .stroke-a-dark:hover a, .a-dark:hover {
          color: #FFFFFF !important;
        }
        .text-secondary {
          color: #b4bcc3 !important;
        }
        .bg-dark-light {
          background-color: #181e24 !important;
        }
        .list-group-item:focus, .list-group-item:hover {
          background-color: #0d10136e!important;
        }
        .bp5-popover .bp5-popover-content {
          background-color: #1c2937;
          color:#EEE;
          border: 1px solid #7d7d7d;
        }
        .bp5-callout.bp5-intent-danger {
          background-color: rgb(205 66 70);
          color: #d6b0b1;
        }
        .bp5-callout.bp5-intent-danger[class*=bp5-icon-]::before, .bp5-callout.bp5-intent-danger > .bp5-icon:first-child, .bp5-callout.bp5-intent-danger .bp5-heading {
          color: #eddbdb;
        }
        .alert-danger {
          color: #c5a5a8;
          background-color: #7e2d34;
          border-color: #58383b;
        }
        .text-clip--transparent { 
          background-clip: text!important;
          -webkit-background-clip: text!important;
          color: #abb9bb;
          background: -webkit-linear-gradient(top,#abb9bb 60%,transparent);
        }
        .colored { 
          background-color: rgb(25 32 38 / 52%);
        }
        .light-colored {
          background-color: #98a8ad1c;
        }
        table td, table td * {
          border-color: #EEEEEE77!important; 
        }
        .layout-state-description {
          color: #fce5a8;
        }
        .bg-light-strobe:nth-of-type(2n) {
          background-color: #0000004d!important;
        }
        .bg-light-strobe:nth-of-type(2n+1) {
          background-color: #0000001a!important;
        }
        .text-dark {
          color:#FFFFFF!important;
        }
        .two-side-card-cont:nth-of-type(2n + 1) .two-side-card h5 {
          color:#242e39!important;
        }
        .purchase-button-container .list-group-item {
          background-color:transparent!important;
        }
        .milestones-cont { 
          border-bottom: 2px solid rgb(255 253 253 / 55%); 
        }
        .milestone-button {
          border: 2px solid rgb(255 255 255 / 55%);
        }
        .milestone-button.active { 
          color: #fffdfd; 
          background-color: #20272f;
        }
        .page-link:not(:disabled):not(.disabled) {
          color: #e1e1e1aa;
        }
        label._check_>input[type=checkbox]:before, 
        label._check_>input[type=radio]:before,
        label._check_blue_>input[type=checkbox]:before, 
        label._check_blue_>input[type=radio]:before {
          background-color: #425061;
          border: 2px solid #d2caee;
        }
        label._check_blue_>input[type=checkbox]:checked:before, 
        label._check_blue_>input[type=radio]:checked:before {
          background-color: #3d568b;
          border: 2px solid #d2caee;
        }
        .bp5-menu-divider {
          border-color:  rgb(213 228 249 / 15%);
        }
        
        .milestone-button  { 
          border-color: #e6e6e6!important;
          color: #FFF!important;
        }
        .milestone-button.active {
          background-color: #20272f!important; 
        }
        .card-top-img::before {
          background: linear-gradient(to bottom,  #00000000 25%, #000000 100%)!important; 
        }  
        .card:hover .card-top-img::before {
            background: linear-gradient(to bottom,  #00000055 0%, #000000 100%)!important;
        }
        `
      )
      if(theme === THEME_NAMES.EVENING) {
        addCSSClass('layout-class-evening', 
          `
          main {
            background: linear-gradient(to top,rgb(84, 105, 127) 3%, #0f141d 125%);
            color:rgb(213, 232, 255) !important;
          }
          .milestone-button.active {
            background-color: #334051 !important;
          }
          .pe-light, .card, .bp5-card, .logined-menu {
            background-color: #203041 !important;
          }
          .btn-link {
            font-weight: 400;
            color: #acd3fd;
            background-color: transparent;
          }
          .input.dark {
            border-bottom: 1px solid rgba(255, 255, 255, 0.15) !important;
            color: #b2d3ff !important;
          }
          .input.dark::placeholder,
          .placeholder { 
            color: rgb(241, 249, 255, 0.33)!important;
          }
          .text-dark-primary {
              color: #041a33 !important;
          }
          .text-dark-success {
            color: #1dce45 !important;
          }
          .alert-danger {
            color: #c5a5a8;
            background-color:rgba(178, 5, 19, 0.33);
            border-color: #58383b;
          }
          .member.no-experts .member_title {
            color: #02060d !important;
          }
          .member.no-experts {
            border-color: #070f1a !important;
          }  
          .list-group-item {
              background-color: #08152380;
          }
          .bg-dark-light {
              background-color: #05192d91 !important;
          }
          `
        ) 
      } 
    }
    else {
      removeCSSClass( 'layout-theme' ) 
      removeCSSClass( 'layout-class-evening' ) 
      root?.style.setProperty(
        "--force-contast-color", 
        "#010101"
      );
      root?.style.setProperty(
          "--force-mini-contast-color",
          "#00000033"
      );
      root?.style.setProperty(
          "--drop-select-color",
          "#45526d"
      );
    }
  }
  useEffect(() => {
    //console.log("layout-app.index: headerHeight, footerHeight")
    let old: HTMLElement | null 
    CSSinks().forEach(cssLink => {
      old = document.getElementById(cssLink.id)
      if( old)  old.remove();
      const el: HTMLLinkElement = document.createElement("link")
      el.rel = "stylesheet"
      el.type = "text/css"
      el.href = cssLink.href
      el.id = cssLink.id
      document.getElementsByTagName("head")[0].appendChild(el)
    })
    CSSScripts().forEach(cssScript => {
      old = document.getElementById(cssScript.id)
      if( old)  old.remove();
      const el: HTMLScriptElement = document.createElement("script") 
      el.type = "text/javascript"
      el.src = cssScript.href
      // el.async = true
      el.id = cssScript.id
      setTimeout(() => {
        document.getElementsByTagName("body")[0].appendChild(el)
      }, cssScript.timeout || 1)
      
    })

    const root = document.documentElement;
    //calculating main section's height
    const menuHeight =  width > WINDOW_WIDTH.TABLET ?  template().header?.height || 0 : 75
    let UIShiftHeight: number = menuHeight + footerHeight
    const headerIconicHeight = template().header_iconic_height || 40
    const menuIconSize = 45
    const iconicWidth = 100
    root?.style.setProperty(
        "--header-height",
        menuHeight + "px"
    );
    root?.style.setProperty(
        "--main-height",
        "calc(100vh - " + (UIShiftHeight) + "px)"
    );
    root?.style.setProperty(
        "--header-height-symbol",
        (menuHeight - 30) + "px"
    );
    root?.style.setProperty(
        "--header-height-icon",
        (
          headerIconicHeight
            ?
            headerIconicHeight
            :
            menuHeight > 80 
              ? 
              40 
              : 
              menuHeight < 55 
                ? 
                25 
                : 
                menuHeight - 40
        ) + "px"
    );
    root?.style.setProperty(
        "--header-loginned-menu-top",
        (menuHeight - 0) + "px"
    );
    root?.style.setProperty(
        "--iconoc-width",
        ( iconicWidth ) + "px"
    );
    root?.style.setProperty(
        "--header-menu-size",
        ( menuIconSize ) + "px"
    ); 
    root?.style.setProperty(
        "--dark-hover-color",
        "#00000066"
    );
    root?.style.setProperty(
        "--main-background",
        "linear-gradient(to right,  rgb(39 47 56) 0%,rgb(26 32 40) 100%)"
    );
    root?.style.setProperty(
        "--force-dark-color",
        "rgb(39 47 56)"
    );
    root?.style.setProperty(
        "--force-more-dark-color",
        "#191e24"
    );
    
    addCSSClass(
      'layout-app', 
      `.layout-app-state {
          min-height: calc(100vh - ${UIShiftHeight}px); 
          height: 100%;
          width:100%;
          position:relative; 
      }
      .menu-row {
        height: calc(100vh - ${UIShiftHeight}px);
      }
      .pe-mobile-under-menu-bottom {
        position: absolute;
        top:auto;
        bottom: 75px;
      }`
    )
    document.title = title() 
  }, [ headerHeight, footerHeight ]) 
  useEffect( () => {
    setThemeCSS()
  }, [ theme ] )

  useEffect(() => {
    const menuHeight = width > WINDOW_WIDTH.TABLET ?  template().header?.height || 0 : 75
    const footer = template().footer?.height || 0 
    useLayoutStore.setState({ 
      headerHeight : menuHeight,
      footerHeight: footer
    })
    const tmt = setTimeout(() => {
      setThemeCSS()
      addCSSClass(
        `layout-stylize-css`,
        `.layout-header {
          background: ${ template().header?.bg };
          color: ${ template().header?.color };
        }
        .layout-header-title,
        .user-name,
        .header-menu-label {
          -webkit-text-fill-color: ${ template().header?.color }!important;
          color: ${ template().header?.color }!important;
        }
        .layout-menu-main {
          background: ${ template().left?.bg }!important;
          color: ${ template().main_left?.color }!important;
        }
        .layout-menu-left {
          background:${ template().main_left?.bg }!important;
          color: ${ template().main_left?.color }!important;
        }
        .layout-left-btn {
          -webkit-text-fill-color: ${ template().main_left?.color }!important;
          color: ${ template().main_left?.color }!important;
        }
        .layout-left-btn.active {
          -webkit-text-fill-color: ${ template().main_left?.active }!important;
          color: ${ template().main_left?.active }!important;
        }
        .list-element,
        .list-element-child {
          color: ${ template().main_left?.color }!important;
        }
        .list-element:hover, .list-element.active, 
        .list-element-child:hover, .list-element-child.active {
          color: ${ template().main_left?.active }!important;
        }
        .layout-footer-container {
          background:${ template().footer?.bg }!important;
          color: ${ template().footer?.color }!important;
        }
        `
      )
    }, 100);
    return () => {
      clearTimeout(tmt)
    }
  }, [])
  return (
    <Suspense fallback={<Loading />}> 
      <LayoutBody />
    </Suspense>
  )
} 

export default LayoutApp


const CSSinks = (): any[] => [
    {
      id :    "normalize",
      href :  "https://unpkg.com/normalize.css@^7.0.0"
    },
    {
      id :    "fontawesome",
      href :  "https://use.fontawesome.com/releases/v5.15.4/css/all.css"
    },
    {
      id :    "googleFonts",
      href :  "https://fonts.googleapis.com/css?family=Amatic+SC|Ubuntu+Condensed|Yanone+Kaffeesatz|Open+Sans+Condensed:300|Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap|Yanone+Kaffeesatz:200&display=swap"
    },
    {
      id :    "googleFonts2",
      href :  "https://fonts.googleapis.com/css?family=PT+Sans:regular,italic,bold,bolditalic"
    },
    {
      id :    "socialLikes",
      href :  "/assets/css/social-likes.css"
    },
    {
      id :    "bootstrapUtility",
      href :  "/assets/css/bootstrap.min.css"
    },
    {
      id :    "blueprint",
      href :  "/assets/css/blueprint.css"
    },
    {
      id :    "blueprintIcons",
      href :  "/assets/css/blueprint-icons.css"
    },
    {
      id :    "phone",
      href :  "/assets/css/phone.css"
    },
    {
      id :    "external-css",
      href :  `${cssStyle()}?${random}`
    },
  ]
const CSSScripts = (): any[] => [
    
    {
      id: "classnames-jssdk",
      href: "https://unpkg.com/classnames@^2.2"
    },
    {
      id: "youtube-jssdk",
      href: "https://www.youtube.com/iframe_api"
    },
    // {
    //   id: "jitsi-jssdk",
    //   href: "https://jitsi.protopia-home.ru/external_api.js"
    // },
    {
      id: "jquery",
      href: "/assets/jquery-3.7.1.min.js"
    },
    {
      id: "social-likes-jssdk",
      href: "/assets/social-likes.js",
      timeout: 1000
    },
    // {
    //   id: "popper",
    //   href: "https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js"
    // },
    // {
    //   id: "bootstrap",
    //   href: "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"
    // },
]
