import { useAppoloStore, useMainStore } from '../../../settings/zustand'
import { externalToken, getChangeName, getInputTypeName, getMutationArgs, getQueryArgs, getQueryName, mutationDelete, mutationEdit, mutationToken, querySingle, querySingleName, queryUserInfo } from 'src/libs/layouts'
import {
  MUTATION_TOKEN_ACTION,
  RESTORE_PASSWORD_ACTION,
  USER_INFO_ACTION,
  RESET_PASSWORD_ACTION,
  RESTORE_FINISH_PASSWORD_ACTION,
  RESTORE_FINISH_PASSWORD_ACCESS_ACTION,
  VERIFY_USER_ACTION,
  SEARCH_ACTION,
  UPDATE_CURRENT_USER,
  GET_FEED_ACTION,
  GET_FEED_COUNT_ACTION,
  GET_SINGLE_ACTIONS,
  UPDATE_SINGLE_TYPE,
  DELETE_SINGLE_TYPE,
  DELETE_BULK_ACTION,
  UPDATE_BULK_ACTION,
  GET_POST_LIKES_ACTION,
  MUTATION_EXTERNAL_TOKEN_ACTION,
  GET_SEARCH_USERS_ACTION,
  GET_ALL_ROLES_ACTION,
  GET_SERVICE_OPTIONS_ACTION,
  CHANGE_SERVAICE_OPTIONS_ACTION,
  CREATE_SITE_ACTION,
  DELETE_SITE_ACTION,
  GET_DICTIONARY_ACTION,
  LOGOUT_ACTION,
  GET_RUTUBE_DATA_ACTION,
  REGISTER_USER_ACTION, 
  GET_LAND_VERSION_ACTION,
  GET_SUPER_ADMIN,
  SET_SUPER_ADMIN,
  VERIFY_EMAIL_ACTION,
  CHANGE_USER_ROLES_ACTION,
  EXTERNAL_SIGN_IN_ESCAPE_ACTION,
  CHANGE_LAND_ACTION,
  SEND_LOG_ACTION,
} from './actionTypes'
import { DocumentNode } from 'graphql'  
import {likes} from '@/modules/pe-basic-module/data/mocks/likes'
import { setRequestError } from '@/settings/errorControllers/setRequestError'
const { gql } = require('@apollo/client/core')

const asyncControllers = async (action: string, data: any) => {
  let response: Promise<any> = new Promise<any>( (resolve, reject) => {
      resolve({})
  })
  let mutation: DocumentNode, query: DocumentNode, query_name: string, mutationString:string, fields: any
  let site__, site__2, land_id
  const apolloClient: any = useAppoloStore.getState().client
  
  try {
    switch (action) { 
      case GET_ALL_ROLES_ACTION:
        response = await apolloClient.query({
          query: gql`query getAllRoles  {
            getAllRoles 
          }` ,
        }) 
        return response
      case GET_LAND_VERSION_ACTION:
        response = await apolloClient.query({
          query: gql`query getLandVersion { getLandVersion }` ,
        }) 
        return response
      case GET_SERVICE_OPTIONS_ACTION:
        response = await apolloClient.query({
          query: gql`query getServiceOptions  {
            getServiceOptions{ roles } 
          }` ,
        }) 
        return response
      case GET_DICTIONARY_ACTION:
        response = await apolloClient.query({
          query: gql`query  getDictionary {
            getDictionary(language: "ru-RU") {
              language
              dictionary
            }
          }` ,
        }) 
        return response
      case CHANGE_SERVAICE_OPTIONS_ACTION:
        let params = "";
        if(data.item?.roles) {
          params += ` roles: ["${data.item.roles.join('","') }"] `
        }
        if( !params ) return response
        const ql = `mutation changeServiceOptions {
            changeServiceOptions(
              input: 
                { ${ params } }
            )
            { roles } 
          }` 
        response = await apolloClient.mutate({
          mutation: gql`${ql}` ,
        }) 
        return response
      case MUTATION_EXTERNAL_TOKEN_ACTION: 
        response = await apolloClient.mutate({
          mutation: externalToken(),
          variables: {
            input: {
              grant_type: 'client_credentials',
              user: data.user || {},
              external: data.external    || '',
            },
          },
        })
        return response
      case VERIFY_EMAIL_ACTION: 
        response = await apolloClient.mutate({
          mutation: gql`
          mutation verifyEmailUser($id: String $code: String) {
            verifyEmailUser(id:$id, code:$code)
          }`,
          variables: data,
        })
        return response
      case MUTATION_TOKEN_ACTION: 
        const mToken = mutationToken()
        response = await apolloClient.mutate({
          mutation: mToken,
          variables: {
            input: {
              grant_type: 'client_credentials',
              login: data.login || '',
              password: data.password || '',
            },
          },
        })
        return response
      case LOGOUT_ACTION:
        const gqllogout = `mutation logout { logout }`
        console.log(gqllogout) 
        response = await apolloClient.mutate({
          mutation: gql`${gqllogout}` ,
        }) 
        break;
      case USER_INFO_ACTION:
        response = apolloClient.query(
          { 
            query: queryUserInfo( data.landId ), 
            fetchPolicy: 'network-only' 
          }
        )
        return response 
      case RESTORE_PASSWORD_ACTION:
        const mutation_remember = gql`
          mutation restorePass($email: String) {
            restorePass(email: $email)
          }
        `
        response = await apolloClient.mutate({
          mutation: mutation_remember,
          variables: {
            email: data.email,
          },
        })
        return response
      case RESTORE_FINISH_PASSWORD_ACTION:
        const mutationFinishRestore = gql`
          mutation compareRestore($id: String, $code: String) {
            compareRestore(id: $id, code: $code)
          }
        `
        response = await apolloClient.mutate({
          mutation: mutationFinishRestore,
          variables: data,
        })
        return response
      case RESTORE_FINISH_PASSWORD_ACCESS_ACTION:
        mutation = gql`
          mutation saveNewPassword($id: String, $password: String, $code: String) {
            saveNewPassword(id: $id, password: $password, code: $code)
          }
        `
        response = await apolloClient.mutate({
          mutation,
          variables: data,
        })
        return response
      case RESET_PASSWORD_ACTION:
        response = await apolloClient.mutate({
          mutation: mutationToken(),
          variables: {
            password: data.password,
            passwordNew: data.passwordNew,
          },
        })
        return response
      case VERIFY_USER_ACTION:
        mutation = gql`
          mutation verifyUser($id: String, $code: String) {
            verifyUser(id: $id, code: $code)
          }
        `
        response = await apolloClient.mutate({
          mutation,
          variables: data,
        })
        return response
      case SEARCH_ACTION:
        query = gql`
          query getPosts {
              getPosts( paging: { offset: ${data.offset || 0}, count: 25, search:  "${data.search}" } ) {
                  id
                  post_title
                  post_content
              }
          }`
        response = await apolloClient.query({
          query,
          variables: data,
        })
        return response
      case CREATE_SITE_ACTION:
        mutation = gql`
          mutation registerLand( $input: PEFestFormInput ) {
            registerLand ( input: $input ) {
              id
              name
            }
          }`
        response = await apolloClient.mutate({
          mutation,
          variables: {
            input: {
              title: data.item.title,
                domain: data.item.domain,
                source: data.item.pattern?.id,
                startDate: data.item.startDate,
                finishDate: data.item.finishDate
            }           
          },
        })
        return response
      case GET_SUPER_ADMIN:
        const mASA = `
        query getAllSuperAdmins {
          getAllSuperAdmins {
            id
            display_name
            roles
            email
          }
        }`
        query = gql`${ mASA }`
        response = await apolloClient.query({ query })
        return response
      case SET_SUPER_ADMIN:
        const sSA = `
        mutation setSuperAdmin {
          setSuperAdmin( input: { user_id: "${data.user_id}", is_add: ${data.is_add} } ) 
        }`
        console.log( sSA )
        mutation = gql`${ sSA }`
        response = await apolloClient.mutate({ mutation })
        return response
      case DELETE_SITE_ACTION:
        const mm = `
        mutation deleteLand {
          deleteLand ( id: "${ data.land_id }" )
        }`
        console.log( mm )
        mutation = gql`${mm}`
        response = await apolloClient.mutate({ mutation })
        return response
      case UPDATE_CURRENT_USER:
        mutation = gql`
          mutation changeCurrentUser($input: UserInput) {
            changeCurrentUser(input: $input) {
              id
              display_name
              first_name
              last_name
              user_email 
              phone
              phone
              avatar 
            }
          }
        `
        response = await apolloClient.mutate({
          mutation,
          variables: {
            input: data,
          },
        })
        return response
      case GET_FEED_ACTION:
        // TODO:: проверять готовность apolloClient и только потом сыпать на него запросы.
        if(!!apolloClient.query) {
          query_name = getQueryName(data.data_type)
          fields = getQueryArgs(data.data_type)
          // console.log( "constroller.GET_FEED_ACTION:", data )
          const paging = data.paging
            ?
            `, ${ data.paging }`
            :
            ``
          land_id = data.land_id 
            ?
            `land_id: "${ data.land_id }"`
            :
            ``
          const queryString = `
          query ${query_name} { 
            ${query_name}( 
              paging:{ count:${data.count || useMainStore.getState().pageCount}, offset:${data.offset || 0} ${paging}}
              ${land_id}
            )
            {
                ${fields}
            }
          }`
          // console.log( queryString )
          query = gql`${queryString}`
          response = await apolloClient.query({
            query,
            variables: {
              input: data,
            },
          }) 
        } 
        return response
      case GET_FEED_COUNT_ACTION:
        query_name = `${querySingleName(data.data_type)}Count`
        land_id = data.land_id 
          ?
          `land_id: "${ data.land_id }"`
          :
          ``
        const paging = data.paging 
          ?
          `( paging:{ ${ data.paging } } ${ land_id } )`
          :
          `` 
        const queryString1 = `
          query ${query_name} {
              ${query_name} ${paging} 
          }` 
        // console.log( queryString1 )
        query = gql`${queryString1}`
        response = await apolloClient.query({
          query,
          variables: {
            input: data,
          },
        })
        return response 
      case GET_POST_LIKES_ACTION:
        response = new Promise<any>((resolve, reject) => {
          resolve( { data: { getLikes: likes() } } )
        })
        return response
      case GET_SINGLE_ACTIONS:
        query_name = querySingleName(data.data_type)
        fields = getQueryArgs(data.data_type)
        query = querySingle(data.data_type, query_name, fields, data.id, data.land_id)  
        response = await apolloClient.query({
          query,
          variables: {
            id: data.id,
            land_id: data.land_id
          },
        })
        return response
      case UPDATE_SINGLE_TYPE:  
        const m: any = mutationEdit(
          data.data_type,                     // mutate data type
          getChangeName(data.data_type),      // GQL mutation name
          getInputTypeName(data.data_type),   // input type
          getMutationArgs(data.data_type),     // chenged arguments,
          data.landId
        ) 

        response = await apolloClient.mutate({
          mutation: m,
          variables: {
            id: data.id,
            input: data.item,
            land_id: data.landId
          },
        })
        return response
      case UPDATE_BULK_ACTION:
        console.log(data)    
        site__ = data.landId ? `, $land_id: ID ` : ``     
        site__2 = data.landId ? `, land_id: $land_id` : ``     
        mutationString = `mutation changeBulk${data.data_type}($id:[ID], $input:${data.data_type}Input ${site__}) {
          changeBulk${data.data_type} ( id:$id, input:$input ${site__2} ) {
            id 
          } 
        }` 
        console.log(mutationString)    
        response = await apolloClient.mutate({
          mutation: gql`${mutationString}`,
          variables: {
            id: data.id,
            input: data.data,
            land_id: data.landId
          },
        })
        return response
      case DELETE_SINGLE_TYPE:  
        response = new Promise( (resolve, reject) => {
          console.log("controller", data.id)
          setTimeout(() => resolve({data:{deletePEFestProject: {id: data.id} }}), 500)
        })
        return response
      case DELETE_BULK_ACTION:  
      site__ = data.landId ? `, $land_id: ID ` : ``     
      site__2 = data.landId ? `, land_id: $land_id` : ``  
        mutationString = `mutation deleteBulk${data.data_type}($id:[ID] ${site__}) {
          deleteBulk${data.data_type} (id:$id ${site__2}) {
            id 
          } 
        }` 
        response = await apolloClient.mutate({
          mutation: gql`${mutationString}`,
          variables: {
            id: data.id,
            land_id: data.landId
          },
        })
        return response
      case GET_SEARCH_USERS_ACTION:
        //console.log( data )
        land_id = data.landId ? `land_id: "${data.landId}"` : ``
        const role__in = Array.isArray(data.role)
          ?
          'role__in: ["' + data.role.join('","') + '"],'
          :
          ``
        const str: string = `query searchUsers {
            getUsers(
              ${land_id}
              paging:{
                order:"display_name",
                ${role__in}
                search: "${data.search}"
              }
            ) {
              id
              display_name
              avatar
              roles 
            }
          }`
        //console.log( str )
        query=gql`${str}`
        response = await apolloClient.query({
          query, 
        })
        return response
      case REGISTER_USER_ACTION:
        land_id = data.landId ? `land_id: "${data.landId}"` : ``
        mutationString = `mutation registerUser($input: UserInput) {
          registerUser(input: $input)
        }`        
        mutation = gql`${mutationString}`
        response = await apolloClient.mutate({
          mutation,
          variables: {
            input: data.item,
            land_id: data.landId
          }, 
        })
        return response
      case GET_RUTUBE_DATA_ACTION:
        query = gql`query getRutubeData {
          getRutubeData(id: "${data.id}") {
            title
            thumbnail_url
            video_url
          }
        }`
        response = await apolloClient.query({
          query, 
        })
        return response
      case CHANGE_USER_ROLES_ACTION:
        mutation = gql`mutation changeUserRoles($id: ID!, $land_id: ID, $input: [UserRoleChangeInput]!) {
          changeUserRoles(id: $id, land_id:$land_id, input: $input) {
            id
            display_name
            roles
            avatar 
          }
        }`
        response = await apolloClient.mutate({
          mutation, 
          variables: {
            land_id: data.landId,
            id: data.id,
            input: data.roles
          }
        })
        return response
      case EXTERNAL_SIGN_IN_ESCAPE_ACTION:
        query = gql`query externalSignInExt($response: String!, $external: String!, $label: String!) {
          externalSignInExt(response: $response, external: $external, label: $label)  
        }`
        response = await apolloClient.query({
          query, 
          variables: {
            response: data.response,
            external: data.external,
            label: data.label
          }
        })
        return response
      case CHANGE_LAND_ACTION:
        mutation = gql`mutation changeLand($id: String, $input: LandInput ) {
          changeLand(id: $id, input: $input)  {
            startDate
            finishDate
          }
        }`
        response = await apolloClient.mutate({
          mutation, 
          variables: {
            id: data.id,
            input: data.input
          }
        })
        return response
      case SEND_LOG_ACTION:
        mutation = gql`mutation sendLog(  $input: String!, $log_type: String!, $land_id: ID ) {
          sendLog( input: $input, land_id: $land_id, log_type: $log_type) 
        }`
        // console.log(data)
        response = await apolloClient.mutate({
          mutation, 
          variables: {
            log_type: data.log_type,
            input: JSON.stringify( data.input ),
            land_id: data.land_id,
          }
        })
        return response
      default:
        return new Promise<any>((resolve, reject) => {
          resolve(`Unknown action type: ${action}`)
        })
    }
  }
  catch( error: any ) {
    console.log( action, data, error )
    setRequestError( action, data, error ) 
   
    return response
  }
  finally {
    return response
  }
}
export default asyncControllers
