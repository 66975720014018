import LoginForm from "@/modules/pe-basic-module/views/profile/LoginForm"
import { useMainStore } from "@/settings/zustand"

/* Protopia Ecosystem component */ 
const OnlyLoggedInForm = ( ): JSX.Element => {
    const onOpenLogin = () => {
        useMainStore.setState({ isOpenLogin: true })
    }
    const onResult = () => {  
        window.location.reload()
    }
    return <div 
            className="layout-app-state overflow-hidden flex-centered "   
          >
            <div 
              className="layout-state-bg"   
            />
            <div className=" ">
                <LoginForm onResult={onResult} onResultArgs={{}} />
            </div>
          </div>
}
export default OnlyLoggedInForm