
import { ID, IMenuItem } from "@/libs/interfaces/layouts"
import { useEffect, useState } from "react"
import { IFestDataStor, IFestStore, useFestDataStore, useFestStore } from "../data/store"
import { useFestival } from "../hooks/festival"
import { useParams } from "react-router"
import { IFestFilters, IFestival, ITrackAttachment } from "../data/interfaces"
import { useSearchParams } from "react-router-dom"
import PEFestProjectTerm from "./projectList/PEFestProjectTerm"
import Feed from "@/modules/pe-basic-module/views/core/Feed"
import { Loading } from "@/libs/useful"
import { actions } from "../data/actions"
import { GET_TRACK_ATTACHMENTS } from "../data/actionTypes"
import FieldInput from "@/libs/scalars"
import { VerticalType } from "@/libs/scalars/interface/IScalarForm"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { Callout, Intent } from "@blueprintjs/core"
import { __ } from "@/libs/utilities"

/* Protopia Ecosystem component */
export interface ITrackAttachmentListFormProps {
    route? : IMenuItem
    festId?: ID
    parentMode?: boolean
}
const TrackAttachmentListForm = (props: ITrackAttachmentListFormProps): JSX.Element => {
    
    const params = useParams()
    const festId: ID = params.landId || "-1" 
    // пагинация для фильтров ТРЕКИ, СОТЫ, ЖАНРЫ
    const filters: IFestFilters     = useFestStore( (state:IFestStore) => state.filters )
    
    const [offset, setOffset] = useState<number>(0)  
    const [fullCount, setFullCount] = useState<number>(0)
    const over = ``
    const [paging, setPaging] = useState<string>( "" )
    const [URLSearchParams, SetURLSearchParams] = useSearchParams()
    const attachments: ITrackAttachment[] = useDataStore( (state: any) => state.PETrackAttachment || [] )

    const updateURLSearchParams = () => {
        let params: any = {}
        if(filters.tracks[0] !== -1) {
            params.tack = filters.tracks.join(",")
        }
        if(filters.schools.length > 1) {
            params.schools = filters.schools.join(",")
        }
        SetURLSearchParams( params )
    }
    useEffect(() => {
        const tracks = URLSearchParams.get("tack")
        // console.log( tracks )
        if(tracks) {
            useFestStore.setState({
                filters: {
                    ...filters, 
                    tracks: [ Number(tracks) ]}
                })
        }
        const schools = URLSearchParams.get("schools")
        if(schools) {
            useFestStore.setState({
                filters: {
                    ...filters, 
                    schools: schools.split(",") }
                })
        }
        if( tracks ) {
            actions(GET_TRACK_ATTACHMENTS, {land_id: festId, track_id: tracks }) 
        }
    }, [ URLSearchParams ])
    useEffect(() => {
        setOffset(0) 
        updateURLSearchParams()
        
    }, [ filters ])

    const isLoading = useFestival(!props.parentMode, festId) 

    if(isLoading ) return <Loading /> 

    return <div className="mx-auto w-100">
        <PEFestProjectTerm />
        <div className='mx-auto container p-0'>
            <div className="row">                
            {
                !!attachments.length
                    ?
                    attachments.map(a => {
                        return  <div className="col-md-4 my-2" key={ a.ID }>
                            <div className="title">
                                { a.descr }
                            </div>
                            <FieldInput
                                type={a.type}
                                vertical={VerticalType.VERTICAL}
                                value={a.data}
                                height={260}
                                width={"100%"}
                            />
                        </div>
                    })
                    :
                    <Callout intent={Intent.DANGER} className="p-5 m-4">
                        { __("No elements exists") }
                    </Callout>
            }  
            </div>
        </div>
    </div>
}
export default TrackAttachmentListForm

 