import { DIALOG_SIZE } from "@/libs/interfaces/layouts"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import UserSearchEngine from "@/libs/useful/UserSearchEngine"
import { __, sprintf } from "@/libs/utilities"
import { IState, IUser, useMainStore } from "@/settings/zustand"
import { Collapse, Intent } from "@blueprintjs/core"
import { useState } from "react"
import { actions } from "../../data/actions"
import { BAN_EXPERT_ACTION } from "../../data/actionTypes"
import { AppToaster } from "@/libs/useful"

/* Protopia Ecosystem component */
const BanExpertForm = (): JSX.Element => {
    const landId            = useMainStore((state:IState) => state.landId )
    const [user, setUser]   = useState<IUser | null>( null )

    const onSelect = (user: IUser) => {
        setUser(user)
    }
    const onBan = () => {
        if( user?.id ) {
            actions(BAN_EXPERT_ACTION, {user_id: user.id})
                .then((resp) => {
                    AppToaster.show({
                        message: sprintf(__("Successfully expert banned and remove %s ratings"), resp)
                    })
                })
        }
    }

    return <div className='container'>
        <div className=" mt-4 mb-2 text-uppercase ">
            {__("Choose expert for ban and remove all his ratings")}
        </div>
        <UserSearchEngine 
            user={ user }
            landId={landId} 
            onSelect={onSelect}
            isMulti={false}
            onlyThisLands
        />
        <Collapse isOpen={ !!user?.id && user?.id !== "-1"} >
            <ConfirmButton
                buttonLabel={ <div 
                    className=" text-center" 
                    dangerouslySetInnerHTML={{
                        __html: sprintf( 
                            __("Ban %s and remove all his ratings"), 
                            `<i class="fas fa-user ml-1 small title" ></i> ${user?.display_name || "expert"}` 
                        )
                    }} 
                /> }
                buttonFill
                buttonIntent={Intent.DANGER}
                dialogClasssName={DIALOG_SIZE.MINIMAL} 
                onConfirm={onBan}
            >
                <div 
                    className="p-5 lead"
                    dangerouslySetInnerHTML={{
                        __html: sprintf( 
                            __("Ban %s and remove all his ratings"), 
                            `<i class="fas fa-user ml-1 small title" ></i> ${user?.display_name || "expert"}` 
                        )
                    }} 
                /> 
            </ConfirmButton>
        </Collapse>
    </div>
}
export default BanExpertForm