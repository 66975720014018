import { HTML } from "../interfaces/layouts";

export default function clearHTML(string: HTML): HTML {
    const res: HTML = string
        .replaceAll(`\n`, `</p><p>`)
            .replace(/\b"/gi, "»")
                .replace(/"\b/gi, " «")
                    .replace(/^"/gi, " «")
                        .replace(/"$/gi, "» ")
                            .replaceAll('" ', "» ")
                                .replaceAll(' "', " «")
                                    .replaceAll('"', "»")
                                        .replace(/ (-){0,} /gi, " — ")
                                        //.replace(/["]/g, "'")
    //console.log( res)
    return res
}