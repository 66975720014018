
import React from 'react' 
import { Button, ButtonGroup, Intent } from '@blueprintjs/core'
import {Widgets, __} from '@/libs/utilities'
import { cardTypes } from '../../data/cardTypes'
import { CARD_SORT_TYPE, CARD_TYPE, IFestival } from '../../data/interfaces'
import CardFilterEngine from './CardFilterEngine' 
import SearchEngine from './SearchEngine'
import FiltersLabel from './FiltersLabel'
import { LoaderBallsLine } from '@/libs/useful' 
import { IFestDataStor, useFestDataStore } from '../../data/store'
import VerifiedFilterEngine from './VerifiedFilterEngine'
import MyRolesLabel from '../MyRolesLabel'
import PhaseLabel from '../PhaseLabel'
import AllProjectsCountLabel from './AllProjectsCountLabel'

const PListLayout = (props: any): JSX.Element => {
    const festival: IFestival = useFestDataStore((state:IFestDataStor) => state.festival ) 
    
    const onCardType = (cardType:CARD_TYPE) => {
        props.params.onCardType(cardType)
    }
    const onOrder = (order: CARD_SORT_TYPE) => {
        props.params.onCardSort(order) 
    }
    return <div className={ ` ${props.containerClassName} pt-3 p-0` }>
        <div className={`${ props.class_name  } mb-4 w-100 d-flex justify-content-between align-items-center`} style={ props.style }> 
            
            <PhaseLabel className='mx-0'/>
            <MyRolesLabel />
        </div> 
        <div className={ props.class_name  } style={ props.style }> 
            <div className="d-flex w-100">
                <div className="mt-5 position-relative">
                    <ButtonGroup vertical className='mr-3 sticky-top'>
                        {
                            festival.isChangeCardType &&
                                cardTypes().map(cardType => {
                                    // if( cardType.type !== props.params.cardType) return null
                                    let is:boolean = cardType.type === props.params.cardType
                                    if( props.params.cardType === CARD_TYPE.NONE ) {
                                        is = cardType.type === festival.memberCardType
                                    }
                                    return <Button 
                                        key={cardType.type}
                                        disabled={ is }
                                        minimal={ !is }
                                        intent={ !is ? Intent.NONE : Intent.DANGER }
                                        data-hint={ __(cardType.title) }
                                        className={`hint hint--right flex-centered `}
                                        onClick={ () => onCardType(cardType.type) }
                                    >
                                        <i className={cardType.icon + ( is ? "" : " opacity_5" ) } />
                                    </Button>
                                })
                        }
                    </ButtonGroup>
                </div>
                <div className='flex-grow-100 max-w-100'>
                    <div className='row'>
                        <div className='col-md-6 col-12 d-flex align-items-center '>
                            <CardFilterEngine 
                                cardSort={ props.params.cardSort } 
                                onClick={onOrder}
                            />  
                            <VerifiedFilterEngine />
                            <AllProjectsCountLabel fullCount={props.params.fullCount}/>
                        </div>
                        <div className='col-md-6 col-12 d-flex align-items-center '>
                            <div className='ml-3 mr-auto'>
                                { props.isLoading  && <LoaderBallsLine /> }
                            </div>  
                            <Widgets 
                                areaName='fest-project-list-filter-hz' 
                                data={{}} 
                                defArea={<div />} 
                            />                          
                            <SearchEngine />
                            <FiltersLabel />
                        </div>
                    </div>
                    <div className="row w-100 pr-4 pr-md-0">
                        { props.children }
                    </div>                    
                </div>
            </div>
            
        </div>  
    </div> 
}

export default PListLayout