import { ID } from "@/libs/interfaces/layouts"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { AppToaster } from "@/libs/useful"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __ } from "@/libs/utilities"
import FieldInputGroup from "@/modules/pe-admin-module/views/single/FieldInputGroup"
import actions from "@/modules/pe-basic-module/data/actions"
import { UPDATE_SINGLE_TYPE } from "@/modules/pe-basic-module/data/actionTypes"
import { ITrack } from "@/modules/pe-fest-module/data/interfaces"
import { IFestDataStor, useFestDataStore } from "@/modules/pe-fest-module/data/store"
import { useIsTrackModerator } from "@/modules/pe-fest-module/hooks/track"
import { isRole } from "@/settings/zustand/user"
import { Intent } from "@blueprintjs/core"
import { useEffect, useState } from "react"

/* Protopia Ecosystem component */
export interface ITrackEditorProps {
   item: ITrack | null

}
const TrackEditor = (props: ITrackEditorProps): JSX.Element => {
    const landId: ID        = useFestDataStore((state: IFestDataStor) => state.festId)
    const [item, setItem]   = useState<ITrack>(props.item ? props.item : {} as ITrack)
    const isModerator : boolean = useIsTrackModerator(item)

    useEffect(() => {
        setItem({...item, ...props.item})
    }, [ props.item ])

    const onField = (value: any, field: keyof ITrack) => {
        setItem({
            ...item,
            [field]: value
        })
    }
    const editTrack = ( ) => {
        let input = {
            title: item.title,
            content: item.content,
            video_id: item.video_id,
            vk_url: item.vk_url,
            is_closed: item.is_closed,
            // last_ad: item.last_ad?.id,
            moderator: item.moderator?.id,
            moderator2: item.moderator2?.id,
        } 
        // console.log( input )
        actions(UPDATE_SINGLE_TYPE, {
            data_type: "PEFestTrack",
            id: item.id,
            landId,
            item: input
        })
        .then(res => AppToaster.show({
            message: __("Update successfully")
        }))
    }

    return isModerator || isRole([ "administrator", "SuperAdmin" ])
        ?
        <ConfirmButton
            buttonIntent={Intent.DANGER}
            buttonMinimal
            buttonIcon="edit"
            onConfirm={editTrack}
            buttonClassName="ml-3"
            dialogTitle={__("Edit Track")}
        >
            <div className="p-4 overflow-y-auto" style={{maxHeight: "calc(100vh - 200px)"}}> 
                <FieldInput
                    value={item.title}
                    editable
                    title={__("Title")}
                    onChange={value => onField(value, "title")}
                />
                <FieldInput
                    value={item.content}
                    editable
                    title={__("Content")}
                    onChange={value => onField(value, "content")}
                    type={SCALAR_TYPES.TEXT}
                />
                <FieldInput
                    value={item.moderator}
                    editable
                    title={__("Moderator")}
                    onChange={value => onField(value, "moderator")}
                    type={SCALAR_TYPES.USER}
                />
                <FieldInput
                    value={item.moderator2}
                    editable
                    title={__("Moderator 2")}
                    onChange={value => onField(value, "moderator2")}
                    type={SCALAR_TYPES.USER}
                />
                <FieldInput
                    value={item.last_ad}
                    editable
                    title={__("Last Ad")}
                    component="Ad"
                    landId={landId}
                    onChange={value => onField(value, "last_ad")}
                    type={SCALAR_TYPES.EXTERNAL}
                /> {/* */}
                <FieldInput
                    value={ item.video_id }
                    editable
                    title={ __( "Video" ) }
                    width={ "100%" }
                    onChange={ value => onField(value, "video_id") }
                    type={ SCALAR_TYPES.YOUTUBE_ID }
                />
                <FieldInput
                    value={item.vk_url}
                    editable
                    title={__("VK group")}
                    onChange={value => onField(value, "vk_url")}
                    type={SCALAR_TYPES.STRING}
                />
                <FieldInput
                    value={item.is_closed}
                    editable
                    title={__("Is closed")}
                    onChange={value => onField(value, "is_closed")}
                    type={SCALAR_TYPES.BOOLEAN}
                />

            </div>
        </ConfirmButton>
        :
        <></> 
}
export default TrackEditor