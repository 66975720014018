import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { VerticalType } from "@/libs/scalars/interface/IScalarForm"
import { AppToaster, Loading } from "@/libs/useful"
import { __ } from "@/libs/utilities"
import actions from "@/modules/pe-basic-module/data/actions"
import { GET_FEED_ACTION, UPDATE_SINGLE_TYPE } from "@/modules/pe-basic-module/data/actionTypes" 
import { IProject } from "@/modules/pe-fest-module/data/interfaces"
import parseProjectExcel from "@/modules/pe-fest-module/data/utils/parseProjectExcel"
import { useEffect, useState } from "react"
import { useParams } from "react-router"

/* Protopia Ecosystem component */
export interface IFromExcelMasterProps {
   setProjects: (projects: IProject[]) => void
}
const FromExcelMaster = (props: IFromExcelMasterProps): JSX.Element => {
    const params = useParams()
    const land_id = params.landId
    const [excel, setExcel] = useState<any>() 
    
    const [isLoad, setIsLoad] = useState<boolean>(false) 
    useEffect(() => {
        actions(GET_FEED_ACTION, {data_type: "PEFestProject", land_id, count: 20000})
            .then( () => {
                actions(GET_FEED_ACTION, {data_type: "User", land_id, count: 20000})
                    .then( () => {
                        setIsLoad(true) 
                    })  
            })
    }, [])
    if(!isLoad) return <Loading />
    const onLoadExcel = ( value: any, name: string, theFile: any ) => { 
        if('application/vnd.ms-excel' !== theFile.type) {
            AppToaster.show({
                message: __("Upload a file in an acceptable format")
            })
            return
        }
        setExcel( value ) 
        const data: any = parseProjectExcel(value, true)  
        if(!!data.length) { 
            props.setProjects( data )
        }
    }
    return <div className='pe-fromexcelmaster-container'>
        <FieldInput
            editable 
            value={excel}
            onChange={ onLoadExcel }
            type={SCALAR_TYPES.UPLOAD}
            title={__("Upload Excel file")}
            vertical={VerticalType.VERTICAL}
            width={"100%"}
            height={200}
            placeholder={__("Upload Excel file")}
            limitSize={ 10000 }
            notClear
            accept=".xls"
            reader={"readAsBinaryString"}
        />
    </div>
}
export default FromExcelMaster