import { ID, WINDOW_WIDTH } from '@/libs/interfaces/layouts'
import { LayoutIcon } from '@/libs/useful'
import { __, compareArrays } from '@/libs/utilities'
import { useDataStore } from '@/modules/pe-basic-module/data/store'
import { isRole } from '@/settings/zustand/user'
import { Button, ButtonGroup, Collapse, Popover, Position } from '@blueprintjs/core'
import React, { useEffect, useRef, useState } from 'react'
import { template } from 'src/libs/layouts'
import { SET_FILTERS_ACTIONS } from '../data/actionTypes'
import { syncAction } from '../data/actions'
import { DESTRICT_FILTER_ICON_URL, IDestrict, IFestFilters, IFestival, IPEFestSchool } from '../data/interfaces'
import { IFestDataStor, IFestStore, useFestDataStore, useFestStore } from '../data/store'
import { isMyDestrict } from '../data/utils/user'
import useClickOutside from './hooks/useClickOutside'
import useProjectListRoute from './hooks/useProjectListRoute'
import useScreenSize from '@/modules/pe-basic-module/hooks/useScreenSize'

 

const DestrictFilterMenu = () : JSX.Element => { 
    return <DestrictFilters />   
}

export const DestrictFilters = () : JSX.Element => { 
    const {width} = useScreenSize()
    const isScreen = width > WINDOW_WIDTH.TABLET
    const destrict : IDestrict[] = useDataStore((state:any) => state.PEFestDestrict )  || []
    const schools : IPEFestSchool[] = useDataStore((state:any) => state.PEFestSchool )  || []
    const filters: IFestFilters = useFestStore((state: IFestStore) => state.filters )
    const festival : IFestival = useFestDataStore((state: IFestDataStor) => state.festival) 

    const setCurrentTitle = () => {  
        switch(filters.tracks[0]) {
            case -1:       
                setTitle( "all Destricts" )
                break
            case "mine": 
                setTitle( "Only mine IDestricts" )
                break
            default: 
                const curTrack = destrict.filter( track => track.id === filters.destrict[0] )[0]
                setTitle( curTrack ? curTrack.title : "all Destricts")
        }
    }
    const [title, setTitle] = useState( __( "all Destricts" ) )
    const [isOpen, setOpen] = useState(false)
    const [isCollapse, setCollapse] = useState(false)
    
    const myRef: React.MutableRefObject<null> = useRef( null )
    const isRoute: boolean = useProjectListRoute() && !!destrict.length && !!festival.isDistricts
    
    const onOpen = (isOpen:boolean) =>
    {
        setOpen(isOpen)
    }
    let timeOut: any = -1    
    useClickOutside( myRef, onOpen, timeOut)

    useEffect( () => {
        setCurrentTitle()
    }, [ filters ] )

    
     
    const handleLoginOpen = () =>
    {
        clearTimeout( timeOut )
        onOpen(true)
    }
    const selectTrack = (id: ID) =>
    {   
        const selectTracks =  id === -1
            ?
            [-1]
            :
            id === "mine" ? [ "mine" ] : [ id ] 
            const selDestrict = selectTracks[0] === "mine"
                ?
                destrict.filter( ( _track: IDestrict ) => {
                    return isMyDestrict( _track )  //|| isOwnerTrack( _track ) 
                })
                .map(project => project.id)
                :
                selectTracks
            const selSchools = [
                "-1", 
                ...schools
                    .filter(sc => {
                        // console.log( 
                        //     sc.title, !!sc.destrict
                        //         .map(sd =>  sd.id )
                        //         .filter( sd_id => selDestrict.includes( sd_id ) ).length 
                        // )
                        return !!sc.destrict
                                .map(s => s.id.toString())
                                .filter( sid => selDestrict.includes( sid ) ).length 
                    })
                    .map(sc => sc.id.toString())
            ]
            // console.log(selSchools)
        syncAction(
            SET_FILTERS_ACTIONS, 
            { 
                filters:{ 
                    destrict: selectTracks, 
                    schools: selSchools
                } 
            } 
        )
        setCurrentTitle()
        clearTimeout( timeOut )
        onOpen(false)
    } 
    const onMouseLeave = () =>
    {
        clearTimeout( timeOut )
        timeOut = setTimeout(() =>
        {
            onOpen(false)
        }, 1000)
    }
    return <>    
        <div 
            className={`indicator dble lrg classic p-0 m-0 overflow-hidden ${ isRoute ? "" : " closed"}`}             
            onMouseLeave={onMouseLeave}
            ref={ myRef }
        >
            <div className={ `header-menu-element ${ template().header_menu } main-menu-filter-icon ` }  onClick={handleLoginOpen}
                title={ __( title ) }
            >
                <LayoutIcon
                    src={ DESTRICT_FILTER_ICON_URL }
                    className="header-menu-icon"
                    
                />
                <span className='header-menu-label px-2 ' title={ __( title ) }>
                    { __( title ) }
                </span>
            </div> 
            <Popover
                className={`indicator-popover ${ isScreen ? "position-relative": "position-absolute top right-center-important" }`}
                modifiers={{
                    arrow: { enabled: false },
                    flip: { enabled: true },
                    preventOverflow: { enabled: false },
                }}
                popoverClassName="p-0" 
                position={isScreen ? Position.BOTTOM_RIGHT : Position.TOP }
                isOpen={isOpen}
                content={(
                    <div className='overflow-y-auto' style={{ maxHeight: `calc(100vh - ${ isScreen ? 150 : 200 }px)`}}>
                        <ButtonGroup 
                            vertical 
                            fill 
                            minimal 
                            onMouseEnter={() =>  clearTimeout( timeOut ) }
                            style={{ minWidth: 200 }}
                        >
                            <Button 
                                className={`p-3 btn-stretch text-force-contrast ${ compareArrays( [ -1 ], filters.destrict ) ? " bg-danger  text-light " : "" }`} 
                                onClick={() => selectTrack( -1 ) }
                            >
                                {__("all Destricts")}
                            </Button>
                            {
                                isRole([ "Tutor", "Project_author", "School_guardian" ]) 
                                    ?   
                                    <Button 
                                        className={`p-3 text-force-contrast btn-stretch ${ compareArrays( [ "mine"], filters.destrict ) ? " bg-danger  text-light " : "" }`}  
                                        onClick={() => selectTrack( "mine" ) }
                                    >
                                        {__("Only mine Destricts")}
                                    </Button> 
                                    :
                                    null
                            }
                            {
                                Array.isArray( destrict )
                                    ?
                                    <>
                                        <Button 
                                            icon="chevron-down" 
                                            minimal
                                            small
                                            className={isCollapse ? "hidden" : " p-3 "} 
                                            onClick={( event: React.MouseEvent<HTMLElement, MouseEvent> ) => {
                                                setCollapse(true)
                                                onOpen(true)
                                                event.preventDefault()
                                                event.stopPropagation()
                                            }}
                                        />
                                        <Collapse isOpen={isCollapse} >
                                            <div className="d-flex flex-column">
                                            {
                                                destrict.map((track:IDestrict) =>
                                                    {
                                                        return <Button 
                                                            className={ `p-0 btn-stretch text-force-contrast ${ filters.destrict.includes( track.id ) ? " bg-danger text-light " : "" }` } 
                                                            key={track.id} 
                                                            onClick={() => selectTrack(track.id) }
                                                        >
                                                            <div className='d-flex w-100'>
                                                                <span className='flex-grow-100 p-3 small '>
                                                                    { track.title }
                                                                </span>
                                                                <span className ='p-3 bg-secondary-super-light small '>
                                                                    { track.count || 0 }
                                                                </span>
                                                            </div>
                                                        </Button>
                                                        
                                                    })
                                            }                                    
                                            </div>
                                        </Collapse>
                                    </>
                                    :                        
                                    <div className='p-3' >
                                        {__("no Destricts")}
                                    </div>   
                            }
                        </ButtonGroup>
                    </div>
                )}
            >
                <div style={{ marginTop: 0 }} />
            </Popover> 
        </div> 
    </> 
} 

export default DestrictFilterMenu



 