import { vk_api_key } from "@/settings/config";
import { useEffect, useState } from "react"
import { AppToaster } from "../../../libs/useful";
import { Intent } from "@blueprintjs/core";

export default function useVKApi( ) : boolean {
    const [isSdkLoaded, setIsSdkLoaded] = useState<boolean>(false)
    const [isDisabled, setIsDisabled]   = useState<boolean>(true)
    const addVK = () => {
        if (document.getElementById('vk-jssdk')) {
            setIsSdkLoaded(true)
            setIsDisabled(false)
            return
        }
        setFbAsyncInit()
        loadSdkAsynchronously()
    }
    const setFbAsyncInit = () => { 
        window.vkAsyncInit = () => 
            {
            try{
                if( vk_api_key() === "NONE") {
                throw new Error('Vk API not connected by owner. Contact administrator');
                }
                window.VK.init({ apiId: vk_api_key() })
                setIsSdkLoaded(true)
                setIsDisabled(false) 
            }
            catch(e:any) {
                console.error(e.message)
                AppToaster.show({
                intent: Intent.DANGER,
                message: "VK API not loaded: " + e.message
                })
            }
        };
    }
    const loadSdkAsynchronously = () => {
      const el = document.createElement('script');
      el.type = 'text/javascript';
      el.src = 'https://vk.com/js/api/openapi.js?139';
      el.async = true;
      el.id = 'vk-jssdk';
      document.getElementsByTagName('head')[0].appendChild(el);
    }
    useEffect(() => {
        addVK()
    }, [])
    return isDisabled
}