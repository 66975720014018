import { __ } from "@/libs/utilities"
import { IRating } from "@/modules/pe-fest-module/data/interfaces"

/* Protopia Ecosystem component */
export interface ICriteryDescriptionsProps {
    ratings: IRating[]
}
const CriteryDescriptions = ({ratings}: ICriteryDescriptionsProps): JSX.Element => {
    return <div className='pe-critery-descriptions-container'>
        {
            !!ratings.filter(r => r.description).length 
                && 
                <div className="my-2 lead">{__("Experts's descriptions")}:</div>
        }
        {
            ratings.map(r => <CriterySingleRating rating={r} key={r.id} />)
        }
    </div>
}
export default CriteryDescriptions


/* Protopia Ecosystem component */
interface ICriterySingleDescriptionsProps {
   rating: IRating
}
const CriterySingleRating = (props: ICriterySingleDescriptionsProps): JSX.Element => {
    return props.rating?.description 
        ?
        <div className='pe-critery-single-descriptions-container small'>
            <div className="title mt-1" >
                    {props.rating?.display_name + ` | ${props.rating?.rating}`}
            </div>
            <div dangerouslySetInnerHTML={{ __html: props.rating?.description }} />
        </div>
        : 
        <></>
} 