import { LoaderBallsLine } from '@/libs/useful'
import { __ } from '@/libs/utilities'
import { useAppoloStore } from '@/settings/zustand'
import { Icon } from '@blueprintjs/core'
import gql from 'graphql-tag'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { GET_FESTIVAL_SETTINGS_ACTION } from '../../data/actionTypes'
import { actions } from '../../data/actions'
import { IFestival, ISettingsPageFormProps } from '../../data/interfaces'
import { useFestDataStore } from '../../data/store'
import FestSettingsForm from './FestSettingsForm'

const SettingsForm = (props: ISettingsPageFormProps ) : JSX.Element  => {
    const params = useParams()
    const [isLoading, setIsLoading] = useState<boolean>(true) 
    const [festival, setFestival] = useState<IFestival>({} as IFestival) 
    const [defaultFest, setDefaultFest] = useState<string>("") 
    useEffect(() => {
        console.log( params.landId )
        actions(GET_FESTIVAL_SETTINGS_ACTION, {id: params.landId})
            .then(
                response => {
                    setFestival(response)
                    setIsLoading(false)
                    setDefaultFest(JSON.stringify( response ))
                }
            ) 
    },[]) 

    const updateFestival = () => {
        // console.log( festival )
        const land_id = params.landId 
            ?
            ` land_id:"${params.landId}" `
            :
            ``
        const mutation: string =  `
            mutation changeFestival {
                changePEFestival (
                    input: { 
                        title: "${ festival.title }"
                        description: "${ festival.description }" 
                        clientUrl: "${ festival.clientUrl }"
                        startDate: "${ festival.startDate  }"
                        finishDate: "${ festival.finishDate }" 
                        defaultProjectThumbnail: "${ festival.defaultProjectThumbnail }"
                        ${ festival.defaultProjectThumbnailName ? 'defaultProjectThumbnailName:"' + festival.defaultProjectThumbnailName + '"' : "" }
                        memberCardType: "${ festival.memberCardType }"
                        isChangeCardType: ${ festival.isChangeCardType }
                        memberCardHeight: ${ festival.memberCardHeight } 
                        ratingType: "${ festival.ratingType }"
                        isProjectsVerify: ${ festival.isProjectsVerify }
                        displayProjectResult: "${ festival.displayProjectResult }"
                        maxRating: ${ festival.maxRating }
                        isIndependentGradeCritery: ${ festival.isIndependentGradeCritery }
                        isOwnerRate: ${ festival.isOwnerRate }
                        isExpertsCriteries: ${ festival.isExpertsCriteries }
                        isHideNotVerifiedProjects: ${ festival.isHideNotVerifiedProjects }
                        isGanres: ${ festival.isGanres }
                        isDistricts: ${ festival.isDistricts }
                        maxGanres: ${ festival.maxGanres }
                        isRegisterUser: ${ festival.isRegisterUser }
                        isRegisterProject: ${ festival.isRegisterProject }
                        isCreateAuthorWithProject: ${ festival.isCreateAuthorWithProject }
                        isDiary: ${ festival.isDiary }   
                        availableRoles: [ "${ (festival.availableRoles || [] ).join('", "') }" ]
                        projectMembersCount: ${
                            typeof festival.projectMembersCount === "undefined"
                            ?
                            10
                            :
                            festival.projectMembersCount
                        }
                    }
                    ${land_id}
                ) {
                    title
                    description 
                    clientUrl
                    startDate
                    finishDate
                    isProjectsVerify  
                    defaultProjectThumbnail 
                    projectMembersCount
                    maxGanres
                    isDistricts
                }
            }` 
        setIsLoading(true)
        // console.log( mutation);
        const apolloClient: any = useAppoloStore.getState().client 
        apolloClient.mutate({
            mutation : gql`${mutation}`
        })
            .then( ( response: any ) => {
                // console.log( response.data.changePEFestival ) 
                actions(GET_FESTIVAL_SETTINGS_ACTION, {id: params.landId})
                .then(
                    response => {
                        setFestival(response)
                        setIsLoading(false)
                        setDefaultFest(JSON.stringify( response ))
                        useFestDataStore.getState().setFestival( {...useFestDataStore.getState().festival, ...response} )
                    }
                )
            })
    }  
 
    return (
        <div className="position-relative mb-5"> 
            <div 
                className={ ` position-sticky mr-2 mt-2 top` }
                style={{ zIndex:100 }}
            >
                <div 
                    className={` mt-2 position-absolute right btn btn-sm btn-${defaultFest !== JSON.stringify(festival) ? 'danger' : 'secondary opacity_5 untouchble'}`}
                    onClick={updateFestival} 
                >
                    <Icon 
                        icon={ 
                            defaultFest === JSON.stringify(festival) //
                                ?  
                                "tick" 
                                :
                                isLoading ? "repeat" : "blank"
                        } 
                        className={ isLoading ? "mr-2 fa fa-spin" : "mr-2" } 
                    /> 
                    { __( "Update Festival data" ) }
                </div>
            </div>
            <div className='d-flex align-items-center '>
                <div className='display-5 mb-2 mr-3'>{ __(props.title || "") }</div>
                { isLoading && <LoaderBallsLine /> } 
            </div>
            
            <FestSettingsForm festival={festival} setFestival={setFestival} />
        </div>
    )
} 

export default SettingsForm

