import FieldInput from '@/libs/scalars'
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { SCALAR_TYPES, VerticalType } from '@/libs/scalars/interface/IScalarForm'
import { __ } from '@/libs/utilities'
import { Button, Collapse, Icon, Intent, Tag } from '@blueprintjs/core' 
import { Fragment, useRef, useState } from 'react'
import RussionRegionList from '../../data/RussianRegionList'
import { IFestDataStor, useFestDataStore } from '../../data/store'
import { ICritery, IFestival, IPEFestSchool, IProject, IRating, ITrack } from '../../data/interfaces'
import MemberRatingByCriteries from '../../data/utils/MemberRatingByCriteries'
import TrackLabel from '../projectList/TrackLabel'
import { IUser } from '@/settings/zustand'
import ProjectCriteryRow from './ProjectCriteryRow'
import TagFilters from './TagFilters'
import FormFieldMonitor from './FormFieldMonitor';
import { ID } from '@/libs/interfaces/layouts';
import { useDataStore } from '@/modules/pe-basic-module/data/store';

interface IProps {
    members: IProject[]
    users: IUser[]
    ratings: IRating[] 
    goto: (url:string) => void
}
const ORDER_SORT = "order"
const ALPHABET_SORT = "alphabet"
const RATING_SORT = "rating"
const NOMINATION_SORT = "nomination"
const SORT_ORDER_ASC = "asc"
const SORT_ORDER_DESC = "desc"


const FullMemberStatistics = (props: IProps): JSX.Element => {
    const festival:IFestival = useFestDataStore((state:IFestDataStor) => state.festival )
    const criteries: ICritery[] = useDataStore((state:any) => state.PEFestCritery || [])
    const tracks: ITrack[] = useDataStore((state:any) => state.PEFestTrack || [])
    const schools: IPEFestSchool[] = useDataStore((state:any) => state.PEFestSchool || [])
    const [ regionId, ] = useState<number>( () => {
        const rsID = ( festival.extendedFields || [] ).findIndex((ff: any) => ff.type === "RussianRegions") 
        return typeof rsID === "number" ? rsID : -1
    } )
    const [ isTrackOpen,  setTrackOpen  ]           = useState<boolean>( false ) 
    const [ isSchoolOpen,  setSchoolOpen  ]           = useState<boolean>( false ) 
    const [ isRegionOpen, setRegionOpen ]           = useState<boolean>( false )
    const [ isFieldOpen,  setFieldsOpen ]           = useState<boolean>( false ) 
    const [ isRatingMaxOpen,  setRatingMaxOpen ]    = useState<boolean>( false ) 
    const [ trackFilter,   setTrackFilter ]         = useState<ID[]>( tracks.map(( track: ITrack) => track.id) || [] )
    const [ schoolFilter,   setSchoolFilter ]       = useState<ID[]>( schools.map(( school ) => school.id) || [] )
    const [ regionFilter, setRegionFilter ]         = useState<string[]>( RussionRegionList() )
    const [ ratingMaxFilter, setRatingMaxFilter ]   = useState<number>( 0 )
    const [ isFields, onIsFields]                    = useState<boolean>( true )
    const [ isCriteries, onIsCriteries]              = useState( false )
    const [ sortField, setSortField]                 = useState<string>( ALPHABET_SORT )
    const [ sortOrder, setSortOrder]                 = useState<string>( SORT_ORDER_ASC )
    const [ sortNomination, setSortNomination]       = useState<string>( NOMINATION_SORT )
    
    const tableRef = useRef(null);
    const onTrackFilter = (value: []) =>
    { 
        setTrackFilter( value || [] )
    }
    const onSchoolFilter = (value: []) =>
    { 
        setSchoolFilter( value || [] )
    }
    const onRegionFilter = (value: []) =>
    { 
        //console.log( value )
        setRegionFilter( value || [] )
    }
    const onRatingMaxFilter = (value: []) =>
    { 
        //console.log( value )
        setRatingMaxFilter( Number(value) )
    }
    const printIt = () =>
    {
        const restorePage = $('body').html(); 
        $( "fest-header" ).hide()
        $( "fest-phase-labels" ).hide()
        $( "fest-head-switcher" ).hide()
        const printPage = $('body').html()
        $('body').empty().html( printPage )
        window.print();
        $('body').html(restorePage); 
    }
    const onChangeField = (field: "onIsFields" | "onIsCriteries", bool: boolean ) =>
    {
        switch(field)
        {
            case "onIsFields":
                onIsFields(bool) 
                break
            case "onIsCriteries":
                onIsCriteries(bool) 
                break
        }
    }
    const onSortOrder = () =>
    {
        setSortOrder( () => sortOrder === SORT_ORDER_ASC ? SORT_ORDER_DESC : SORT_ORDER_ASC )

    }
    const sortMembers = (a: IProject, b: IProject ) =>
    {
        switch(sortField)
        {
            case ALPHABET_SORT: 
                const nameA = a.title.replace(/\«|\"|\.|\\/gi, "").toUpperCase() 
                const nameB = b.title.replace(/\«|\"|\.|\\/gi, "").toUpperCase() 
                if (nameA < nameB) {
                    return sortOrder === SORT_ORDER_ASC ? -1 : 1
                }
                if (nameA > nameB) {
                    return sortOrder === SORT_ORDER_ASC ? 1 : -1
                }
                return 0
            case NOMINATION_SORT: 
                const nomA = a.nomination.replace(/\«|\"|\.|\\/gi, "").toUpperCase() 
                const nomB = b.nomination.replace(/\«|\"|\.|\\/gi, "").toUpperCase() 
                if (nomA > nomB) 
                {
                    return sortOrder === SORT_ORDER_ASC ? -1 : 1
                }
                if (nomA < nomB) 
                {
                    return sortOrder === SORT_ORDER_ASC ? 1 : -1 
                }
                return 0
            case ORDER_SORT:
                return sortOrder === SORT_ORDER_ASC
                    ?
                    Number( a.order ) - Number( b.order )
                    :
                    Number( b.order ) - Number( a.order )
            case RATING_SORT:
                return sortOrder === SORT_ORDER_ASC
                    ?
                    MemberRatingByCriteries( b ) - MemberRatingByCriteries( a )
                    :
                    MemberRatingByCriteries( a ) - MemberRatingByCriteries( b )
        }
        return 1
    } 
    let memberData = props.members 
        ?    
        props.members
            // фильруем по трекам
            .filter( 
                (member: IProject) => {
                    if( useFestDataStore.getState().tracks.length === 0 ) return true
                    if(!member.track || !member.track.id) return true
                    return trackFilter.filter( 
                        (filter : any) => {                            
                            return filter === member.track?.id
                        }
                ).length > 0
            })
            // фильруем по schools
            .filter( 
                (member: IProject) => {
                    if( schools.length === 0 ) return true
                    if(!member.fmru_school.length) return true
                    return schoolFilter.filter( 
                        (filter : any) => {                            
                            return filter === member.fmru_school[0]?.id
                        }
                ).length > 0
            })
            // фильтруем по списку регионов России. 
            .filter(
                (member: IProject) => regionFilter.filter(
                    filter => {
                        if( regionId < 0 ) return true
                        if( !Array.isArray( member.form_fields  ) || !member.form_fields[ regionId ] )
                        {
                            return regionFilter.length === RussionRegionList().length
                        }
                        if( typeof member.form_fields[ regionId ][ 0 ] === "undefined" )
                        {
                            return true
                        }
                        if(!member.form_fields[ regionId ][ 0 ]) return true
                        return filter === member.form_fields[ regionId ][ 0 ]
                    }
                ).length > 0
            )
            // фильтруем по рейтингу
            .filter( (member: IProject) => MemberRatingByCriteries( member ) >= ratingMaxFilter )
        :
        []
    memberData.sort( sortMembers )
    const memberTRs: JSX.Element[] | JSX.Element = memberData.length > 0 
        ?
        memberData.map((member, i) => 
        { 
            const rating = member.actualRating
            const crs: JSX.Element[] | false = criteries.map(( critery:ICritery, index: number ) => {
                return <ProjectCriteryRow
                    project={ member }
                    critery={ critery }
                    ratings={ props.ratings }
                    users={ props.users }
                    index={ index }
                    key={ critery.id }
                />
            })
            return <Fragment key={member.id}>                
                <tr className='bg-light-strobe  border-top border-secondary border-2'>
                    <td>
                        <Tag minimal>{member.order}</Tag>
                    </td>
                    <td colSpan={2} >
                        <div className='lead title py-2 text-md-left text-center max-width-100'>{member.title}</div> 
                        
                        <div className='py-2 d-flex align-items-center flex-column flex-md-row'>
                            <span className='mr-2'>
                                {__("Tutor")}:
                            </span> 
                            <span className='title text-md-left text-center'>
                                { member.tutor?.display_name } 
                            </span>
                            <span className='mx-1'>|</span> 
                            { member.tutor?.user_email }
                        </div> 
                        
                        <div className='py-2 d-flex align-items-center flex-column flex-md-row'>
                            <span className='mr-2'>
                                {__("Project author")}:
                            </span> 
                            <span className='title text-md-left text-center'>
                                { member.leader?.display_name } 
                            </span>
                            <span className='mx-1'>|</span> 
                            { member.leader?.user_email }
                        </div> 
                        { 
                            member.track && <div className='py-2 d-flex align-items-center flex-column flex-md-row'>
                                <span>{__("Track")}:</span>                                
                                <TrackLabel track={member.track} className="title d-flex align-items-center text-nowrap my-2 ml-2"/> 
                            </div> 
                        }                        
                        {
                            !!member.fmru_school.length && <div className='py-2 d-flex align-items-center flex-column flex-md-row'>
                                <span className='mr-2'>
                                    {__("Education organizations")}:
                                </span> 
                                <span className='title text-md-left text-center'>
                                    { member.fmru_school.map(s => s.title).join(", ") } 
                                </span> 
                            </div>  
                        } 

                        <div className='py-2'>
                            <FormFieldMonitor 
                                className={""}  
                                isFields={isFields!} 
                                form_fields={member.form_fields}
                            />
                        </div> 
                        {
                            member.nomination
                                &&
                                <div className='d-flex py-2'>
                                    <div className=' '>
                                        {__("Nomination:")}
                                    </div>
                                    <div className='ml-3 title text-danger'>
                                        { member.nomination }
                                    </div>
                                </div>
                        }
                    </td>
                    <td > 
                        <div className='py-2 display-6 title  text-md-left text-center'>
                            { Number(rating).toFixed( 2 ) }
                        </div> 
                    </td>
                </tr>
                {crs}
            </Fragment>
        })
        :
        <div > </div>                      
    return <>
        <div className='w-100'>
            <div className='mt-1 mb-3 w-100'>
                <div className='align-items-center w-100 d-flex justify-content-md-between justify-content-center flex-md-row flex-column'>
                    <div className='flex-centered'>
                        <div className='flex-centered descr text-uppercase text-secondary mr-2'>
                            {__("Filters")}:
                        </div>
                        <Button intent={Intent.SUCCESS} minimal={ !isTrackOpen } onClick={() => setTrackOpen( !isTrackOpen ) }>
                            {__("Tracks")} | { trackFilter.length }
                        </Button>
                        <Button intent={Intent.SUCCESS} minimal={ !isSchoolOpen } onClick={() => setSchoolOpen( !isSchoolOpen ) }>
                            {__("Schools")} | { schoolFilter.length }
                        </Button>
                        <Button intent={Intent.SUCCESS} minimal={ !isRegionOpen } onClick={() => setRegionOpen( !isRegionOpen ) }>
                            {__("Regions")} | { regionFilter.length }
                        </Button> 
                        <Button intent={Intent.SUCCESS} minimal={ !isRatingMaxOpen } onClick={() => setRatingMaxOpen( !isRatingMaxOpen ) }>
                            {__("By rating")} | { ratingMaxFilter }
                        </Button> 
                    </div>
                    <div className='d-flex flex-md-row flex-column'>
                        <div className=' flex-centered ml-4 mr-2 descr text-uppercase text-secondary '>
                            {__("Do sort")}:
                        </div>
                        <Button 
                            intent={Intent.SUCCESS} 
                            minimal={sortField !== ORDER_SORT}
                            onClick={() => setSortField(ORDER_SORT)}
                        >
                            {__("By Member's order")}
                        </Button>
                        <Button 
                            intent={Intent.SUCCESS} 
                            minimal={sortField !== ALPHABET_SORT}
                            onClick={() => setSortField(ALPHABET_SORT)}
                        >
                            {__("By alphabet")}
                        </Button>
                        <Button 
                            intent={Intent.SUCCESS} 
                            minimal={sortField !== RATING_SORT}  
                            onClick={() => setSortField(RATING_SORT)}
                        >
                            {__("By rating")}
                        </Button>
                        <Button 
                            intent={Intent.SUCCESS} 
                            minimal={sortField !== NOMINATION_SORT}  
                            onClick={() => setSortField( NOMINATION_SORT )}
                        >
                            {__("By nomination")}
                        </Button>
                        <Button 
                            intent={Intent.SUCCESS} 
                            minimal  
                            onClick={ onSortOrder }
                        >
                            <Icon 
                                icon={ sortOrder === SORT_ORDER_ASC 
                                    ?  
                                    sortField === RATING_SORT
                                        ?
                                        "sort-numerical-desc"
                                        :
                                        "sort-asc" 
                                    : 
                                    sortField === RATING_SORT
                                        ?
                                        "sort-numerical"
                                        :
                                        "sort-desc"
                                } 
                                size={20} 
                            />
                        </Button>
                    </div>
                    
                    <div className='flex-centered'>
                        <Button intent={Intent.SUCCESS} minimal={!isFieldOpen} className=' px-3 py-2 ml-3' onClick={() => setFieldsOpen(!isFieldOpen)}>
                            {__("Fields")}
                        </Button>
                    </div> 

                    <div className='flex-centered'>
                        <div className=' display-6 '>
                            { memberData.length }
                        </div>
                        <Button minimal className='px-3 py-2 ml-3' onClick={printIt}>
                            <Icon icon="print" size={21} className="hint hint--top" data-hint={__("Print")} />
                        </Button> 
                        <DownloadTableExcel
                            filename="projects"
                            sheet="projects"
                            currentTableRef={ tableRef.current }
                        >
                            <Button minimal className='px-3 py-2 '>
                                <Icon 
                                    icon="th" 
                                    size={21} 
                                    className="hint hint--top pointer" 
                                    data-hint={__("Download XLS")}
                                />
                            </Button>                            
                        </DownloadTableExcel> 
                    </div>
                     
                </div>
            </div>
            <Collapse isOpen={ isTrackOpen} className=" light-colored ">
                <FieldInput
                    type="tags" 
                    multiple
                    vertical={VerticalType.VERTICAL}
                    editable
                    values={ useFestDataStore.getState().tracks.map((track:ITrack) => ({ _id:track.id, title: track.title })) }
                    value={trackFilter}
                    onChange={onTrackFilter}
                    on={onTrackFilter}
                />
            </Collapse>
            <Collapse isOpen={ isSchoolOpen} className=" light-colored ">
                <FieldInput
                    type="tags" 
                    multiple
                    vertical={VerticalType.VERTICAL}
                    editable
                    values={ schools.map((track) => ({ _id:track.id, title: track.title })) }
                    value={trackFilter}
                    onChange={onSchoolFilter}
                    on={onSchoolFilter}
                />
            </Collapse>
            <Collapse isOpen={ isRegionOpen} className=" light-colored ">
                <FieldInput
                    type="tags" 
                    className={` w-100 input dark form-control `}
                    multiple
                    vertical={VerticalType.VERTICAL}
                    editable 
                    values={ RussionRegionList() }
                    value={regionFilter}
                    onChange={onRegionFilter}
                    on={onRegionFilter}
                />
            </Collapse>
            <Collapse isOpen={ isRatingMaxOpen } className=" light-colored ">
                <FieldInput 
                    type={ SCALAR_TYPES.SLIDER } 
                    title={ __( "Only more than" ) }
                    commentary={ __( "Filter all member that raating low this parameter" ) }
                    className="max-width-100 ml-5"
                    min={ 0 }
                    max={ festival.maxRating || 10 }
                    stepSize={ 1 }
                    labelStepSize={ 1 } 
                    editable 
                    value={ ratingMaxFilter }
                    onChange={ onRatingMaxFilter }
                    on={ onRatingMaxFilter }
                />
            </Collapse>
            <Collapse isOpen={ isFieldOpen } className=" light-colored ">
                <div className='p-3'>
                    <TagFilters 
                        isFields={isFields}
                        isCriteries={isCriteries}
                        onChangeField={onChangeField}
                    />
                </div>
            </Collapse>
        </div>
        {/* <div className='my-3 w-100'>
            {  regionFilter.join(", ") }
        </div> */}
        <table 
            className='table table-striped table-bordered2 table-hover1 pe-fest-table-statistics w-100' 
            id="members-to-xls"
            ref={ tableRef }
        > 
            {
                isCriteries || true
                    ?
                    <thead className="thead-dark-33 bg-secondary text-white">
                        <tr>
                            <th scope="col" style={{ width: 55 }} >
                                {__("#")}
                            </th>
                            <th scope="col" >
                                {__("Critery")}
                            </th>
                            <th scope="expert">
                                {__("Expert")}
                            </th>
                            <th scope="col"  style={{ width: 10 }}  >
                                {__("Rate")}
                            </th> 
                        </tr>                
                    </thead >
                    :
                    null
            }
            <tbody>
               {memberTRs}
            </tbody> 
        </table>
    </>
}
 

export default FullMemberStatistics