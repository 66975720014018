import { __ } from '@/libs/utilities'
import { Outlet, useNavigate } from 'react-router'
import { NavLink } from 'react-router-dom'
import { IFestival, IProject } from '../../data/interfaces'
import { IFestDataStor, IFestProjectsProps, useFestDataStore, useFestProjects } from '../../data/store'
import { useUserOwner } from '../../hooks/project'
import PhaseLabel from '../PhaseLabel' 
import ProjectData from './ProjectData' 
import { Button, Popover } from '@blueprintjs/core'

const ProjectContainer = (props:any):JSX.Element => {
    const item: IProject = useFestProjects((state: IFestProjectsProps) => state.currentProject)
    const nav = useNavigate()
    const onReport = () => {
        nav("ratings-report")
    }
    return <div>
        <ProjectData {...props} item={item} />
        <div className="d-flex w-100 justify-content-center">
            <div className="mx-2"> 
                <PhaseLabel />
            </div>     
            <Links /> 
            <Popover
                content={<div className='p-3'>
                    {__("Ratings report")}
                </div>}
                interactionKind='hover'
            >
                <Button minimal icon="archive" onClick={onReport} />
            </Popover>                  
        </div>
        <Outlet/>
    </div>
}
 

export default ProjectContainer

const Links = () : JSX.Element | null => {    
    const festival:IFestival = useFestDataStore( ( state:IFestDataStor )  => state.festival )
    const item: IProject = useFestProjects( ( state: IFestProjectsProps ) => state.currentProject )  
    const isOwner: boolean = useUserOwner( item ) 
    if( !festival.isDiary  && !isOwner ) return null

    let links: any[] = []
    if(isOwner) {
        links = links.concat(
            links, 
            [
                {
                    title: "Project team",
                    route: "project-team"
                },
                {
                    title: "Project management",
                    route: "management"
                },
            ]
        )
    }
    if(festival.isDiary){
        links.splice(0, 0, {title: "Diary", route: "diary"})
    }
    if( !!links.length ) links.unshift({title: "Ratings", route: "ratings"})
    
    return <>
        {
            links.map((l:any) => <NavLink
                key={l.route}
                to={l.route}
                className={ setActive }
            >
                {__(l.title)}
            </NavLink>  )
        } 
    </>
}
interface IActiveProps {
    isActive: boolean
}
const setActive = ({ isActive } : IActiveProps) => isActive ? "fest-project-route-btn text-force-contrast active-link" : " fest-project-route-btn text-force-contrast "