import IScalarForm from '../interface/IScalarForm';
import { __, sprintf } from '@/libs/utilities';
import Scalar from './Scalar';
import { useEffect, useState } from 'react';
import DownloadBtn from '@/libs/useful/DownloadBtn';

const Upload = (props: IScalarForm) : JSX.Element => { 
    return <Scalar 
        { ...props } 
        enabledForm={<UploadEnabled {...props} className={`h-100 form-control input dark`}/>}
        desabledForm={<UploadDesabled {...props} />}
    />
}
export const UploadEnabled = (props: IScalarForm) : JSX.Element =>  {
    const BYTES: number     = 1048.575
    const maxKb: number     = props.limitSize || 1500
    const maxSize: number   = maxKb * BYTES

    const [fileName, setFileName] = useState<string>("")
    const [fileSize, setFileSize] = useState<number>(0)
    useEffect(() => {
        setFileName( "" )
        setFileSize(0)
    }, [props.onClear] )
    const onChange = (evt: any): void =>
    {  
        let file = evt.currentTarget.files[0]
        if(file.size > maxSize)
        {
            alert(
                sprintf(
                     __("File is too big. Maximum file size is %skB. Now: %skB."), 
                     Math.floor(maxKb).toString(),
                     Math.floor(file.size / BYTES).toString() 
                ) 
            )
            return
        }
        let reader = new FileReader() 
        switch(props.reader) {
            case "readAsText":
                reader.readAsText(file)
                break
            case "readAsBinaryString":
                reader.readAsBinaryString(file)
                break
            case "readAsDataURL":
            default:
                reader.readAsDataURL(file)
        }  
        reader.onload = function( theFile ) {
            return function (e: any) { 
                if(  props.onChange ) {
                    setFileName( theFile.name )
                    setFileSize( theFile.size ) 
                    props.onChange( e.target.result, theFile.name, theFile ) 
                }
			}
        }( file )
      
        reader.onerror = function() {
            console.log(reader.error);
        };
    }

    return <div className={`d-flex w-100 align-items-start ${props.className ? props.className : ""}`}> 
        <div 
            className={`flex-centered position-relative flex-column pe-bg  ${props.className ? props.className : ""}`} 
            style={{
                minHeight: 75,
                height: props.height || 75,
                width:  props.width  || "100%"
            }} 
            title={fileName}
        >
            <input 
                accept={ props.accept }
                type="file"
                className='opacity_0 w-100 h-100 pointer '
                placeholder={__(" ")}
                value={ "" }
                onChange={ onChange } 
            />
            <div 
                className="  overflow-hidden p-4 position-absolute top left w-100 h-100 flex-centered flex-column bg-secondary untouchble"
               
            >
                <div className={` text-light title text-center ${fileName ? "text-overflow" : ""}`}>
                    {
                        fileName
                            ?
                            fileName
                            :
                            __( props.placeholder || "Upload file from computer" )
                    }
                </div>
                <div className='text-light'>
                    { fileName }
                    <small>
                    { 
                        fileSize 
                            ? 
                            fileSize / BYTES > 1000 
                                ?
                                (Math.floor(  fileSize / BYTES)/ 1000).toString() + " Mb"
                                :
                                Math.floor(  fileSize / BYTES).toString() + " Kb" 
                            : 
                            sprintf( 
                                __( "Maximum size is %s" ), 
                                maxKb > 1000 
                                    ? 
                                    `${(maxKb/ 1000).toString()} Mb` 
                                    : 
                                    `${maxKb.toString()} Kb` 
                            ) 
                    }
                    </small>
                </div>
            </div>
            
        </div>
    </div>
}

export const UploadDesabled = (props: IScalarForm) : JSX.Element => {
    return <div className="d-flex align-items-center justify-content-center" style={{ width: props.width, height: props.height }}>
        
        <DownloadBtn
            src={ props.value }
            className=' d-flex align-items-center pe-file-input-link  justify-content-center' 
        >
            <div className='lead opacity_5'> 
                <i className="fas fa-download mr-2" />
            </div>
            { __( props.field || "download file" ) }
        </DownloadBtn>
    </div>
} 

export default Upload