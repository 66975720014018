import { ID } from '@/libs/interfaces/layouts'
import { AppToaster } from '@/libs/useful'
import { __ } from '@/libs/utilities'
import { ADD_ATTACHMENT_ACTION, DELETE_ATTACHMENT_ACTION } from '@/modules/pe-fest-module/data/actionTypes'
import { actions } from '@/modules/pe-fest-module/data/actions'
import { IAttachments, IProject, IProjectMilestone } from '@/modules/pe-fest-module/data/interfaces'
import { useUserOwner } from '@/modules/pe-fest-module/hooks/project'
import { Button, ButtonGroup, Callout, Dialog, Icon, Intent } from '@blueprintjs/core'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router' 
import AttachForm from '../attaches/AttachForm'
import AttachInput from '../attaches/AttachInput'
import { SCALAR_TYPES } from '@/libs/scalars'
import { checkURL } from '@/libs/scalars/scalars/URL'


interface IMilestoneIncludeListProps {
    memberId: ID
    member: IProject
    milestone: IProjectMilestone
    isEditForms: boolean 
    //дёргаем родителя для перезагрузки данных всех этапов
    onUpdate : () => void
}
const MilestoneIncludeList = (props: IMilestoneIncludeListProps): JSX.Element => {
    const {landId} = useParams()
    const { milestone } = props
    const [attachments, setAttachments] = useState<IAttachments[]>( Array.isArray( milestone?.attachments) ? milestone?.attachments : [] )
    const [editOpen, setEditOpen] = useState(true)
    const [trashOpen, setTrashOpen] = useState(false)
    const [trashedID, setTrashedID] = useState(-1)
    const isOwner = useUserOwner( props.member ) 
    useEffect(() => { 
        setAttachments( milestone?.attachments || [] )
    }, [milestone?.attachments])
    
    const onInsertNew = (type: any) =>
    {
        const attaches:IAttachments[] = [
            ...attachments, 
            { 
                type, 
                data: "", 
                descr: "", 
                unixtime: Date.now(), 
                milestone_id: milestone?.id,
                member_id: props.memberId
            } as IAttachments
        ] 
        setAttachments( attaches )
        setEditOpen( true )
        // props.onEditFormToggle( true )

    }
    const onChange = (
        value: any, 
        field: string, 
        i: number, 
        attType: string, 
        dd: any
    ) => {      
        console.log( value, field )  
        // console.log( "field", field )
        // console.log( "i", i )
        // console.log( "attType", attType )
        const attaches = [ ...attachments ]
        attaches[ i ].data = value   
        attaches[ i ].descr = field   
        setAttachments( attaches )
        //console.log( attaches[ i ] )
        //console.log( [...attachments] )
    }

    const onAdd = (i: number) =>
    {
        const attachment = attachments[i];
        // console.log( attachments )
        if(!attachment.data)
        {
            AppToaster.show({
                message:__("You must add attachment."),
                intent: Intent.DANGER
            })
            return
        } 
        if( attachment.type === SCALAR_TYPES.URL && !checkURL(attachment.data) ) {
            AppToaster.show({
                message: __("Error: link must be url"),
                intent: Intent.DANGER
            })
            return
        }
        console.log( attachment )
        actions( ADD_ATTACHMENT_ACTION, {...attachment, landId} )
            .then( r => {
                console.log( r ) 
                setEditOpen(false)
                props.onUpdate()
            })
    }
    const onTrash = (i: number) =>
    {     
        const attaches = [ ...attachments ]
        if(attaches[i].data)
        {
            setTrashOpen(true)
            setTrashedID( i )
        }
        else
        {
            onTrashFinally(i)
        }
       
    }
    const onTrashFinally = (i: number) =>
    {  
        const attaches = [ ...attachments ] 
        const attachment = attaches.splice(i, 1)
        //console.log( attachment[0].ID )
        setAttachments( attaches ) 
        setTrashOpen(false) 
        actions( DELETE_ATTACHMENT_ACTION, {id: attachment[0].ID, landId })
            .then(res => {
                console.log( res)
                // setEditOpen(false)
                props.onUpdate()
            })
    }
    const isNew = (i: number) =>
    {
        return !attachments[i].ID
    }
    //console.log( "attachments:", attachments )
    const _attachments = attachments.length > 0
        ?
        <div className='w-100 max-width-560 mx-auto'>
        {
            attachments.map((att: any, i: number) =>
            {
                return !!att 
                    ?
                    <span 
                        className='mx-auto'
                        style={{ maxWidth: 580, width:"100%", display:"flex" }} 
                        key={ att.ID + " __ " + i }
                    >  
                        <AttachInput 
                            {...props}
                            att={att}
                            editable={  isNew(i) && editOpen }
                            onChange={
                                isNew(i) 
                                ? 
                                ( 
                                    value: any, 
                                    name: string, 
                                    attType: string, 
                                    dd: any 
                                ) => onChange(value, name, i, attType,  dd)
                                :
                                null
                            } 
                        />                         
                        {
                            isNew(i) && editOpen
                                && 
                                <Button minimal intent={Intent.SUCCESS} onClick={() => onAdd(i)}> 
                                    {__("Add")}
                                </Button> 
                        }
                        {
                            isOwner 
                                &&
                                <Button 
                                    minimal 
                                    intent={Intent.DANGER}                            
                                    onClick={() => onTrash( i )}
                                >
                                    <Icon icon="trash" className="opacity_75"/>
                                </Button> 
                        }
                        
                    </span>
                    :
                    null
            })
        }
        {
            isOwner && <AttachForm onInsertNew={onInsertNew} /> 
        }
        </div>        
        :
        milestone?.milestone.is_attachments_required
            ?
            <Callout intent={Intent.DANGER} className="p-4 ">
                <div className='text-center text-uppercase'>
                    {__("Attachments must be registered to work on the stage")}
                </div>                
                {
                    isOwner && <AttachForm onInsertNew={onInsertNew} /> 
                }
            </Callout>
            :
            isOwner &&  <AttachForm onInsertNew={onInsertNew} />
    return (
        <div className='d-flex justify-content-center flex-column align-items-center  container'>  
            { _attachments }
            <Dialog
                isOpen={ trashOpen }
                onClose={() => setTrashOpen(false)}
                className="little flex-centered"
            >
                <div className='p-5'>
                    <div className='pb-5'>
                        {__("Delete attachment?")}
                    </div>
                    <ButtonGroup >
                        <Button fill intent={Intent.SUCCESS} onClick={() => onTrashFinally( trashedID )}>
                            {__("Delete")}
                        </Button>
                        <Button intent={Intent.DANGER} onClick={() => setTrashOpen(false)}>
                            <Icon icon="cross" />
                        </Button>
                    </ButtonGroup>
                </div>
            </Dialog>
        </div>
    )
} 

export default MilestoneIncludeList