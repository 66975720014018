import { IProject } from "../interfaces";

export default function excelInsertProjects ( data : IProject[] ): IProject[] {
    console.log( data[0] )
    const projects: IProject[] = data.map(d => {
        let project: IProject = {...d}

        return project
    })
    return projects
}