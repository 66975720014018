import { __ } from "@/libs/utilities";
import { Button } from "@blueprintjs/core";
import { useRef, useState } from "react";

export type UploadRef = {
    upload: () => void;
    abort: () => void;
  }

/* Protopia Ecosystem component */
export interface IUploaderProps {
    onUpload?: (data: unknown) => void
}
const Uploader = (props: IUploaderProps): JSX.Element => {
    const [drop, setDrop] = useState<boolean>(false)
    const [disabled, setDisabled] = useState<boolean>(false)
    const [loading , setLoading ] = useState<boolean>(false)
    const [progress, setProgress] = useState(0)

    const abortUploading = useRef<() => void>()
    const abort = () => {
        abortUploading.current?.()
        reset()
    }
    const reset = () => {
        setLoading(false);
        setProgress(0);
    }
    // const handleFile = (file: File) => {
    //     if (loading || !file) return; 
    //     setLoading(true);
    //     const uploading = upload<T>(file, url, { onProgress: setProgress });
    //     abortUploading.current = uploading.abort;
    //     uploading
    //       .then( props.onUpload )
    //       .catch((e: any) => {})
    //       .finally(reset)
    // };

    const onDragLeave = (e: React.DragEvent<HTMLElement>) => {
        if (disabled) return;
        e.preventDefault();
        setDrop(false);
      };
      
      const onDragOver = (e: React.DragEvent<HTMLElement>) => {
        if (disabled) return;
        e.preventDefault();
        setDrop(true);
      };
      
      const handleDrop = (e: React.DragEvent<HTMLElement>) => {
        if (disabled) return;
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        setDrop(false);
        console.log(droppedFile)
        // handleFile(droppedFile);
    };

    const getFile = (file:File) => {
        
    }
      
    const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        if(!evt.target.files) return
        let file = evt.target.files[0];
        if(file.size > 20971500)
        {
            alert(__("File is too big. Maximum file size is 200kB. " + file.size ))
            return
        }
        let reader = new FileReader();      
        reader.readAsDataURL(file);
      
        reader.onload = function( theFile ) {
            return function (e: any) {  
                console.log( e.target.result, theFile )  
			}
        }( file )
      
        reader.onerror = function() {
            console.log(reader.error);
        };
    }
    return !loading
        ?
        <div 
            className='pe-uploader-container'
            onDrop={handleDrop}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
        >
            <input
                type="file"
                value={""}
                onChange={onChange}
            />
            <div className="position-absolute w-100 h-100 flex-centered flex-column untouchble">
                <Button minimal large>
                    {
                        __("Drag file there or click me")
                    }
                </Button>
                <div className="descr">
                    {__("Upload file (1500kB max)")}
                </div>
            </div>
        </div>
        :
        <div className='pe-uploader-container ' >
            {/* 
            <ProgressIndicator progress={progress} className={s.progress} theme="green" />
            <CloseCircleOutlined className={s.abort} onClick={abort} />     
            */}
        </div>
}
export default Uploader