import useVKApi from '@/modules/pe-basic-module/hooks/useVKApi'
import { VIDEO_HOSTING_TYPE, VIDEO_SEPARATOR } from '@/libs/interfaces/layouts'
import { AppToaster, Loading } from '@/libs/useful'
import useScreenSize from '@/modules/pe-basic-module/hooks/useScreenSize'
import { Dialog, Intent } from '@blueprintjs/core'
import { useEffect, useId, useState } from 'react'
import { __ } from "src/libs/utilities"
import IScalarForm from '../interface/IScalarForm'
import Scalar from './Scalar'


export const getVideoValue = (value: string) : string[] => {
    return value?.split(VIDEO_SEPARATOR) || []
}

const VKVideo = (props: IScalarForm) : JSX.Element => {
    const isDisabled:boolean = useVKApi()
    return <Scalar 
        { ...props } 
        enabledForm={<VKVideoEnabled {...props} isDisabled={isDisabled} />}
        desabledForm={<VKVideoDisabled {...props}  isDisabled={isDisabled} />}
    />
} 
export default VKVideo 


interface IVKVideoProps extends IScalarForm {
    isDisabled: boolean
}
export const VKVideoEnabled = ( props: IVKVideoProps ): JSX.Element => { 
    const [value, setValue] = useState<string>( getVideoValue( props.value )[0] )
    const [hostingType, ]= useState<VIDEO_HOSTING_TYPE>( VIDEO_HOSTING_TYPE.VK )
    
    const onChange = (evt: any) => {
        const value = evt.target.value
        const isVKEmbed: boolean = verifyVKVideoEmbed( value )
        console.log( isVKEmbed )
        if( !isVKEmbed ) {
            AppToaster.show({
                message: <div>
                    <div className="lead mb-2">
                        {__("Incorrect data")}
                    </div>
                    <div>
                        {__("Open the video in the player, right-click and copy the «Embed code»")}
                    </div>
                </div>,
                intent: Intent.DANGER
            })
            return 
        }
        const _value: string = value.replaceAll('"', "'")
        setValue(_value) 
        if( props.onChange ) {
            props.onChange( _value + VIDEO_SEPARATOR + hostingType,  props.field, "" )
        } 
    }

    return props.isDisabled 
    ?
    <div className="w-100 flex-centered">
        <Loading />
    </div>
    :
    <div className=" d-flex flex-column w-100 p-0 border2 border-secondary-medium border-dotted">
        <VKVideoPlayer value={value} width={props.width} height={props.height} />
        <input  
            className = "form-control input dark" 
            placeholder={__("Set embed code ")}
            value = { value } 
            onChange={onChange}
        />
        <div className="scalar-commentary p-2">
            { __("Copy embed code (right click on player)") }
        </div>
    </div>
} 
 
export const VKVideoDisabled = ( props: IVKVideoProps ): JSX.Element => {
    const [value, ] = useState<string>( getVideoValue( props.value)[0] )  
    return <VKVideoPlayer value={value} width={props.width} height={props.height} />  
}

export const YoutubePlayer = (props: any): JSX.Element => {
    const id = useId()
    const [isPlay, setIsPlay]   = useState<boolean>(false)
    const [width, setWidth]     = useState<number>(props.width  || 400)
    const [height, setHeight]   = useState<number>(props.height || 160)
    const windowSize = useScreenSize()
    useEffect(() => {
        const ytp: HTMLElement = document.getElementById(`vk-player-${id}`)!
        setWidth(ytp.offsetWidth)
        setHeight(ytp.offsetHeight)
    }, [])
    const onPlay = () => {
        setIsPlay(!isPlay)
    }
    return <div style={{width, height}} id={`vk-player-${id}`}>
    {
        !isPlay
            ?
            <Thumbnail id={ props.value } width={width} height={height} onPlay={onPlay} />
            :
            width  > 400 && 
            height > 160
                ?
                <iframe 
                    width={props.width ? props.width : props.height && typeof props.height === "number" ? props.height * 1.6 : "320"} 
                    height={props.height || "160"} 
                    src={`https://www.youtube.com/embed/${  props.value }?controls=2&autoplay=1`} 
                    title="VK video player"
                    allowFullScreen
                />
                :
                <>
                    <Thumbnail id={ props.value } width={props.width} height={props.height} onPlay={onPlay} />
                    <Dialog
                        isOpen={true}
                        onClose={() => setIsPlay(false)}
                    >
                        <iframe
                            width={windowSize.width < 800 ? windowSize.width : 800 } 
                            height={480} 
                            src={`https://www.youtube.com/embed/${  props.value }?controls=2&autoplay=1`} 
                            title="VK video player"
                            allowFullScreen
                        />
                    </Dialog>
                </>
    }        
    </div>
} 

export const Thumbnail = (props: any) :JSX.Element => {
    return <div 
        className={`video-container position-relative ${props.isPlay ? "isPlay" : ""}`}  
        style={{width:props.width, height: props.height}}
        onClick={props.onPlay}
    >  
        <div 
            className={`thumbnail card-top-img1 `} 
            style={{
                backgroundImage:`url(https://img.youtube.com/vi/${ props.id }/0.jpg)`, 
                width:props.width, 
                height: props.height
                }}  
            onClick={props.onPlay}
        /> 
        <svg 
            viewBox="0 0 24 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="video-icon" 
        >
            <path className="round" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" fill="#00000077" stroke="#FFFFFF" strokeWidth=".33" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="tre" d="M15.0015 11.3344C15.3354 11.5569 15.5023 11.6682 15.5605 11.8085C15.6113 11.9311 15.6113 12.0689 15.5605 12.1915C15.5023 12.3318 15.3354 12.4431 15.0015 12.6656L11.2438 15.1708C10.8397 15.4402 10.6377 15.5749 10.4702 15.5649C10.3243 15.5561 10.1894 15.484 10.1012 15.3674C10 15.2336 10 14.9908 10 14.5052V9.49481C10 9.00923 10 8.76644 10.1012 8.63261C10.1894 8.51601 10.3243 8.44386 10.4702 8.43515C10.6377 8.42515 10.8397 8.55982 11.2438 8.82917L15.0015 11.3344Z" stroke="#FFFFFF" strokeWidth=".33" strokeLinecap="round" strokeLinejoin="round"/>
        </svg> 
    </div>
} 

const VKVideoPlayer = (props: any) => {
    const isDisabled: boolean   = useVKApi() 
    const videoWidth            = props.width  ? props.width  : 390
    const videoHeight           = props.height ? props.height : 260
    useEffect(() => { 
        /*
        const vData: IVKVideoData = getVKVideoData(props.value)        
        window.VK.Api.call(
            "video.get",
            {
                videos: `${vData.ownerId}_${vData.id}`,
                v:"5.199"
            },
            (r: any) => {
                if(r.error) {
                    console.log( r )
                    AppToaster.show({
                        message: "VK API error:"+  r.error?.error_msg,
                        intent: Intent.DANGER,
                        timeout: 10000
                    }) 
                    return
                }
                console.log( r )
            }
        )
        */
    }, [isDisabled])
    const __html = props.value
        .replace(/width="\d*"/, "width='"+ videoWidth + "'")
        .replace(/height="\d*"/, "height='"+videoHeight+"'")
        .replace(/width='\d*'/, "width='"+ videoWidth + "'")
        .replace(/height='\d*'/, "height='"+videoHeight+"'")
    const value = <>
        <div className='w-100 mt-2 ' dangerouslySetInnerHTML={{ __html }} />
    </>
    return value
}

export interface IVKVideoData {
    id: string
    ownerId: string
    hash: string
}
export function getVKVideoData(embed_code: string): IVKVideoData {
    const matchId: string[] | null = embed_code.match(/oid=(.*?)&/)
    const matchOwnerId: string[] | null = embed_code.match(/&id=(.*?)&/)
    const matchHash: string[] | null = embed_code.match(/&hash=(.*?)"/)
    return {
        ownerId : matchId ? matchId[1] : embed_code,
        id: matchOwnerId ? matchOwnerId[1] : embed_code,
        hash: matchHash ? matchHash[1] : embed_code,
    }
}

export function verifyVKVideoEmbed(mayBeEmbedCode:string): boolean {
    const match = `<iframe src="https://vkvideo.ru/video_ext.php?oid=`
    return mayBeEmbedCode.indexOf(match) === 0
}