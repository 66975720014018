import { __, getContrastColor } from "@/libs/utilities"
import { Select } from "@/libs/useful"
import IScalarForm from "../interface/IScalarForm"
import Scalar from "./Scalar"
import { Color } from "@/libs/interfaces/layouts"
import { schema } from "@/libs/layouts"
import { Tag } from "@blueprintjs/core"

/* Protopia Ecosystem component */ 

const DataTypeSelector = (props: IScalarForm) : JSX.Element => {
    return <Scalar 
        { ...props } 
        notClear
        enabledForm={<DataTypeSelectorEnabled {...props} notClear />}
        desabledForm={<DataTypeSelectorDisabled {...props} />}
    />
}
export default DataTypeSelector



const DataTypeSelectorEnabled = ( props: IScalarForm ): JSX.Element => { 
    const items: any = schema()
    const onChange = (evt: any) => {
        if( props.onChange ) {
            //props.onChange( items.filter( item => item.id === value )[0],  props.field, "" )
        }   
    }  
    const selectItems = Object.keys(items)
    .sort((a:any, b:any) => { 
        return ( '' + __( items[a].name ) ).localeCompare( __( items[b].name ) )
    })
    .map((key, i) => { 
        return { ...items[key], id: key, title: __(items[key].name), key: i } 
    })

    return <div 
        className={`${ props.className ? props.className :` d-flex justify-content-center my-2 w-100 ` }`}
    >
        <Select 
            isMulti={ false }
            items={ [{ id: -1, title: "--", key: -1 }, ...selectItems] }
            value={ getTitledItem(props.value) }
            onChange={ onChange }
            placeholder={props.placeholder}
        />
    </div>
} 
 
const DataTypeSelectorDisabled = ( props: IScalarForm ): JSX.Element => {
    const fills: Color[] | null =  schema()[ props.value || "Post"  ]?.admin_data?.fill
    const color : string = Array.isArray( fills ) ? fills[0].toString() : "#000000"
    return <Tag 
        round
        className="px-4 py-1 m-1"
        style={{
            backgroundColor: color,
            color: getContrastColor( color )
        }}
    >
        { props.value || "" }
    </Tag>
} 

const getTitledItem = ( key: string ) => { 
    if(!key) return {id:-1, title: "--"}
    const items = schema()
    let item : any = schema()[key]
    if(!item?.name) {
        item = Object.keys(items).filter( key => items[key] === key )[0] 
        if(!item) {
            return {id:-1, title: "--"}
        }
    }
    return { ...item, title: __(item.name), id: key }
}
 