import { VIDEO_HOSTING_TYPE, VIDEO_SEPARATOR } from '@/libs/interfaces/layouts' 
import { useId, useState } from 'react'
import { __ } from "src/libs/utilities"
import IScalarForm from '../interface/IScalarForm'
import Scalar from './Scalar'
import useRutubeApi, { IRutubeData } from '@/modules/pe-basic-module/hooks/useRutubeApi'


export const getVideoValue = (value: string) : string[] => {
    return value?.split(VIDEO_SEPARATOR) || []
}

const RutubeVideo = (props: IScalarForm) : JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<RutubeVideoEnabled {...props} />}
        desabledForm={<RutubeVideoDisabled {...props} />}
    />
} 
export default RutubeVideo 

export const RutubeVideoEnabled = ( props: IScalarForm ): JSX.Element => { 
    const [value, setValue] = useState<string>( getVideoValue( props.value )[0] )
    const [hostingType, ] = useState<VIDEO_HOSTING_TYPE>( VIDEO_HOSTING_TYPE.RUTUBE )
    
    const onChange = (evt: any) => {
        const _value: string = evt.target.value.replaceAll('"', "'")
        setValue(_value) 
        if( props.onChange ) {
            props.onChange( _value + VIDEO_SEPARATOR + hostingType,  props.field, "" )
        } 
    }

    return <div className=" d-flex flex-column  w-100 p-0 border2 border-secondary-medium border-dotted">
        <RutubePlayer value={value} width={props.width} height={props.height} />
        <input  
            className = "form-control input dark" 
            placeholder={__("Set embed code ")}
            value = { value } 
            onChange={onChange}
        />
        <div className="scalar-commentary p-2">
            { __("Copy embed code (right click on player)") }
        </div>
    </div>
} 
 
export const RutubeVideoDisabled = ( props: IScalarForm ): JSX.Element => {
    const [value, ] = useState<string>( getVideoValue( props.value)[0] ) 
    return <RutubePlayer value={value} width={props.width} height={props.height} />  
}

export const RutubePlayer = (props: any) => {
    const id = useId()
    // const data: IRutubeData | null = useRutubeApi(props.value); 
    const videoWidth  = props.width  ? props.width  : 390
    const videoHeight = props.height ? props.height : 260
    const __html = props.value
        .replace(/width="\d*"/, "width='" + videoWidth + "'")
        .replace(/height="\d*"/, "height='" +videoHeight + "'")
        .replace(/width='\d*'/, "width='" + videoWidth + "'")
        .replace(/height='\d*'/, "height='" +videoHeight + "'") 
        .replace(/mozallowfullscreen/, "id='" + id.toString() + "' ")
        .replace(/https:\/\/rutube.ru\/play\/embed\//, "https://rutube.ru/embed/")
        .replace("clipboard-write; autoplay", "encrypted-media")
    const value = <>
        <div className='w-100 mt-2 ' dangerouslySetInnerHTML={{ __html }} /> 
    </>
    return value
}
 