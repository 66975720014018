import { Button, ButtonGroup, Intent, NumberRange, RangeSlider } from '@blueprintjs/core'
import { __ , sprintf } from '@/libs/utilities'
import React, { useEffect, useState } from 'react' 
import FieldInput, { SCALAR_TYPES } from '@/libs/scalars'
import { VerticalType } from '@/libs/scalars/interface/IScalarForm'
import { IAppoloField, POST_STATUS } from '@/libs/interfaces/layouts'


export interface IMetaFilterElementProps {
    field: IAppoloField
    onFilter: (key: string, value: any) => void
    value?:any
}
const MetaFilterElement = ({ field, onFilter, value }: any) : JSX.Element => {
    const [select, setSelect] = useState<any>( value )

    useEffect(() => {
        setSelect( value )
    }, [value])
    
    const changeSelect = (val: any) => {
        // console.log( val )
        setSelect( val )
        onFilter(field.name, val)
    }
    
    switch(field.type)
    {
        case SCALAR_TYPES.POST_STATUS:
            return <div className="p-2 w-32 w-sm-100 card m-1 opacity_75" >
                <div className="title text-secondary">
                    { __( field.title ) }
                </div>
                <div className="pl-4">
                    <ButtonGroup>
                        <Button
                            intent={Intent.SUCCESS}
                            minimal={select !== POST_STATUS.PUBLISH}
                            data-val="1"
                            data-field={field.field}
                            onClick={ () => changeSelect( POST_STATUS.PUBLISH ) }
                        >
                            {__("Visibled")}
                        </Button>
                        <Button
                            intent={Intent.DANGER}
                            minimal={select !== POST_STATUS.DRAFT}
                            data-val="2"
                            data-field={field.field}
                            onClick={ () => changeSelect( POST_STATUS.DRAFT ) }
                        >
                            {__("Hidden")}
                        </Button>
                        <Button
                            intent={Intent.NONE}
                            minimal={select !== POST_STATUS.ALL} 
                            data-val="0"
                            data-field={field.field}
                            onClick={ () => changeSelect( POST_STATUS.ALL ) }
                        >
                            {__("All")}
                        </Button>
                    </ButtonGroup> 
                </div>                
            </div>
        case SCALAR_TYPES.CHECKBOX:
            return <div className="p-2 w-32 w-sm-100 card m-1 opacity_75" >
                <div className="title text-secondary">
                    { __( field.title ) }
                </div>
                <div className="pl-4">
                    <FieldInput
                        editable
                        vertical={VerticalType.VERTICAL}
                        value={Array.isArray( select ) ? select : []}
                        onChange={ changeSelect }
                        type={SCALAR_TYPES.CHECKBOX_GROUP}
                        values={ field.values || [] }
                    />
                </div>                
            </div> 
        case SCALAR_TYPES.NUMBER:
            return <div className="p-2 w-32 w-sm-100 card m-1 opacity_75" >
                <div className="title text-secondary">
                    { __( field.title ) }
                </div>
                <div>
                    <RangeSlider
                        min={ field.min || 0 }
                        max={ field.max || 100 }
                        stepSize={1}
                        labelStepSize={ field.max - field.min < 20 ? 1 : 20 } 
                        onChange={(range: NumberRange) => changeSelect( range ) }
                        value={select || [ 0, field.max || 100 ]} 
                        handleHtmlProps={
                            { 
                                start:  { "aria-label": "example start" }, 
                                end:    { "aria-label": "example end" } 
                            }
                        }
                    />
                </div>
            </div>
        case SCALAR_TYPES.BOOLEAN:
            return <div className="p-2 w-32 w-sm-100 card m-1 opacity_75" >
                <div className="title text-secondary">
                    { __( field.title ) }
                </div> 
                <ButtonGroup>
                    <Button
                        intent={Intent.SUCCESS}
                        minimal={typeof select === "undefined" || select !== true}
                        data-val="1"
                        data-field={field.field}
                        onClick={ () => changeSelect( true ) }
                    >
                        {__("Yes")}
                    </Button>
                    <Button
                        intent={Intent.DANGER}
                        minimal={typeof select === "undefined" || select !== false}
                        data-val="2"
                        data-field={field.field}
                        onClick={ () => changeSelect( false ) }
                    >
                        {__("No")}
                    </Button>
                    <Button
                        intent={Intent.NONE}
                        minimal
                        data-val="0"
                        data-field={field.field}
                        onClick={ () => changeSelect( null ) }
                    >
                        {__("clear filter")}
                    </Button>
                </ButtonGroup>
            </div> 
        case SCALAR_TYPES.EXTERNAL:
            const sel = [].map(( e: any, i: number ) => { 
                const select = false
                return <option value={e.id} key={e.id} selected={select}>
                    {e.post_title}
                </option>
            })
            return <div className="p-2 w-sm-100 card m-1 opacity_75" >
                    <div className="title text-secondary">
                        { __(field.title) }
                    </div>
                    <select
                        className="filter form-control"
                        name={field.name}
                        onChange={ () => changeSelect( field.name ) }
                    >
                        <option value="-1">---</option>
                        {sel}
                    </select>
                </div>  
        default:
            return <div className="p-2 w-32 w-sm-100  card m-1 opacity_75" >
                <div className="title text-secondary">
                    { __( field.title ) }
                </div>
                <div>
                    <input 
                        type="text"
                        placeholder={ sprintf ( __("Insert %s for filter"), __(field.title) ) } 
                        className="form-control input dark"
                        onChange={ evt => changeSelect( evt.target.value ) }
                    />
                </div>
            </div>
    }
} 

export default MetaFilterElement