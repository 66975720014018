import useScreenSize from '@/modules/pe-basic-module/hooks/useScreenSize'
import { WINDOW_WIDTH } from '../interfaces/layouts'
import LayoutHeaderMobile from './header/LayoutHeaderMobile'
import LayoutHeaderScreen from './header/login'

const LayoutHeader = ( ) : JSX.Element => { 
	const {width} = useScreenSize()
    const isScreen = width > WINDOW_WIDTH.TABLET 
	return isScreen
		?
		<LayoutHeaderScreen /> 
		:
		<LayoutHeaderMobile />
		
} 

export default LayoutHeader