import { IUser, useMainStore } from '@/settings/zustand'
import { isRole } from '@/settings/zustand/user'
import { getAllRoutes, getRoute, template } from '@/libs/layouts'
import { Button, Dialog, Icon, Intent } from '@blueprintjs/core'
import { LayoutIcon, ScalableDialog } from '@/libs/useful'
import { __ } from '@/libs/utilities'
import { useEffect, useState } from 'react'
import { Location, useLocation } from 'react-router' 
import { DIALOG_SIZE, IMenuItem, Role } from '@/libs/interfaces/layouts'
import HelpMenu from './helps/HelpMenu'
import HelpVideo from './helps/HelpVideo'
import Help from './helps/Help'

const HELP_ICON = "/assets/img/fest/help.svg" 

const getCurrentData = ( location: Location ): any  => {
    // console.log(location, searchRoute( location ) )
    return searchRoute( location )?.helps || []
}
const existedVideosByUser = (videos: any ) : boolean =>
{  
    const user: IUser = useMainStore.getState().user 
    // console.log( videos )
    if( !Array.isArray(videos) || !videos.length ) return false
    if(user.id && !!user.roles?.length )
    {
        const r: Role[] =  videos.reduce( (prevous, video) => {
            const summ = typeof video.roles === "undefined" || !video.roles.length 
                ?
                [ ...prevous, ...user.roles ]
                :
                [ ...prevous, ...video.roles ] 
            return summ
            }, [] )  
        return isRole([...r, "administrator"])
    }
    else
    {
        const r = videos
            .filter( video => typeof video.roles === "undefined" || !video.roles.length )
        return !!r.length
    }
}


const HelpMenuWidget = (props: any):JSX.Element | null => {
    const location:Location = useLocation()
    
    const [currentList, setCurrentList] = useState<any[]>( [] ) 
    const [exists, setExists] = useState<boolean>( false )
    const [isOpen, setOpen] = useState( false )
    const [isRoute, setRoute] = useState( false )
    const [isMenu, setMenu] = useState( exists )
    const [currentVideo, setCurrentVideo] = useState( 
        !Array.isArray(currentList) 
            ? 
            currentList 
            :
            currentList[0]
                ?
                currentList[0]
                : 
                {} 
    )
    const changeOpen = () => {
        setOpen( !isOpen )
        setMenu( Array.isArray(currentList) && currentList.length > 1 )
    }
    const chooseCurrentVideo = (current: any) =>
    {
        setMenu(false)
        setCurrentVideo( current )
    }
    useEffect(() => {
        const list : any[] = getCurrentData( location )
        const isExists: boolean = existedVideosByUser( list ) 
        setCurrentVideo( list.length ? list[0] : {} )
        setCurrentList( list )
        setExists( isExists ) 
        setRoute( isExists )    
    }, [ location.pathname ])
    
    return <>
        <div className={`indicator dble lrg classic p-0 m-0 overflow-hidden ${ isRoute ? " opened " : " closed"}`} onClick={ changeOpen }>
            <div className={ `header-menu-element ${ template().header_menu } main-menu-filter-icon ` }  >
                <LayoutIcon
                    src={ HELP_ICON }
                    className="header-menu-icon mt-sm-0 mt-2"
                    isSVG={false}
                />
                <span className='header-menu-label d-md-block d-none'>
                    { __( "Help" ) }
                </span>
            </div> 
        </div>
        <ScalableDialog
            isOpen={isOpen}
            onClose={changeOpen}
            dialogSize={DIALOG_SIZE.NORMAL}
            className=""
        >
            {
                // Если для Пользователя с его набором ролей доступно больше одного ролика - нужно меню
                exists 
                    ?
                    isMenu
                        ?
                        <HelpMenu {...props} currentList={currentList} onClick={chooseCurrentVideo}/>
                        :
                        <Help {...props} item={currentVideo}/>
                    :
                    <Help {...props} item={currentVideo}/>
            } 
            <Button className='position-absolute top right px-3 py-2' onClick={changeOpen} minimal>
                <Icon size={33} color="#FFFFFF" icon="cross" />
            </Button>
        </ScalableDialog>
    </> 
} 
export default HelpMenuWidget



export const helpsTypes = () : any => {
    return {
        youtube: {
            id: "youtube",
            icon: "fas fa-play-circle"
        },
        html_source: {
            id: "html_source",
            icon: "far fa-file-alt"
        },
    }
}


const searchRoute = (location: Location) => {
    const s: IMenuItem[] = getAllRoutes()
        .filter((route: IMenuItem) => { 
            if(route.childRoutes) {
                if(route.childRoutes.reduce(( prevous, childRoute: string ) => {
                    if( location.pathname === `${ route.realRoute }/${childRoute}` ) { 
                        return true
                    }
                    return prevous
                }, false)) {
                    return true
                }
            }
            return route.realRoute !== "/" && location.pathname.indexOf(getRoute(route)) > -1
            //return route.realRoute === location.pathname
        })
    return s[ s.length - 1 ]
}