import FieldInput from "@/libs/scalars"
import { SCALAR_TYPES, VerticalType } from "@/libs/scalars/interface/IScalarForm"
import { __ } from "@/libs/utilities"
import { IState, SORT_ORDER, useMainStore } from "@/settings/zustand"
import { IAdminStore, useAdminStore } from "../../data/store" 
import { apolloFields } from "@/libs/layouts"

/* Protopia Ecosystem component */
export interface IDataTableSettingsPanelProps {
    data_type: string
    onSort: (order: SORT_ORDER, byField: string) => void
}
const DataTableSettingsPanel = (props: IDataTableSettingsPanelProps): JSX.Element => {
    const pageCount: number = useMainStore(( state: IState ) => state.pageCount)
    const setPageCount: any = useMainStore((state: IState ) => state.setPageCount) 
    const sortOrder: SORT_ORDER = useAdminStore(( state: IAdminStore ) => state.sortOrder)
    const setSortOrder: any = useAdminStore((state: IAdminStore ) => state.setSortOrder) 
    const sortByField: string = useAdminStore(( state: IAdminStore ) => state.sortByField)
    const setSortByField: any = useAdminStore((state: IAdminStore ) => state.setSortByField) 

    const onSortOrder = ( order: SORT_ORDER ) => {
        setSortOrder( order )
        // props.onSort( order, "" ) 
    }

    const onSortBy = ( orderBy: string ) => {
        console.log( orderBy )
        setSortByField( orderBy )
        // props.onSort( orderBy, "" ) 
    }

    return <div className="p-3 pe-light mt-2 border-bottom-dark"> 
        <div className="row px-3">
            <div className="col-md-4"> 
                {/* <div className="h-100 col-md-5 text-md-left text-center  align-items-center">
                    {__("Count elements by page:")}
                </div>
                <input
                    type="number"
                    className="form-control input dark ml-2 col-md-6"
                    value={ pageCount }
                    onChange={ (evt: any ) => setPageCount( evt.target?.value )}
                /> */}
                <FieldInput 
                    editable
                    vertical={VerticalType.VERTICAL}
                    value={pageCount} 
                    type={SCALAR_TYPES.NUMBER}
                    title={__("Count elements by page:")}
                    onChange={setPageCount}
                    notClear
                /> 
            </div>
            <div className="col-md-4"> 
                <FieldInput 
                    editable
                    vertical={VerticalType.VERTICAL}
                    value={sortOrder}
                    values={Object.values(SORT_ORDER).map(s => {
                        return { 
                            _id:s,
                            title: __(s)
                        }
                    })}
                    type={SCALAR_TYPES.TAGS}
                    title={__("Sort order:")}
                    onChange={ onSortOrder }
                    notClear
                /> 
            </div>
            <div className="col-md-4">
                <FieldInput 
                    editable
                    vertical={VerticalType.VERTICAL}
                    value={sortByField}
                    type={SCALAR_TYPES.TAGS}
                    values={
                        Object.keys( apolloFields( props.data_type ) )
                        .filter(af => {
                            return apolloFields( props.data_type )[af].type !== SCALAR_TYPES.MEDIA && 
                                apolloFields( props.data_type )[af].type !== "array" && 
                                apolloFields( props.data_type )[af].type !== "external_array" 
                        })
                        .filter(af => !!apolloFields( props.data_type )[af].thread )
                        .map( af => {
                            return { 
                                _id: af ,
                                title: __( apolloFields( props.data_type )[af].title )
                            }
                            
                        })
                    }
                    title={__("Sort by field:")}
                    onChange={ onSortBy }
                    notClear
                /> 
            </div> 
        </div> 
    </div>
}
export default DataTableSettingsPanel