import { ID } from "@/libs/interfaces/layouts"
import { LayoutHeaderTitle } from "@/libs/layout-app/header/LayoutHeaderHeader"
import { iconBg, iconHeight, iconWidth } from "@/libs/layouts"
import Attantion from "@/libs/useful/Attantion"
import { __, sprintf } from "@/libs/utilities"
import { component_url } from "@/libs/utilities/links"
import { Button } from "@blueprintjs/core"
import { NavLink } from "react-router-dom"
import { IFestival } from "../data/interfaces"
import { IFestDataStor, useFestDataStore } from "../data/store"  
import { IState, useMainStore } from "@/settings/zustand"
import PhaseLabel from "../views/PhaseLabel"

const FestLayoutHeaderWidget = (): JSX.Element => {
    return <div className='pe-fest-layout-header-container d-flex align-items-center flex-grow-100'>
        <FestHeader /> 
    </div>
}
export default FestLayoutHeaderWidget

const FestHeader = () => {
    const festival:IFestival = useFestDataStore((state: IFestDataStor) => state.festival)
    //const festId:ID = useFestDataStore((state: IFestDataStor) => state.festId) 
    const festId:ID = useMainStore((state: IState) => state.landId) 
    const link = component_url( "FestivalView" )
    const getFestLink = () => { 
        return festId ? `${link === "/" ? "" : link}/fest/${ festId }` : ''
    }

    return  festival.id && festival.id !== -1
        ?
        <>
            <NavLink to={ getFestLink() } className="d-flex mainLink mr-2">
                <div
                    className="layout-header-icon "
                    style={{
                        backgroundImage: `url(${ festival.icon })`,
                        height: iconHeight(),
                        width: iconWidth(),
                        minWidth: iconWidth(),
                        backgroundColor: iconBg(),
                        backgroundSize: `${iconWidth() - 8}px  ${iconHeight() - 8}px`,
                        backgroundRepeat: "no-repeat"
                    }}
                /> 
                    <div className="flex-centered" style={{ maxWidth: "calc(100% - 60px)" }}>
                        <div className="layout-header-title">
                            { festival.title }
                            <div className="layout-header-description d-sm-flex">
                                { festival.description }
                            </div>
                            <small>
                                <PhaseLabel className=" layout-header-description small d-sm-flex justify-content-center text-lowercase m-0 p-0 "/>
                            </small> 
                        </div>
                        <div className="p-3 bg-danger text-white d-none">{ festival.id }</div>
                    </div> 
            </NavLink>
            <FestLayoutHeaderLink link={link} /> 
        </>
        :
        <LayoutHeaderTitle />
}

export interface IFestLayoutHeaderLinkProps {
    link: string
}
const FestLayoutHeaderLink = ({link, ...props}: IFestLayoutHeaderLinkProps): JSX.Element => {
    return link 
        ?
        <NavLink to={ link } >
            <Button large minimal className="px-0">
                <i className="fas fa-caret-down my-3 mx-1 layout-header-title" />
            </Button>
        </NavLink>
        :
        <Attantion 
            text={
                sprintf( 
                    __("Route for %s not install! Contact your system administrator."),
                    "FestivalView"
                )
            }
        />
} 