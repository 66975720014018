import { ID, WINDOW_WIDTH } from '@/libs/interfaces/layouts'
import { LayoutIcon } from '@/libs/useful'
import { __, compareArrays } from '@/libs/utilities'
import { useDataStore } from '@/modules/pe-basic-module/data/store'
import { isRole } from '@/settings/zustand/user'
import { Button, ButtonGroup, Collapse, Popover, Position } from '@blueprintjs/core'
import React, { useEffect, useRef, useState } from 'react'
import { template } from 'src/libs/layouts'
import { SET_FILTERS_ACTIONS } from '../data/actionTypes'
import { syncAction } from '../data/actions'
import { IFestFilters, ITrack, TRACK_FILTER_ICON_URL } from '../data/interfaces'
import { IFestStore, useFestStore } from '../data/store'
import { isMyTrack } from '../data/utils/user'
import useClickOutside from './hooks/useClickOutside'
import useProjectListRoute from './hooks/useProjectListRoute'
import useScreenSize from '@/modules/pe-basic-module/hooks/useScreenSize'
 
const TrackFilterMenu = () : JSX.Element => {  
    return <TrackFilters />   
}

export const TrackFilters = () : JSX.Element => { 
    const tracks : ITrack[] = useDataStore((state:any) => state.PEFestTrack)  || []
    const filters: IFestFilters = useFestStore((state: IFestStore) => state.filters )
    const {width} = useScreenSize()
    const isScreen = width > WINDOW_WIDTH.TABLET
    const setCurrentTitle = () => {  
        switch(filters.tracks[0]) {
            case -1:       
                setTitle( "all Tracks" )
                break
            case "mine": 
                setTitle( "Only mine Tracks" ) 
                break
            default: 
                const curTrack = tracks.filter( track => track.id === filters.tracks[0]?.toString() )[0]
                setTitle( curTrack ? curTrack.title : "all Tracks")
        }
    }
    const [title, setTitle] = useState( __( "all Tracks" ) )
    const [isOpen, setOpen] = useState(false)
    const [isCollapse, setCollapse] = useState(!isScreen)
    
    const myRef: React.MutableRefObject<null> = useRef( null )
    const isRoute: boolean = useProjectListRoute() && !!tracks.length
    
    const onOpen = (isOpen:boolean) =>
    {
        setOpen(isOpen)
    }
    let timeOut: any = -1    
    useClickOutside( myRef, onOpen, timeOut)

    useEffect( () => {
        setCurrentTitle()
    }, [ filters ] )

    
     
    const handleLoginOpen = () =>
    {
        clearTimeout( timeOut )
        onOpen(true)
    }
    const selectTrack = (id: ID) =>
    {   
        const selectTracks =  id === -1
                ?
                [-1]
                :
                id === "mine" ? ["mine"] : [ id ] 
        syncAction(
            SET_FILTERS_ACTIONS, 
            { 
                filters:{ 
                    tracks: selectTracks[0] === "mine"
                        ?
                        tracks.filter( ( _track: ITrack ) => {
                            return isMyTrack( _track )  //|| isOwnerTrack( _track ) 
                        })
                        .map(project => project.id)
                        :
                        selectTracks,
                    schools: filters.schools
                } 
            } 
        )
        setCurrentTitle()
        clearTimeout( timeOut )
        onOpen(false)
    } 
    const onMouseLeave = () =>
    {
        clearTimeout( timeOut )
        timeOut = setTimeout(() =>
        {
            onOpen(false)
        }, 1000)
    }
    return <>   
        <div 
            className={`indicator dble lrg classic p-0 m-0 overflow-hidden ${ isRoute ? "" : " closed"}`}             
            onMouseLeave={onMouseLeave}
            ref={ myRef }
            title={ __( title ) }
        >
            <div className={ `header-menu-element ${ template().header_menu } main-menu-filter-icon ` }  onClick={handleLoginOpen}>
                <LayoutIcon
                    src={ TRACK_FILTER_ICON_URL }
                    className="header-menu-icon"
                    
                />
                <span className='header-menu-label px-2 ' title={ __( title ) }>
                    { __( title ) }
                </span>
            </div> 
            <Popover
                className={`indicator-popover ${ isScreen ? "position-relative": "position-absolute top left-important" }`}
                popoverClassName="p-0" 
                position={ isScreen ? Position.BOTTOM_RIGHT : Position.TOP_LEFT }
                isOpen={isOpen}
                modifiers={{
                    arrow: { enabled: false },
                    flip: { enabled: true },
                    preventOverflow: { enabled: false },
                }}
                content={(
                    <div className='overflow-y-auto' style={{maxHeight: `calc(100vh - ${ isScreen ? 150 : 200 }px)`}}>
                        <ButtonGroup 
                            vertical 
                            fill 
                            minimal 
                            onMouseEnter={() =>  clearTimeout( timeOut ) }
                            style={{ minWidth: 200 }}
                        >
                            <Button 
                                className={`p-3 btn-stretch text-force-contrast ${ compareArrays( [ -1 ], filters.tracks ) ? " bg-danger  text-light " : "" }`} 
                                onClick={() => selectTrack( -1 ) }
                            >
                                {__("all Tracks")}
                            </Button>            
                            {
                                isRole([ "Tutor", "Project_author", "Track_moderator" ]) 
                                    ?   
                                    <Button 
                                        className={`p-3 text-force-contrast btn-stretch ${ compareArrays( ['mine'], filters.tracks ) ? " bg-danger  text-light " : "" }`}  
                                        onClick={() => selectTrack( "mine" ) }
                                    >
                                        {__("Only mine Tracks")}
                                    </Button> 
                                    :
                                    null
                            }
                            {
                                Array.isArray( tracks )
                                    ?
                                    <>
                                        <Button 
                                            icon="chevron-down" 
                                            minimal
                                            small
                                            className={isCollapse ? "hidden" : " p-3 "} 
                                            onClick={( event: React.MouseEvent<HTMLElement, MouseEvent> ) => {
                                                setCollapse(true)
                                                onOpen(true)
                                                event.preventDefault()
                                                event.stopPropagation()
                                            }}
                                        />
                                        <Collapse isOpen={isCollapse} >
                                            <div className="d-flex flex-column">
                                            {
                                                tracks.map((track:ITrack) =>
                                                    {
                                                        return <Button 
                                                            className={ `p-0 btn-stretch text-force-contrast ${ filters.tracks.includes( track.id ) ? " bg-danger text-light " : "" }` } 
                                                            key={track.id} 
                                                            onClick={() => selectTrack(track.id) }
                                                        >
                                                            <div className='d-flex w-100'>
                                                                <span className='flex-grow-100 p-3 small '>
                                                                    { track.title }
                                                                </span>
                                                                <span className ='p-3 bg-secondary-super-light small '>
                                                                    { track.count || 0 }
                                                                </span>
                                                            </div>
                                                        </Button>
                                                        
                                                    })
                                            }                                    
                                            </div>
                                        </Collapse>
                                    </>
                                    :                        
                                    <div className='p-3' >
                                        {__("no Tracks")}
                                    </div>   
                            }
                        </ButtonGroup>
                    </div>
                )}
            >
                <div style={{ marginTop: 0 }} />
            </Popover> 
        </div> 
    </> 
} 

export default TrackFilterMenu



 