import { ICalculateRating, IDescriptionCorrectRating, IRating } from "../interfaces";

export default function getCalculateRating(ratings: IRating[]): ICalculateRating {
    let rating : number = 0
    let correctRating: number = 0
    let expertDisplayNames: string[] = []
    let descriptions: IDescriptionCorrectRating[] = []
    ratings.forEach(r => {
        rating      += r.rating
        correctRating += getCalcSingleRating(r)
        expertDisplayNames.push(r.display_name)
        descriptions.push( 
            {
                description: r.correct_description,
                display_name: r.display_name,
                correct_display_name: r.correct_display_name,
                oldRating: r.rating,
                newRating: Number(r.correct_rating),
            } as IDescriptionCorrectRating
        )
    })
    return {rating, correctRating, expertDisplayNames, descriptions}
}

export const getCalcSingleRating = ( rating: IRating): number => {
    const rr: number = !( typeof rating.correct_rating === "undefined" || rating.correct_rating === null || rating.correct_rating === -1 )
        ? 
        Number(rating.correct_rating)
        : 
        rating.rating || 0 
    return rr
}