import useScreenSize from "@/modules/pe-basic-module/hooks/useScreenSize"
import ClearFiltersForm from "./ClearFiltersForm"
import DestrictFilterMenu from "./DestrictFilterMenu"
import GanreFilterMenu from "./GanreFilterMenu"
import HoneycomsFilterMenu from "./HoneycomsFilterMenu"
import SchoolsFilterMenu from "./SchoolsFilterMenu"
import { TrackFilters } from "./TrackFilterMenu"
import { IPost, WINDOW_WIDTH } from "@/libs/interfaces/layouts"
import { __ } from "@/libs/utilities"
import { useEffect, useRef, useState } from "react"
import useProjectListRoute from "./hooks/useProjectListRoute"
import useClickOutside from "./hooks/useClickOutside"
import { template } from "@/libs/layouts"
import { LayoutIcon } from "@/libs/useful"
import { Collapse } from "@blueprintjs/core" 
import { IFestStore, useFestStore } from "../data/store" 
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { DEFAULT_FILTER_ICON_URL, GANRE_FILTER_ICON_URL, HONEYCOMBS_FILTER_ICON_URL, SCHOOL_FILTER_ICON_URL, TRACK_FILTER_ICON_URL } from "../data/interfaces"



/* Protopia Ecosystem component */ 
const ProjectsFiltersWidget = ( ): JSX.Element => {
    const {width} = useScreenSize()
    const [isOpen, setOpen] = useState(false) 
    const [title, setTitle] = useState( __( "Filters" ) ) 
    const [icon, setIcon] = useState<string>(DEFAULT_FILTER_ICON_URL)

    const myRef: React.MutableRefObject<null> = useRef( null )
    let timeOut: any = -1 
    const isRoute: boolean = useProjectListRoute() 

    useEffect(() => useFestStore.subscribe(
        (state: IFestStore) => {
            let title:string = "", icon: string = DEFAULT_FILTER_ICON_URL
            if(state.filters.tracks[0] !== -1) {
                const track: IPost = (useDataStore.getState().PEFestTrack || []).filter((t: IPost) => t.id === state.filters.tracks[0])[0] 
                title = track?.title || ""
                icon = track?.thumbnail || TRACK_FILTER_ICON_URL
            }
            else if(state.filters.honeycombs[0] !== -1) {
                const honeycombs: IPost = (useDataStore.getState().PEFestHoneycombs || []).filter((t: IPost) => t.id === state.filters.honeycombs[0])[0]
                title = honeycombs?.title || ""
                icon = honeycombs?.thumbnail || HONEYCOMBS_FILTER_ICON_URL
            }
            else if(state.filters.ganres[0] !== -1) {
                const ganre: IPost = (useDataStore.getState().PEFestGanre || []).filter((t: IPost) => t.id === state.filters.ganres[0])[0]
                title = ganre?.title || ""
                icon = ganre?.thumbnail || GANRE_FILTER_ICON_URL
            }
            else if(state.filters.schools[0] !== -1) {
                const school: IPost = (useDataStore.getState().PEFestSchool || []).filter((t: IPost) => t.id === state.filters.schools[0])[0]
                title = school?.title || ""
                icon = school?.thumbnail || SCHOOL_FILTER_ICON_URL
            }
            else {
                title = __( "Filters" )
                icon = DEFAULT_FILTER_ICON_URL
            }
            setTitle(title)
            setIcon(icon)
        }
      ), [])
     

    const onOpen = (isOpen:boolean = false) =>
    {
        setOpen(isOpen)
        clearTimeout( timeOut )
    }   
    useClickOutside( myRef, onOpen, timeOut)

    const onMouseLeave = () =>
    {
        clearTimeout( timeOut )
        timeOut = setTimeout(() =>
        {
            onOpen(false)
        }, 1000)
    }
    const handleLoginOpen = () =>
    {
        clearTimeout( timeOut )
        onOpen(true)
    }

    return width > WINDOW_WIDTH.TABLET
        ?
        <div className='d-flex'>
            <TrackFilters />
            <HoneycomsFilterMenu />
            <SchoolsFilterMenu />
            <DestrictFilterMenu />
            <GanreFilterMenu />
            <ClearFiltersForm />
        </div>
        :
        <span ref={ myRef } style={{ height: 50 }} onMouseLeave={onMouseLeave} className={`d-flex align-items-center`}>    
            <div 
                className={` mobyle-main-menu-link ${ isRoute ? "opened" : "closed" } `} 
            >
                <div className={ `header-menu-element ${ template().header_menu } ` }  onClick={handleLoginOpen}
                    title={ __( title ) }
                >
                    <LayoutIcon
                        src={ icon }
                        className="header-menu-icon"
                        
                    />
                    <span className='pe-mobyle-under-menu-label' title={ __( title ) }>
                        { __( title ) }
                    </span>
                </div> 
            </div> 
            <ClearFiltersForm onClick={ onOpen } />
            <Collapse isOpen={isOpen} className="pe-mobile-under-menu-bottom left w-100 flex-centered p-0">
                <div className="p-0 bg-dark text-light flex-centered ">
                    <TrackFilters />
                    <HoneycomsFilterMenu />
                    <SchoolsFilterMenu /> 
                    <GanreFilterMenu /> 
                </div>
            </Collapse>
        </span>
}
export default ProjectsFiltersWidget