
/* Protopia Ecosystem component */
export interface IBulkCheckBoxProps {
    checked: boolean
    onCheck: (evt: any) => void
}
const BulkCheckBox = (props: IBulkCheckBoxProps): JSX.Element => { 
    const onClick = (evt: any) => {
        evt.stopPropagation()
        evt.preventDefault() 
        props.onCheck(evt)
    }
    return <div className={`${props.checked ? "_is_check_" : "_no_check_"} position-absolute`} onClick={onClick}> 
    </div>

}
export default BulkCheckBox