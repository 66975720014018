import actions from "@/modules/pe-basic-module/data/actions"
import { GET_SEARCH_USERS_ACTION } from "@/modules/pe-basic-module/data/actionTypes"
import { IUser } from "@/settings/zustand"
import { Collapse, Popover, PopoverInteractionKind } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { ID, Role } from "../interfaces/layouts"
import FieldInput, { SCALAR_TYPES } from "../scalars"
import { __ } from "../utilities"
import { LoaderMinimal } from "./Loading"

/* Protopia Ecosystem component */
export interface IUserSearchEngineProps {
    user?: IUser | null
    onSelect: (user:IUser) => void
    landId?: ID
    minWidth? : number
    onlyThisLands? : boolean
    isMulti? : boolean
    notClear? : boolean
    role?: Role[]
}
const UserSearchEngine = (props: IUserSearchEngineProps): JSX.Element => { 
    const [name, setName] = useState<string>(props.user?.display_name || "")
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isLoad, setIsLoad] = useState<boolean>(false)
    const [isMulti, setIsMulti] = useState<boolean>(!!props.isMulti)
    const [users, setUsers]= useState<IUser[]>([]) 
    
    // console.log( props.landId )

    useEffect(() => {
        setName( props.user?.display_name || "" )
    },[props.user])
    const onChange = (_name:string, isMulti: boolean) => {
        setName(_name)
        setIsLoad(true) 
        actions( 
            GET_SEARCH_USERS_ACTION, 
            { 
                search:_name, 
                role: props.role,
                landId: isMulti ? "-1" : props.landId 
            } )
            .then((resp: IUser[]) => {
                setUsers(resp) 
                setIsOpen(!!resp.length) 
                setIsLoad(false) 
            })
    }
    const selectUser = (user:IUser) => {
        props.onSelect(user)
        setUsers( [] )
        setName( "" )
        setIsOpen( false )
    }
    const onFromAll = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setIsMulti( evt.target.checked )
        onChange( name, evt.target.checked )
    }
    return <div className='w-100 position-relative' style={{minWidth: props.minWidth || 0}}>
        <div className="d-flex align-items-center container pl-3">
            <FieldInput
                type={SCALAR_TYPES.STRING}
                value={name}
                onChange={value => onChange(value, isMulti)}
                onClear={() => props.onSelect({id: "-1"} as IUser)}
                editable
                placeholder={__("Start write user name")}
                notClear={props.notClear}
            />
            {
                isLoad && <LoaderMinimal />
            }
        </div>
        {
            !props.onlyThisLands && 
            <div>
                <label className="_check_">
                    <input type="checkbox" onChange={onFromAll} />
                    {__("From all festivals")}
                </label>
            </div>
        }
        <div className="w-100 position-absolute bottom left">
            <Popover
                isOpen={isOpen}
                interactionKind={PopoverInteractionKind.HOVER}
                onInteraction={state => setIsOpen(state)}
                hoverCloseDelay={1000}
                position="bottom-left"
                modifiers={{
                    arrow: { enabled: false },
                    flip: { enabled: true },
                    preventOverflow: { enabled: false }, 
                }}
                content={
                    <Collapse isOpen={isOpen}>
                        <ul className={`list-group list-group-flush container overflow-y-auto h_200 ${isLoad ? "opacity_5" : ""}`}>
                        {
                            users.map(user => {
                                return <li 
                                    className='list-group-item pointer hover-straght p-1 d-flex align-items-center' 
                                    key={user.id}
                                    onClick={ () => selectUser( user ) }
                                >
                                    <div className="user-ava" style={{backgroundImage:`url(${user.avatar})`, borderRadius:0}}></div>
                                { user.display_name}     
                                </li>
                            })
                        }
                        </ul>
                    </Collapse>
                }
            >
                <div className="w-100 position-absolute bottom left"></div>
            </Popover>
        </div>
    </div>
}
export default UserSearchEngine