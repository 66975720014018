import { __ } from "@/libs/utilities"

/* Protopia Ecosystem component */
export interface ISwitcherProps {
   goto: (url:string) => void
   url: string
}
const Switcher = ({url, goto}: ISwitcherProps): JSX.Element => {
    return <div className='pe-switcher-container'>
       <select className="form-control input dark" value={url} onChange={ (e) => goto(e.target.value) }>
            <option value="users" >
                { __("Users")}
            </option>
            <option value="schools" >
                {__("Schools")}
            </option>
            <option value="specials" >
                {__("Projects")}
            </option>
       </select>
    </div>
}
export default Switcher