import { useEffect } from "react"

/* Protopia Ecosystem component */
export interface ISEOProps {
   title: string
   description: string
   image: string
}
const SEO = (props: ISEOProps): JSX.Element => {
    useEffect(() => {
        const addSingleMeta = (title: string, content: string, propName:string="name") => {
            const elTw      = document.createElement('meta') 
            elTw.setAttribute(propName, title) 
            elTw.content = content
            elTw.setAttribute("data-react-helmet", `true`) 
            document.getElementsByTagName('head')[0].appendChild(elTw)  
        }
        const addMetas = (field: keyof ISEOProps) => {
            const content   = props[ field ] as string 
            addSingleMeta( field ,              content, "name") 
            addSingleMeta(`og:${field}`,        content, "property") 
            addSingleMeta(`twitter:${field}`,   content, "name")  
        }
        const metas = document.head.querySelectorAll("[data-react-helmet]") || []
        metas.forEach(meta => {
            document.head.removeChild( meta  )
        })
        addMetas("title")
        addMetas("description")
        addMetas("image")
        addSingleMeta("al:web:url", window.location.protocol + "://" + window.location.host)
    }, [])
    return <></>
}
export default SEO