import { Navigate, Route, Routes } from 'react-router'
import FestHelp from '../help/FestHelp'
import Diary from './Diary'
import Project from './Project'
import ProjectContainer from './ProjectContainer'
import ProjectEditForm from './ProjectEditForm'
import { IProject } from '../../data/interfaces'
import { IFestProjectsProps, useFestProjects } from '../../data/store'
import { Callout, Intent } from '@blueprintjs/core'
import { __ } from '@/libs/utilities' 
import ProjectTeam from './team'
import SEO from '@/libs/layout-app/seo'
import { image } from '@/libs/layouts'
import RatingsReport from './RatingsReport'



/* Protopia Ecosystem component */
export interface IProjectFormProps { 

}  

const ProjectScreenForm = ( props: IProjectFormProps ): JSX.Element => {  
    const item: IProject = useFestProjects((state: IFestProjectsProps) => state.currentProject) 
    return item.id 
        ?
        <> 
            <SEO
                title={item.title}
                description={item.post_content}
                image={item.thumbnail || image()}
            />
            <Routes>
                <Route path="" element={ <ProjectContainer /> } >
                    <Route index element={ <Navigate to="ratings" replace /> } /> 
                    <Route 
                        path='ratings'
                        element={ <Project /> }
                    />
                    <Route 
                        path="project-team"
                        element={ <ProjectTeam /> }
                    />
                    <Route 
                        path="management"
                        element={ <ProjectEditForm /> }
                    />
                    <Route 
                        path="diary"
                        element={ <Diary /> }
                    /> 
                    <Route 
                        path="ratings-report"
                        element={ <RatingsReport /> }
                    />
                    <Route path="*" element={ <Navigate to="ratings" replace /> } /> 
                </Route>
            </Routes>
            <FestHelp />
        </> 
        :
        <Callout intent={Intent.DANGER}>
            <div className="p-5 text-center lead">
                {__("No element exists")}
            </div>
        </Callout>
} 

export default ProjectScreenForm