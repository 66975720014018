import { URL } from "@/libs/interfaces/layouts";
import { DEFAULT_PROJECT_BG_URL, EMTY_IMAGE_NAME, IFestival, IProject } from "../../data/interfaces";

export default function getDefaultProjectThumbnail(project: IProject, festival: IFestival): URL {
    const backgroundImage: URL = project.thumbnail && project.thumbnail.indexOf(EMTY_IMAGE_NAME) === -1  
            ? 
            project.thumbnail 
            : 
            festival.defaultProjectThumbnail
                ?
                festival.defaultProjectThumbnail
                :
                DEFAULT_PROJECT_BG_URL

    return backgroundImage
}