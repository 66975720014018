import { Link } from "react-router-dom"
import { IPartner } from "../data/interfaces"

/* Protopia Ecosystem component */
export interface IToposPartnerProps {
   item: IPartner
}
const ToposPartner = (props: IToposPartnerProps): JSX.Element => {
    const ElComponent = props.item.link ? "a" : NoLink
    return <ElComponent className='pe-topos-partner-container align-items-center justify-content-start hover' href={props.item.link || ""} target="_blank" >
        <div 
            className="thumbn"
            style={{
                backgroundImage: `url(${props.item.thumbnail})`
            }}
        />
        <div className="flex-grow-100 text-light text-center mt-3">
        {
            props.item.title
        }  
        </div>
    </ElComponent>
}
export default ToposPartner

const NoLink = (props:any) => {
    return <div className={props.className}>
        { props.children }
    </div>
}