import * as XLSX from 'xlsx'
import { ICategory, ICorrectRating, IDestrict, IFestival, IHoneycombs, IPEFestSchool, IProject, ITrack } from '../interfaces'
import { IUser } from '@/settings/zustand'
import { POST_STATUS } from '@/libs/interfaces/layouts'
import { useFestDataStore } from '../store'
import { useDataStore } from '@/modules/pe-basic-module/data/store'
import { __ } from '@/libs/utilities'

export default function parseProjectExcel(value: any, isUnique: boolean= true) : any {
    const projects: IProject[] = useDataStore.getState().PEFestProject || []  
    const projectNames: number[] = projects.map(p => p.order)
    // console.log( projectNames )
    let workbook: XLSX.WorkBook = XLSX.read(value, { type: "binary", }) 
    const sheetNames: string[] = workbook.SheetNames
    let res: any[] = []
    sheetNames.forEach((sName, i) => { 
        const sheet: XLSX.WorkSheet = workbook.Sheets[ sName ] 
        const data: any[] = XLSX.utils.sheet_to_json( sheet ) 
        res.push( data.map( row => parseRow( row, -1 ) ) ) 
    })
    const res11 = isUnique 
        ?
        res[0].filter( (project: IProject) => {
            //console.log( projectNames, project.order )
            return !projectNames.includes( project.order )
        })
        :
        res[0]

    return res11
}

const parseRow = (row: any, i: number): IProject => {
    const festival: IFestival = useFestDataStore.getState().festival
    const schools: IPEFestSchool[] = useDataStore.getState().PEFestSchool || []
    const tracks: ITrack[] = useDataStore.getState().PEFestTrack || []  
    const users: IUser[] = useDataStore.getState().User || []  
    const track : ITrack = tracks.filter(t => { 
        return t.title === row[__("Track")]
    })[0] 
    const fmru_school : IPEFestSchool[] = schools.filter(t => { 
        return t.title === row[__("School")]
    }) 
    const leader: IUser = users.filter( u => u.display_name === row[__("Project author")])[0]
    const tutor: IUser = users.filter( u => u.display_name === row[__("Tutor")])[0]
    let project: IProject = {
        id: i, 
        title: row[__("Project")], 
        post_content: "", 
        thumbnail: row["Постер"],
        order: row["#"], 
        version: 0, 
        raiting: `${row[__("Raiting")]}`,
        r2: row[__("Raiting")],
        actualRating: `${row[__("Raiting")]}`,
        correctMemberRate: {} as ICorrectRating,
        category : {} as ICategory, 
        honeycombs: {} as IHoneycombs,  
        track,
        destrict: {} as IDestrict,
        fmru_school,
        fmru_destrict: [],
        fmru_group_player: [],
        exhortations: [],
        tutor,
        leader,
        status: POST_STATUS.PUBLISH,
        form_fields: festival.extendedFields?.map(ff => {
            return row[ff.name]
        }) || [],
        experts: [], 
        ratings: [], 
        nomination: ""
    }
    return project
}