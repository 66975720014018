import { Icon, Tag } from '@blueprintjs/core'
import IScalarForm from '../interface/IScalarForm'
import Scalar from './Scalar'
import { useEffect, useState } from 'react'
import { ID } from '@/libs/interfaces/layouts'
import { __ } from '@/libs/utilities'

const Select = (props: IScalarForm) : JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<SelectEnabled {...props} />}
        desabledForm={<SelectDesabled {...props} />}
    />
} 
export default Select 


const SelectDesabled = (props: IScalarForm) : JSX.Element => {
    if(!Array.isArray(props.values)) return props.value.toString()
    const curr: any = props.values.filter((v:any) => v._id === props.value)[0]
    const v: string =  typeof curr === "undefined"
        ?
        props.value.toString()
        :
        curr.title
    return <Tag round className="px-4 py-1">{v}</Tag>
}


const SelectEnabled = (props: IScalarForm) :JSX.Element => {
    // console.log( props )
    const [value, setValue] = useState( () => {
        if(props.multiple)
        {
            let v =  Array.isArray(props.value) ? [ ...props.value ] : [] 
            return v
        }
        else
        {
            return props.value
        } 
    })
    useEffect( () => {
        setValue( () => {
            if(props.multiple)
            {
                let v =  Array.isArray(props.value) ? [ ...props.value ] : [] 
                return v
            }
            else
            {
                return props.value
            } 
        })
    }, [props.value] ) 
    const onClick = (id : ID) =>
    {
        // console.log( "onClick", id )
        setValue(id)
        if( props.onChange )
            props.onChange(id,  props.field, "" )
    }
    const onMultipleClick = (id : ID) =>
    {
        // console.log( "onMultipleClick", value)
        let vv
        if(value.filter((v: ID) => v === id ).length > 0)
        {
            vv = value.filter((v: ID) => v !== id )
        }
        else
        {
            vv = [...value]
            vv.push(id)
        }
        setValue( vv )        
        if( props.onChange )
            props.onChange(vv,  props.field, "")
    }
    const onAll = () =>
    {
        let vv = value.length === props.values?.length 
            ? 
            [] 
            : 
            [ ...(props.values ? props.values?.map( v => v._id ? v._id : v ) : [] ) ]
        setValue( vv )
        if( props.onChange )
            props.onChange(vv,  props.field, "")
    }
    const values = Array.isArray([ ...(props.values ? props.values : [] ) ])
        ?
        props.values?.map( (v, i) =>
        {
            return !props.multiple
                ?
                single({ v, value, i, onClick })
                :
                miltiple({ v, value, i, onMultipleClick })
        })
        :
        [ ]
    return (
        <div className='w-100 d-flex flex-wrap py-2 '>
            <select 
                className="form-control input dark" 
                multiple={ props.multiple } 
                value={ value || "-1" } 
                onChange={( evt : React.ChangeEvent<HTMLSelectElement>) => {
                    props.multiple
                        ?
                        onMultipleClick( evt.currentTarget.value as ID )
                        :
                        onClick( evt.currentTarget.value as ID )

                }}
                    
            >
                <option value="-1">--</option>
                { values } 
                {
                    props.multiple
                        &&
                        <option  
                            value="all"
                            onClick={ onAll }
                        > 
                            { __( "All" ) }
                        </option> 
                }
            </select>
        </div>
    )
}  

const miltiple = (props: any) : JSX.Element | null =>
{
    const {v, value, i} = props
    // console.log( v, value, i )
    return  v?._id 
        ?
        <option 
            key={ v._id + "_" + i } 
            onClick={() => props.onMultipleClick( v._id )}
        >
            {v.title} 
        </option>
        :
        typeof v === "string"
            ? 
            <option 
                key={v + "_" + i}  
                //onClick={() => props.onMultipleClick( v )}
            >
                <div className='pl-3' dangerouslySetInnerHTML={{__html: v }} />
            </option> 
            :
            null
}
const single = (props: any): JSX.Element | null =>
{
    const {v, value, i} = props
    //console.log( value )
    return  v?._id 
        ?
        <option 
            key={ v._id + "_" + i }  
            className={`m-1 pr-3 py-1 ${v.disabled ? "opacity_75 hint hint--top " : ""} pointer`} 
            value={v._id}
            //onClick={() => !v.disabled ? props.onClick(v._id) : null }
        >
            {
                v.disabled 
                    ? 
                    v.title  
                    : 
                    v.title
                }
        </option>
        :
        typeof v === "string"
            ? 
            <option 
                key={v + "_" + i}  
                className="m-1 px-3 py-1 pointer"  
                //onClick={() => props.onClick( v )}
            >
                { v }
            </option> 
            :
            null
}