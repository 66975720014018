import { __, isArraysJoint } from '@/libs/utilities'
import { Popover, Position } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import empty from "../../assets/img/empty.png"
import { ICardProjectProps } from '../../data/interfaces'
import { getExpertRoles } from '../../data/utils/getExpertRoles'
import getProjectLink from '../../data/utils/getProjectLink'
import { useOwnerPartner, useUserOwner } from '../../hooks/project'

const BoxProject = ( props: ICardProjectProps ): JSX.Element => {
    const isOwner: boolean = useUserOwner( props.item )
    const isPartner:boolean = useOwnerPartner(props.item)
    const url = getProjectLink(props.item.id)
    const expertsCount = props.item.experts?.filter( e => isArraysJoint(e.roles, getExpertRoles() ) ).length || 0 
    const link = <div className={`member ${ !!expertsCount ? "" : "no-experts" } ${ "cl" }`}>
            <img src={empty} className="empty" alt="" />
            <div className="member_title">
                {props.item.order}
            </div>
            <div className="xperts " data-cl={props.i === 0 ? "hidden" : ""}>
                { props.item.experts?.length || 0 }
            </div>
            {
                isOwner
                    ?
                    <div className="mine-corner mine-corner-box">
                        { __( "My project" ) }
                    </div>
                    :                    
                    isPartner 
                        ?
                        <div className="mine-honeycombs-corner mine-corner-box">
                            {__("My honeycombs")}
                        </div>
                        :
                        null
            }
            {
                !props.item.is_verified && 
                    <div 
                        className="mine-corner-no-verified corner-card-project"
                        style={{
                            right: -30,
                            top: -26,
                            fontSize: 8,
                            width: 120,
                            height: 51,
                        }}
                    >
                        {__("Project has not yet been approved")}
                    </div>
            }
        </div>
    return (
        <div className="col-xl-1_8 col-lg-2 col-md-3 col-sm-4 col-6 position-relative">
            <Link to={url} className={` ${!props.item.is_verified ? "opacity_5" : ""}`}> 
            {
                !!expertsCount 
                    ?
                    link
                    :
                    <Popover
                        content={<div className="p-4 bg-secondary text-light">
                            {__("No one expert rate")}
                        </div>}
                        interactionKind='hover'
                        className='w-100'
                        position={Position.TOP_LEFT} 
                        hoverOpenDelay={1000}
                        modifiers={{
                            arrow: { enabled: false },
                            flip: { enabled: true },
                            preventOverflow: { enabled: false },
                        }}
                    >
                        {link}
                    </Popover>
            }
            </Link> 
        </div>
    )
} 

export default BoxProject