import { __ } from "@/libs/utilities" 
import { useSearchParams } from 'react-router-dom';

/* Protopia Ecosystem component */
export interface IFestivalFilterProps { 

}
const FestivalFilter = (props: IFestivalFilterProps): JSX.Element => {
    const [searchParams, setSearchParams] = useSearchParams();

    const onClick = ( value: string, isClear: boolean = false ) => {
        setSearchParams(isClear ? {} : {"filter": value}) 
    }
    return <div className='pe-festival-filter-container container flex-centered'>
         <div className=" flex-centered flex-wrap">
            <div 
                className={`btn btn-sm btn-transparent px-2 py-0 mr-1 mb-1 rounded-0 ${ !searchParams.get("filter") ? "btn-secondary" : ""}`} 
                onClick={() => onClick("all", true)}    
            >
                <div className="small">
                    {__("All")}
                </div> 
            </div> 
            <div 
                className={`btn btn-sm btn-transparent px-2 py-0 mr-1 mb-1 rounded-0 ${ searchParams.get("filter") === "all-before" ? "btn-secondary" : ""}`} 
                onClick={() => onClick("all-before")}
            >
                
                <div className="small">
                    {__("Not started yet")} 
                </div>
            </div>
            <div 
                className={`btn btn-sm btn-transparent px-2 py-0  mr-1 mb-1 rounded-0 ${ searchParams.get("filter") === "all-current" ? "btn-secondary" : ""}`} 
                onClick={() => onClick("all-current")}
            >
                
                <div className="small">
                    {__("All current")} 
                </div>
            </div>
            <div 
                className={`btn btn-sm btn-transparent px-2 py-0  mb-1 rounded-0 ${ searchParams.get("filter") === "all-finished" ? "btn-secondary" : ""}`} 
                onClick={() => onClick("all-finished")}
            >
                
                <div className="small">
                    {__("All finished")} 
                </div>
            </div>
        </div>
    </div>
}
export default FestivalFilter