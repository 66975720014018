import { getFeedRoute, getRoute } from "@/libs/layouts"
import { __cases } from "@/libs/utilities"
import { Link } from "react-router-dom"
import { IGanre } from "../../data/interfaces" 
import { Button, Collapse } from "@blueprintjs/core"
import { useState } from "react"
import { trimContent } from "../utils/trimContent"


/* Protopia Ecosystem component */
export interface IFestGanreCardProps {
   item: IGanre
}
const FestGanreCard = (props: IFestGanreCardProps): JSX.Element => { 
    const[isOpen, setOpen] = useState<boolean>(false)
    const routeSingle = getFeedRoute("PEFestProject") 
    const _url = routeSingle
        ? 
        `/${ getRoute(routeSingle)}?ganres=-1,${ props.item.id }` 
        : 
        "/" 
    const trimmedContent = trimContent( props.item.post_content || "", 14 )

    
    const onOpenClick = (evt: any) => {
        evt.stopPropagation()
        evt.preventDefault()
        setOpen(true)
      }
      const onCloseClick = (evt: any) => {
        evt.stopPropagation()
        evt.preventDefault()
        setOpen(false)
      }

    return <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
        <Link 
            className="card h-100 m-0 p-0 position-relative pointer hover" 
            // onClick={onClick}
            to={ _url } 
        > 
            <div 
                className='track-icon position-relative mt-3' 
                style={{
                    backgroundImage: `url(${props.item.thumbnail})`, 
                    backgroundColor: props.item.color.toString() , 
                    top:0
                }}
            />
            <div
                className=" d-flex flex-centered flex-column"
                title={ props.item.title }
                style={{
                height: 76,
                overflow: "hidden",
                padding: "0 1.25em",
                position: "relative",
                display: "table",
                paddingTop: 14
                }}
            >
                <h5 className="m-0 w-100 text-center " style={{ lineHeight: 1.0 }} >
                    { props.item.title }  
                </h5>
                <div className="text-force-contrast">
                    <span className='mr-2 title'>{ props.item.count || 0 }</span>
                    <span>{__cases(props.item.count, "projects")}</span>
                </div>
            </div>
            <ul className="list-group list-group-flush flex-grow-100 ">
                <li className="list-group-item h-100 d-flex flex-column ">              
                {
                    trimmedContent.isTrim
                        ?
                        <>
                            <Collapse isOpen={!isOpen} className={` ${ !isOpen ? "cclosed" : "" } `}>
                                <div className="px-4 pt-4 pb-5 flex-centered flex-column content-collapse  text-force-contrast">
                                    <div className="h-100 text-clip--transparent" dangerouslySetInnerHTML={{ __html: trimmedContent.content }} />
                                </div>                                               
                            </Collapse>
                            <Collapse isOpen={isOpen} className={` ${ isOpen ? "cclosed" : "" } `}> 
                                <div className="px-4 pt-4 pb-5 flex-centered flex-column content-collapse  text-force-contrast">
                                    <div 
                                        className="h-100" 
                                        dangerouslySetInnerHTML={{ __html: props.item.post_content || "" }} 
                                    />
                                </div>
                            </Collapse> 
                            <Button 
                                icon={ isOpen ? "chevron-up" : "chevron-down"}
                                className="position-absolute w-100 bottom"
                                minimal 
                                onClick={ isOpen ? onCloseClick : onOpenClick } 
                            />                    
                        </>
                        : 
                        <>
                            <div className="p-4  text-force-contrast">
                                { trimmedContent.content }
                            </div> 
                        </>
                } 
                </li>
            </ul>
        </Link>
    </div>
}
export default FestGanreCard