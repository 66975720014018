import { __ } from "@/libs/utilities"
import CategorySwitchForm from "./CategorySwitchForm"
import MilestoneSwitchForm from "./MilestoneSwitchForm"
import { ChangeEvent, useEffect, useState } from "react"
import { Card, Collapse } from "@blueprintjs/core"
import { ICategory, ICritery, IFestival } from "@/modules/pe-fest-module/data/interfaces"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { IFestDataStor, useFestDataStore } from "@/modules/pe-fest-module/data/store"
import { isRole } from "@/settings/zustand/user"
import actions from "@/modules/pe-basic-module/data/actions"
import { UPDATE_SINGLE_TYPE } from "@/modules/pe-basic-module/data/actionTypes"
import { useParams } from "react-router"
import { ID } from "@/libs/interfaces/layouts"
import { IState, IUser, useMainStore } from "@/settings/zustand"
import { AppToaster } from "@/libs/useful"

/* Protopia Ecosystem component */
export interface IInsertCriteryProps {
    
}
const InsertCritery = (props: IInsertCriteryProps): JSX.Element => {
    const { landId } = useParams()
    const user: IUser = useMainStore((state:IState) => state.user)
    const festival: IFestival = useFestDataStore((state:IFestDataStor) => state.festival)
    const [isCollapse, setisCollapse] = useState<boolean>( false ) 
    const [uniqs, setUniqs] = useState<ICritery[]>(useDataStore.getState().PEFestCritery?.filter( (cr: ICritery) => !!cr.expert?.id) )  
    const [title, setTitle] = useState<string>( "" )  
    const [text, setText] = useState<string>( "" ) 
    const [category, setCategory] = useState<ID>("-1") 
    const [milestone, setMilestone] = useState<ID>("-1") 

    useEffect(() => useDataStore.subscribe(
        (state:any) => {
            setUniqs(state.PEFestCritery.filter( (cr: ICritery) => !!cr.expert?.id ) )
        }
    ), [])
    if(!festival.isExpertsCriteries || !isRole(["administrator", "Expert"])) {
        return <></>
    }
    const createCritery = () => {
        actions(
            UPDATE_SINGLE_TYPE,
            {
                landId: landId,
                data_type: "PEFestCritery",
                id: "-1",
                item: {
                    title,
                    post_content: text,
                    category,
                    milestone,
                    order: 1,
                    post_status: "publish",
                    expert: parseInt( user.id.toString() )
                }
            }
        )
        .then( (response: any) => {
            console.log( response )
        })
        .finally(() => {
            setText("")
            setTitle("")
            setCategory("-1")
            setMilestone("-1")
            AppToaster.show({
                className :"p-3",
                message: __("Finished")
            })
        })
    }
    const onTitle = (evt: ChangeEvent<HTMLInputElement>) => {
        setTitle(evt.target.value)
    }
    const onTextarea = (evt: ChangeEvent<HTMLTextAreaElement>) => {
        setText( evt.target.value )
    }
    const onCategory = (id: ID) => {
        setCategory(id)
    }
    const onMilestone = (id: ID) => {
        setMilestone(id)
    }
    return <div className='pe-insert-critery-container container mt-3'>
        <div className="pointer user-select-none d-flex justify-content-between mb-1" onClick={() => setisCollapse( !isCollapse )}>
            <div className="display-6 ">
                { __("Create new Critery") }
            </div>
            <div className={`fmRU_button ml-auto${ isCollapse ? " open" : "" }`} >
                <i className="fa fa-caret-right" style={{ color: "#f8f9fa" }} />
            </div>
        </div>
        <div className="row"> 
            <Collapse isOpen={isCollapse} className="overflow-hidden w-100">
                <div className="card row flex-row overflow-hidden p-4">
                    <div className="col-md-5">
                        <CategorySwitchForm onChange={onCategory}/>
                        <MilestoneSwitchForm  onChange={onMilestone}/>
                    </div>
                    <div className="col-md-7 d-flex flex-column"> 
                        <div className=" ">
                            <p>
                                Не более трех критериев! Сейчас - { uniqs.length }
                            </p>
                            <ul>
                                <li>Добавьте значимый для вас критерий, не учтенный в базовом списке. Укажите его название и, если необходимо, короткий комментарий.</li> 
                                <li>Выберите категорию, к которой относится Ваш критерий.</li>
                                <li>Выберите этап, к которому относится Ваш критерий.</li>
                                <li>Поздравляем! Теперь вы сможете оценить любую работу на этом Фестивале по созданному Вами критерию.</li>
                            </ul>

                        </div>  
                        <input
                            type="string"
                            className="input dark mb-2 p-2"
                            placeholder={__("Put title of your critery")}
                            value={ title }
                            onChange={onTitle}
                        />
                        <textarea
                            id="new_critery_text"
                            rows={ 8 }
                            style={{borderWidth: 1}}
                            placeholder={__("Put description of your critery")}
                            className="w-100 h-100 input dark p-2 flex-grow-1 text-force-contrast "
                            value={ text}
                            onChange={ onTextarea}
                        /> 
                        <button 
                            className={` btn btn-block my-2 ${title ? "btn-primary" : "btn-link" } `} 
                            onClick={ createCritery }
                            aria-disabled={ !title }
                            disabled={ !title }
                        >
                            {__("Create")}
                        </button>
                    </div>
                </div>
            </Collapse>   
        </div>
    </div> 
}
export default InsertCritery