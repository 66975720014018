import { ID } from "@/libs/interfaces/layouts"
import { Loading } from "@/libs/useful"
import switchToBlog from "@/modules/pe-basic-module/hooks/switchToBlog"
import getDefEventRoutes from "@/modules/pe-topos-module/components/getDefEventRoutes"
import { IToposStore, useToposStore } from "@/modules/pe-topos-module/data/store"
import { IState, useMainStore } from "@/settings/zustand"
import { useEffect } from "react"
import { Outlet, Route, Routes, useParams } from "react-router"
import { IFestDataStor, useFestDataStore } from "../../data/store"
import { clearFestival, useFestival } from "../../hooks/festival"
import AboutForm from "../about/AboutForm"
import RateSystemForm from "../about/RateSystemForm"
import MessagesForm from "../messages/MessagesForm"
import ModerationForm from "../moderation/ModerationForm"
import ProjectForm from "../project/ProjectForm"
import ProjectListForm from "../projectList/ProjectListForm"
import { ILand } from "../../data/interfaces"
import NoMatchView from "@/modules/pe-basic-module/views/NoMatchView"
import { DEFAULT_404_PROPS } from "@/libs/interfaces/dummies"
import { __ } from "@/libs/utilities"
import AdListForm from "../AdListForm"
import TrackAttachmentListForm from "../TrackAttachmentListForm"
import { description, image, land_group_id } from "@/libs/layouts"
import SEO from "@/libs/layout-app/seo"
import { isUser } from "@/settings/zustand/user"
import OnlyLoggedInForm from "@/libs/basic-view/OnlyLoggedInForm"
import Presentation from "@/libs/useful/Presentation"

/* Protopia Ecosystem component */
export interface IFestivalProps {

}
const Festival = (props: IFestivalProps): JSX.Element => {
    const params = useParams()
    const festId: ID = params.landId || "-1" 
    const isLoading = useFestival( true, festId )
    const land:ILand = useFestDataStore((state:IFestDataStor) => state.site )
    const eventRoutes = useToposStore((state: IToposStore) => state.eventRoutes)

    useEffect(() => {
        useFestDataStore.setState({ festId })
        useMainStore.setState({landId: festId})
        switchToBlog( festId )
        return () => {
            clearFestival()
        }
    }, [festId]) 
    
    if( isLoading ) return <div className="layout-app-state flex-center">
        <Loading />
    </div> 
    
    const _land_group_id = land_group_id()  
    if(!land.id || ( !!_land_group_id  && land.land_group_id !== _land_group_id)) {
        return <div className="layout-app-state flex-centered">
            <NoMatchView {...DEFAULT_404_PROPS} />
        </div>
    }
    return <>
        <SEO
            title={ land.title }
            description={ land.content || description() }
            image={land.thumbnail || image()}
        />
        <Routes> 
            <Route element={<><Outlet /></>} >     
                <Route path="*" element={<AboutForm {...props} parentMode />} >  
                    <Route path="insert-project" element={<></>} /> 
                    <Route path="reglament" element={<></>} /> 
                </Route>   
                <Route path="rate-system" element={<RateSystemForm  {...props} parentMode />} /> 
                <Route path="members" element={
                    isUser()
                        ?
                        <ProjectListForm  {...props} parentMode />
                        :
                        <OnlyLoggedInForm />
                } /> 
                <Route path="ads" element={
                    isUser()
                        ?
                        <AdListForm  {...props} parentMode />
                        :
                        <OnlyLoggedInForm />
                } /> 
                <Route path="track-attachments" element={
                    isUser()
                        ?
                        <TrackAttachmentListForm  {...props} parentMode />
                        :
                        <OnlyLoggedInForm />
                } /> 
                <Route path="messages" element={ <MessagesForm  parentMode /> } /> 
                <Route path="moderation" element={<ModerationForm  {...props} parentMode />} /> 
                <Route
                    path="project/:id/*"  
                    element={
                        isUser()
                            ?
                            <div className="w-100"> 
                                <ProjectForm  /> 
                            </div>
                            :
                            <OnlyLoggedInForm /> 
                    }
                />
                <Route path="presentation" element={<Presentation />} />
                { getDefEventRoutes( eventRoutes) } 
            </Route>
        </Routes>
        {
            land.archived && 
            <div className="mine-corner-no-verified corner-card-project pe-pennant-dark">
                {__("in archive")}
            </div>
        }            
    </>
}
export default Festival