import { getFeedRoute, getRoute } from '@/libs/layouts'
import { __cases } from '@/libs/utilities'
import { Button, Collapse } from '@blueprintjs/core'
import { useState } from 'react'
import { useNavigate, } from 'react-router-dom'
import { ITrack } from '../../data/interfaces'
import getModeratorAvatar from '../utils/getModeratorAvatar'
import TrackClosedLabel from '../utils/TrackClosedLabel'
import TrackVideo from '../utils/TrackVideo'
import TrackVKLink from '../utils/TrackVKLink'
import { trimContent } from '../utils/trimContent'

interface IFestTrackCardProps {
  item: ITrack
}

const FestTrackCard = (props: IFestTrackCardProps) :JSX.Element => {  
    const navigate = useNavigate()
    const[isOpen, setOpen] = useState(false) 
    const[random,] = useState( Math.random() )
    const routeSingle = getFeedRoute("PEFestProject")  
    const _url = routeSingle
        ? 
        `/${ getRoute(routeSingle)}?track=${ props.item.id }` 
        : 
        "/"

    const onClick = () => {
      navigate( _url )
    }
    const onOpenClick = (evt: any) => {
      evt.stopPropagation()
      evt.preventDefault()
      setOpen(true)
    }
    const onCloseClick = (evt: any) => {
      evt.stopPropagation()
      evt.preventDefault()
      setOpen(false)
    }
    const getAvatar = (moderator: "moderator" | "moderator2" = "moderator") => {  
      return getModeratorAvatar(props.item, isOpen, random, moderator) 
    }  
    const trimmedContent = trimContent( props.item.content, 20 )
    return  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
      <div 
        className="card h-100 m-0 p-0 position-relative pointer hover" 
        onClick={onClick}
      >
          <div
            className="card-img overflow-hidden"
            style={{
              backgroundImage: props.item.illustration  && props.item.illustration !== "false"  
                ?
                `url(${props.item.illustration})`
                :
                  props.item.video_id 
                  ? 
                  `url(https://img.youtube.com/vi/${ props.item.video_id }/0.jpg)` 
                  : 
                  `url('')`,
              //borderColor: gcolor,
              height: 275,
              backgroundSize: "cover",
              backgroundRepeat:"no-repeat"
            }} 
          > 
            <TrackVKLink item={props.item} />
            <TrackVideo  item={props.item} />
            <TrackClosedLabel is_closed={!!props.item?.is_closed} /> 
            <div className='under-track-icon'>

            </div>
          </div> 
          <div
            className="card-header d-flex flex-centered flex-column"
            title={ props.item.title }
            style={{
              height: 76,
              overflow: "hidden",
              padding: "0 1.25em",
              position: "relative",
              display: "table",
              paddingTop: 14
            }}
          >
              <h5 className="m-0 w-100 text-center " style={{ lineHeight: 1.0 }} >
                { props.item.title }  
              </h5>
              <div>
                <span className='mr-2'>{ props.item.count || 0 }</span>
                <span>{__cases(props.item.count, "projects")}</span>
              </div>
          </div>
          <div className='track-icon' style={{backgroundImage: `url(${props.item.thumbnail})`}} />
          <ul className="list-group list-group-flush flex-grow-100 ">
            <li className="list-group-item h-100 d-flex flex-column">              
              {
                trimmedContent.isTrim || props.item.moderator?.user_descr?.length > 0
                  ?
                  <>
                    <Collapse isOpen={!isOpen} className={` ${ !isOpen ? "cclosed" : "" } `}>
                      <div className="px-4 pt-4 pb-5 flex-centered flex-column content-collapse">
                        <div className="h-100 text-clip--transparent" dangerouslySetInnerHTML={{ __html: trimmedContent.content }} />
                        
                      </div>                                               
                    </Collapse>
                    <Collapse isOpen={isOpen} className={` ${ isOpen ? "cclosed" : "" } `}> 
                      <div className="px-4 pt-4 pb-5 flex-centered flex-column content-collapse">
                        <div 
                          className="h-100" 
                          dangerouslySetInnerHTML={{ __html: props.item.content }} 
                        />
                      </div>
                    </Collapse>
                    { getAvatar() } 
                    { getAvatar( "moderator2" ) }  
                    <Button 
                      icon={ isOpen ? "chevron-up" : "chevron-down"}
                      className="position-absolute w-100 bottom"
                      minimal 
                      onClick={ isOpen ? onCloseClick : onOpenClick } 
                    />                    
                  </>
                  : 
                  <>
                    <div className="p-4">
                      { trimmedContent.content }
                    </div>
                    <div className="p-4 mt-auto" >
                      { getAvatar() }   
                      { getAvatar( "moderator2" ) }                                             
                    </div>
                  </>
              } 
            </li>
          </ul>
      </div>
  </div> 
}

FestTrackCard.propTypes = {}

export default FestTrackCard

