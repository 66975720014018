import { IMilestone, IProjectMilestone, IRating } from "@/modules/pe-fest-module/data/interfaces"
import { IFestProjectsProps, useFestProjects } from "@/modules/pe-fest-module/data/store"
import CorrectSingleRatingForm from "./CorrectSingleRatingForm"
import CriteryRatingMonitor from "./CriteryRatingMonitor"

export interface ICriteryRatingProps {
    ratings: IRating[]
    milestone?:IMilestone
}
const CriteryRatingView = (props:ICriteryRatingProps): JSX.Element => { 
    return <div className="footer-widget d-flex">
        <CriteryRatingMonitor  ratings={props.ratings} milestone={props.milestone} /> 
        <CorrectSingleRatingForm items={props.ratings} milestone={props.milestone} /> 
    </div>	
}

export default CriteryRatingView