import { __ } from "@/libs/utilities"
import { IMilestone, IProjectMilestone, IRating } from "@/modules/pe-fest-module/data/interfaces"
import { IFestProjectsProps, useFestProjects } from "@/modules/pe-fest-module/data/store"
import getCalculateRating from "@/modules/pe-fest-module/data/utils/getCalculateRating"
import { Button, Popover } from "@blueprintjs/core"

/* Protopia Ecosystem component */
export interface ICriteryRatingMonitorProps {
    ratings: IRating[] 
    milestone?: IMilestone
}
const CriteryRatingMonitor = ( props: ICriteryRatingMonitorProps): JSX.Element => {
    const currentProjectMilestones:IProjectMilestone[] = useFestProjects((state:IFestProjectsProps) => state.currentProjectMilestones) 
    const {rating, correctRating, descriptions} = getCalculateRating( props.ratings )
    let count: number = 1
    if(props.milestone) {
        const projectMilestone = currentProjectMilestones.filter(c => c.id === props.milestone?.id)[0]
        count = projectMilestone
            ?
            projectMilestone.experts.length
            :
            props.ratings.filter(r => !!r.rating ).length || 1
    }
    else {
        count = props.ratings.filter(r => !!r.rating ).length || 1
    }
    if(!count) {
        count = 1
    } 
    return rating !== correctRating && correctRating !== 0
        ?
        <div className="d-flex">
            <Popover 
                interactionKind="hover"
                content={<div className="py-0 w_250 max_h_300 overflow-y-auto">
                    <div>
                        <div className="lead text-center p-2">
                            {__("Rating is correct")}
                        </div>
                        <div className="small pl-3">
                            {
                                descriptions 
                                .map((d: any, i: number) => {
                                    return d.newRating !== null && typeof d.newRating !== "undefined"
                                    ?
                                    <div key={i} className="row strob01"> 
                                        <div className="small col-5 text-right p-2 d-flex align-items-center">    
                                            <div>{__("Old rating")}:</div>
                                        </div>
                                        <div className="col-7 p-2">
                                            <div className="title text-secondary d-flex align-items-center">
                                                {d.oldRating}
                                            </div>
                                        </div>                            
                                        <div className="small col-5 text-right p-2 d-flex align-items-center">    
                                            <div>{__("Rating author")}:</div>
                                        </div>
                                        <div className="col-7 p-2">    
                                            <div className="title text-secondary d-flex align-items-center">
                                                {d.display_name}
                                            </div>
                                        </div>                                   
                                        <div className="small col-5 text-right p-2 d-flex align-items-center">
                                            <div>{__("Correct rating")}:</div>
                                        </div>
                                        <div className="col-7 p-2">  
                                            <div className="title text-secondary p-2 d-flex align-items-center">
                                                {d.newRating} -- { typeof d.newRating }
                                            </div>
                                        </div>  
                                        <div className="small col-5 text-right p-2 d-flex align-items-center">
                                            <div>{__("Reason")}:</div>
                                        </div>
                                        <div className="col-7 p-2">  
                                            <div className="title text-secondary p-2 d-flex align-items-center">
                                                {d.description}
                                            </div>
                                        </div>                                  
                                        <div className="small col-5 text-right p-2 d-flex align-items-center">
                                            <div>{__("Corrector")}:</div>
                                        </div>
                                        <div className="col-7 p-2">  
                                            <div className="title text-secondary p-2 d-flex align-items-center">
                                                {d.correct_display_name}
                                            </div>
                                        </div>                                         
                                    </div>
                                    : 
                                    <></>
                                })
                            }
                        </div>
                    </div> 
                </div>}
            >
                <Button minimal icon="help" />
            </Popover>
            <div className="lead text-danger">
                {(( correctRating) / count ).toFixed(2)}
            </div>
        </div>        
        :
        <div className="lead">
            {(( rating) / count ).toFixed(2)}
        </div>
}
export default CriteryRatingMonitor