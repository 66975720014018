import { IGanre } from "@/modules/pe-fest-module/data/interfaces"
import { Popover } from "@blueprintjs/core" 
import { trimContent } from "../../utils/trimContent"

/* Protopia Ecosystem component */
export interface IGanreLabelProps {
   ganre: IGanre
   small?:boolean
}
const GanreLabel = ({ganre, small}: IGanreLabelProps): JSX.Element => { 
    const trimmedContent = trimContent( ganre.post_content || "", 40 )
    return <Popover
        interactionKind="hover"
        hoverOpenDelay={1200}
        content={ <div className="p-4 w_250">
            <div className="pb-1 lead" > 
                { ganre.title }
            </div>
            { trimmedContent.content }
        </div>}
    >        
        <div className={`ganre-label ${ small ? "small" : "" }`} style={{backgroundColor: ganre.color?.toString() }} title={ ganre.title }>
            {
                !small && <img src={ganre.thumbnail} className="ganre-miniature m-0" alt="" />
            }
            <div className="pt-1 px-1 small text-center text-light" > 
                { ganre.title }
            </div>
        </div>
    </Popover>
}
export default GanreLabel