import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { IFestival, IGanre } from "../../data/interfaces"
import { __ } from "@/libs/utilities"
import FestGanreCard from "./FestGanreCard"
import { IFestDataStor, useFestDataStore } from "../../data/store"

/* Protopia Ecosystem component */
export interface IGanresProps {
   
}
const Ganres = (props: IGanresProps): JSX.Element | false  => {
    const festival: IFestival = useFestDataStore((state: IFestDataStor) => state.festival)
    const ganres: IGanre[] = useDataStore((state: any) => state.PEFestGanre || [])
    return !!festival.isGanres && !!ganres.length && <div className="container border-top-2 border-secondary-medium" id="ganres">
       <div className="row justify-content-center py-4 px-md-5 px-2">
            <div className="col-md-12 d-flex justify-content-center">
                <div className="role_descr">
                    {__("Ganres on Festival")}
                </div>
            </div> 
        </div>
        <div className="row justify-content-center px-2">
        {
            ganres?.map( 
                (ganre: IGanre) => <FestGanreCard item={ganre} key={ganre.id} /> 
            )
        }
        </div>
    </div>
}
export default Ganres