import { Link } from 'react-router-dom'
import { __ } from 'src/libs/utilities'
import { DEFAULT_PROJECT_BG_URL, EMTY_IMAGE_NAME, ICardProjectProps, IFestival, ITrack } from '../../data/interfaces'
import { IFestDataStor, useFestDataStore } from '../../data/store'
import getProjectLink from '../../data/utils/getProjectLink'
import { useOwnerPartner, useUserOwner } from '../../hooks/project'
import ExtendedForms from '../project/projectData/ExtendedForms'
import MemberRatingDisplay from './MemberRatingDisplay'
import TrackLabel from './TrackLabel'
import GanreLabel from './search/GanreLabel'
import { useMyTracks } from '../../hooks/track'
import getDefaultProjectThumbnail from '../utils/getDefaultProjectThumbnail'

const G_COLOR = "#14172b" 
const TwoSizeProject = (props: ICardProjectProps): JSX.Element => {
    const festival: IFestival = useFestDataStore((state: IFestDataStor) => state.festival)
    const isOwner: boolean = useUserOwner( props.item )
    const isPartner:boolean = useOwnerPartner(props.item) 
    const myTracks : ITrack[] = useMyTracks()
    const url = getProjectLink(props.item.id)
    return (
        <div className="two-side-card-cont col-md-4 col-sm-6 m-0 p-0">      
            <Link className="two-side-card" to={url}>
                <div className="text-overlay">
                    <div
                        className="two-side-card-img h-100 w-100 "
                        style={{
                            backgroundImage: `url( ${ getDefaultProjectThumbnail( props.item, festival ) })`,
                            borderColor: G_COLOR,
                            height: festival.memberCardHeight,
                            opacity: !props.item.is_verified ? .33 : 1
                        }}
                        data-mid={props.item.id}
                        data-fmru_type="fmru_player"
                        data-args={props.item.id}
                    >
                        <div className="card-id">
                            {props.item.order} 
                        </div>
                        <div className="bottom position-absolute w-100 mb-5"> 
                        {
                            props.item.prize
                                ? 
                                <div className='pe-small-pennant pe-pumpkit small font-monospace  mb-1'>
                                    <div>                                        
                                        {/* <div className="discr">
                                            {__("Prize:")}
                                        </div> */}
                                        <div className="data text-uppercase">
                                            {props.item.prize}
                                        </div>
                                    </div>
                                </div> 
                                :
                                null
                        } 
                        {
                            props.item.nomination
                                ? 
                                <div className='pe-small-pennant pe-pumpkit-dark small font-monospace bottom'>
                                    <div>                                        
                                        {/* <div className="discr">
                                            {__("Nomination:")}
                                        </div> */}
                                        <div className="data text-uppercase">
                                            {props.item.nomination}
                                        </div>
                                    </div>
                                </div> 
                                :
                                null
                        } 
                        </div>
                        <TrackLabel track={props.item.track} />
                    </div>
                    
                    <div
                        className="two-side-card-title"
                        title={props.item.title}
                            style={{ 
                            overflow: "hidden",
                            textOverflow: "ellipsis", 
                            position: "relative",
                            display: "flex",
                        }}
                    >
                        <h5 className="card-title m-0 d-flex w-100 justify-content-center align-items-center text-center" >
                            {props.item.title}
                        </h5>
                    </div> 
                    {
                        !props.item.is_verified && 
                            <div className="mine-corner-no-verified corner-card-project">
                                {__("Project has not yet been approved")}
                            </div>
                    }
                </div>
                <div className="purchase-button-container ">
                    <ul className="list-group list-group-flush flex-grow-100 w-100 ">                           
                        <li className="list-group-item hidden text-overflow" style={{ height: 64 }}>
                            <span className="discr">
                                {props.item.post_content}
                            </span>
                        </li>
                        {
                            Array.isArray(props.item.fmru_school) 
                                &&
                                props.item.fmru_school.map(school => {
                                    return <li className="list-group-item pb-2" key={school.id}> 
                                        <div className="p-3 small text-center text-dark" > 
                                            { school.title } 
                                        </div>
                                    </li>
                                })
                        }
                        {
                            !!festival.isGanres && Array.isArray(props.item.fmru_group_player) 
                                &&
                                <li className="list-group-item flex-centered py-3"> 
                                {
                                    props.item.fmru_group_player.map((ganre, i) => i < (festival.maxGanres || 1) && <GanreLabel ganre={ganre} key={ganre.id} /> )
                                }
                                </li>
                        }  
                        <ExtendedForms 
                            vertical={false}
                            item={ props.item } 
                            isCollapseOpen 
                            hidden="hide_in_card"
                        />
                        <li className="list-group-item py-2">
                            <span className="discr">
                                {__("Raiting:")}
                            </span>
                            <span className="data float-right">
                                <MemberRatingDisplay 
                                correct_member_rate={props.item.correctMemberRate}
                                countedRating={props.item.r2}
                                className={" "}
                                /> 
                            </span>
                        </li>
                        <li className="list-group-item py-2">
                            <span className="discr">
                                {__("Expert count:")}
                            </span>
                            <span className="data selected float-right">
                                {props.i === 0 ? "-" : props.i} 
                            </span>
                        </li> 
                    </ul>
                    {
                        isOwner
                        ?
                        <div className=" mine-corner over-side " style={{ top:-6, right:-6 }}>
                            { __( "My project" ) }
                        </div>
                        :                    
                        isPartner 
                            ?
                            <div className="mine-honeycombs-corner over-side z-index-100" style={{ top:-6, right:-6 }}>
                                {__("My honeycombs")}
                            </div>
                            :
                            props.item.track?.i && !!myTracks.filter(mt => mt?.id === props.item.track?.id).length 
                                ?
                                <div className="my-track-label">
                                { __( "My Track" ) }
                                </div>
                                :
                                null 
                    }
                </div>
                {
                    isOwner
                        ?
                        <div className="mine-corner d3d" style={{ top:14, right:8 }}>
                            { __( "My project" ) }
                        </div>
                        :                    
                        isPartner 
                            ?
                            <div className="mine-honeycombs-corner d3d z-index-100">
                                {__("My honeycombs")}
                            </div>
                            :
                            props.item.track?.i && !!myTracks.filter(mt => mt?.id === props.item.track?.id).length 
                                ?
                                <div className="my-track-label">
                                { __( "My Track" ) }
                                </div>
                                :
                                null
                }
            </Link>
        </div>
    )
} 

export default TwoSizeProject