import { ID, WINDOW_WIDTH } from "@/libs/interfaces/layouts"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { DEFAULT_SCHOOL_THUMBNAIL, IDestrict, IPEFestSchool } from "@/modules/pe-fest-module/data/interfaces"
import { Collapse } from "@blueprintjs/core"
import { useEffect, useState } from "react" 
import getGuardAvatar from "../../utils/getGuardAvatar"
import SchoolDestrict from "./SchoolDestrict"
import { trimContent } from "../../utils/trimContent"
import useScreenSize from "@/modules/pe-basic-module/hooks/useScreenSize"

/* Protopia Ecosystem component */
export interface ISchoolTaxProps {
    filter: ID[]
}
const SchoolTax = (props: ISchoolTaxProps): JSX.Element => {

    const [current, setCurrent] = useState<IPEFestSchool | null>( null )
    const PEFestSchool: IPEFestSchool = useDataStore((state: any) => state.PEFestSchool )
    useEffect(() => {
        if( !props.filter[1] ) {
            setCurrent( null )
        }
        else {
            setCurrent( 
                PEFestSchool?.filter((school: IPEFestSchool) => school.id === props.filter[1]?.toString() )[0] || {}
            )
        }
    }, [ props.filter, PEFestSchool ])

    return <> 
        <Collapse 
            className=' container-float' 
            isOpen={ !!current && !!props.filter[1] } 
            transitionDuration={ 1000 } 
        >
            <CurrentSchoolTax item={current} />
        </Collapse>    
    </>
}
export default SchoolTax

interface ICurrentSchoolTaxProps {
    item: IPEFestSchool | null
 }
 const CurrentSchoolTax = ({item }: ICurrentSchoolTaxProps): JSX.Element => {
    const {width} = useScreenSize()
    return width > WINDOW_WIDTH.TABLET
        ? 
        <CurrentSchoolScreen item={item} /> 
        : 
        <CurrentSchoolTaxMobile item={item} />
 }
 const CurrentSchoolTaxMobile = ({item }: ICurrentSchoolTaxProps): JSX.Element => {
    return <div className='w-100'> 
        <div className=" light-colored  m-0 flex-row">
            <div className="container d-flex flex-md-row flex-column">
                <div
                    className="w-md-66 flex-grow-100 p-0 " 
                >
                    <div className="lead p-3 align-items-center "> 
                        { item?.title }
                        {/* <TrackEditor item={track} /> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
 }
 const CurrentSchoolScreen = ({item }: ICurrentSchoolTaxProps): JSX.Element => {
    const[isOpen, setOpen] = useState(false)
    const trimmedContent = trimContent( item?.content || "", 30 )
    const destricts: IDestrict[] = useDataStore( (state: any) => state.PEFestDestrict || [] )

    return <div className='w-100'> 
        <div className=" light-colored  m-0 flex-row">
            <div className="container d-flex flex-md-row flex-column">
                <div 
                    className="w-md-33 flex-grow-100 position-relative overflow-hidden pointer "
                    style={{
                        backgroundImage:`url(${ item?.thumbnail ? item?.thumbnail : DEFAULT_SCHOOL_THUMBNAIL })`, 
                        minHeight: 250,
                        backgroundSize: 'cover',
                        backgroundPosition: "center"
                    }}
                    onClick={() => setOpen( !isOpen )}
                >  
                </div>
                <div
                    className="w-md-66 flex-grow-100 p-0 " 
                >
                    <div className="lead p-3 align-items-center "> 
                        { item?.title }
                        {/* <TrackEditor item={track} /> */}
                    </div>
                    <div onClick={() => setOpen( !isOpen )} className="pointer">
                    {
                        trimmedContent.isTrim || item?.guardian?.user_descr?.length > 0
                            ?
                            <>
                                <Collapse isOpen={!isOpen} className={` ${ !isOpen ? "cclosed" : "" } `}>
                                <div className="px-4 pt-2 pb-2 flex-centered flex-column content-collapse">
                                    <div className="h-100 text-clip--transparent" dangerouslySetInnerHTML={{ __html: trimmedContent.content }} />
                                    
                                </div>                                               
                                </Collapse>
                                <Collapse isOpen={isOpen} className={` ${ isOpen ? "cclosed" : "" } `}> 
                                <div className="px-4 pt-4 pb-5 flex-centered flex-column content-collapse">
                                    <div 
                                    className="h-100" 
                                    dangerouslySetInnerHTML={{ __html: item?.content || "" }} 
                                    />
                                </div>
                                </Collapse>       
                            </>
                            : 
                            <>
                                <div className="px-4 py-2">
                                    { trimmedContent.content }
                                </div> 
                            </>
                    }
                    </div>
                    <SchoolDestrict item={destricts.filter(d => d.id === ( item?.destrict[0]?.id || "-1" ))[0] } /> 
                    {
                        item && getGuardAvatar(item, true, Math.random(), "guardian")
                    }
                    {
                        item && getGuardAvatar(item, true, Math.random(), "guardian2")
                    }
                </div>
                 
            </div> 
        </div>
    </div>
 } 